import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/dateFormat';
import { Button } from 'components/_new/Button';
import GreenCheckmarkIcon from '../icons/GreenCheckmarkIcon';
import RedCrossIcon from '../icons/RedCrossIcon';

interface Props {
	signValidationError?: 'empty' | 'change' | '';
	menteeSign?: Date | null;
	mentorSign?: Date | null;
	userFullName?: string | null;
	pairFullName?: string | null;
	panelType: string;
	signContract: () => void;
	info: string;
}

const SignField = ({
	signValidationError,
	menteeSign,
	mentorSign,
	userFullName,
	pairFullName,
	panelType,
	signContract,
	info,
}: Props) => {
	const { t } = useTranslation();
	const formattedMentorSign = mentorSign && dateFormat(mentorSign, 'date');
	const formattedMentteSign = menteeSign && dateFormat(menteeSign, 'date');
	const titles =
		panelType === 'mentor'
			? ['Mentee:', `${t('userPanel.contract.mentor')}:`]
			: [`${t('userPanel.contract.mentor')}:`, 'Mentee:'];
	const signs =
		panelType === 'mentor'
			? [formattedMentteSign, formattedMentorSign]
			: [formattedMentorSign, formattedMentteSign];

	return (
		<div className="flex flex-column gap-2">
			<div className="flex justify-content-around w-full">
				<div className="flex flex-column gap-2 text-center">
					<div>{titles[0]}</div>
					<div className="flex flex-row align-items-center gap-2">
						<div>
							<div>{pairFullName}</div>
							{signs[0] && <div>{signs[0]}</div>}
						</div>
						{signs[0] ? <GreenCheckmarkIcon /> : <RedCrossIcon />}
					</div>
				</div>

				<div className="flex gap-2 align-items-end">
					<div className="flex flex-column align-items-center gap-2 text-center">
						<div>{titles[1]}</div>
						{signs[1] && (
							<div>
								<div>{userFullName}</div>
								<div>{signs[1]}</div>
							</div>
						)}
						{!signs[1] && (
							<>
								<Button
									label={t('userPanel.contract.sign')}
									onClick={signContract}
									aria-describedby="username2-help"
									variant="primary"
								/>
								{signValidationError && (
									<small id="username2-help" className="p-error block max-w-9rem text-center">
										{signValidationError ? `${t('userPanel.contract.emptyFields')}` : undefined}
									</small>
								)}
							</>
						)}
					</div>
					{signs[1] && <GreenCheckmarkIcon />}
				</div>
			</div>
			{!signs[1] && <p>{info}</p>}
		</div>
	);
};
export default SignField;
