import { httpClient } from 'api';
import {
	StatisticsProgramFeedback,
	StatisticsProgramMentee,
	StatisticsProgramMentor,
	StatisticsProgramProgress,
	StatisticsProgramSummary,
} from 'types/Statistics';

export const statisticsApi = {
	getSummary: (programId: number) => httpClient.get<StatisticsProgramSummary>(`/statistics/${programId}/summary`),
	getProgress: (programId: number) => httpClient.get<StatisticsProgramProgress>(`/statistics/${programId}/progress`),
	getMentee: (programId: number) => httpClient.get<StatisticsProgramMentee>(`/statistics/${programId}/mentee`),
	getMentor: (programId: number) => httpClient.get<StatisticsProgramMentor>(`/statistics/${programId}/mentor`),
	getFeedback: (programId: number, userType?: 'mentors' | 'mentees') =>
		httpClient.get<StatisticsProgramFeedback>(
			`/statistics/${programId}/feedback${userType ? `?userType=${userType}` : ''}`
		),
	clearCache: (programId: number) => httpClient.delete(`/statistics/${programId}/cache`),
	downloadXlsx: (programId: number) =>
		httpClient.get<Blob>(`/statistics/${programId}/xlsx`, undefined, { responseType: 'blob' }),
};
