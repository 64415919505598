/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { HTMLAttributes, ReactNode, forwardRef, useMemo, useRef } from 'react';
import './Slider.scss';
import { classNames } from 'primereact/utils';
import composeRefs from '@seznam/compose-react-refs';
import { universalRenderer } from 'utils/universalRenderer';

export type SliderColors = Array<string | [string, string]>;
export type SliderCommonProps = {
	min: number;
	max: number;
	step?: number;
	onBarClick?: (event: any) => void;
	track?: ReactNode | (() => ReactNode);
	disabled?: boolean;
	title?: string;
	colors?: SliderColors;
};

type SliderBaseProps = SliderCommonProps &
	HTMLAttributes<HTMLDivElement> & {
		whileDrag?: boolean;
		children: ReactNode;
	};

export const prepareSliderData = (min: number, max: number, step?: number, colors?: SliderColors) => {
	const fixedMin = min >= max ? max : min;
	const fixedMax = max <= min ? min : max;
	const range = fixedMax - fixedMin;
	const fixedStep = step || 1;
	const stepsCount = Math.floor(range / fixedStep);

	const colorCount = colors?.length || 0;
	const steps: Array<{ num: number; color?: string }> = Array(stepsCount)
		.fill(null)
		.map((_, i) => {
			const colorIndex = colorCount ? Math.floor((i * colorCount) / stepsCount) : 0;
			const selectedColor = colors?.[colorIndex];
			if (selectedColor) {
				const color = Array.isArray(selectedColor) ? selectedColor[0] : selectedColor;
				return { num: i, color: color || undefined };
			}
			return { num: i };
		});

	const stepped = stepsCount <= 20;
	return { fixedMin, fixedMax, step: fixedStep, range, stepsCount, steps, stepped };
};

export const SliderBase = forwardRef(
	(
		{
			min,
			max,
			step = 1,
			whileDrag,
			children,
			onBarClick,
			track,
			title,
			disabled = false,
			colors,
			...props
		}: SliderBaseProps,
		ref: any
	) => {
		const containerRef = useRef<HTMLDivElement>(null);

		const { fixedMin, fixedMax, steps, stepped } = useMemo(
			() => prepareSliderData(min, max, step, colors),
			[min, max, step, colors]
		);

		const renderTrack = () => (track ? universalRenderer(track) : null);

		return (
			<div className="slider-container" title={title}>
				<div className="slider-container-label slider-container-label-min">{fixedMin}</div>
				<div
					ref={composeRefs(containerRef, ref)}
					className={classNames('slider', { 'slider-disabled': disabled })}
					{...props}
					onClick={onBarClick}
				>
					<div className="slider-track">
						{stepped ? (
							steps.map(({ num, color }) => (
								<div key={num} className="slider-track-step" style={{ backgroundColor: color }} />
							))
						) : (
							<div className="slider-track-step" />
						)}
						{renderTrack()}
					</div>
					<div className={classNames('slider-range')}>{children}</div>
				</div>
				<div className="slider-container-label slider-container-label-max">{fixedMax}</div>
			</div>
		);
	}
);
