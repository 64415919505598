import { httpClient } from 'api';

type GetReplyOutput = {
	status: 'error' | 'waiting' | 'ready';
	content: string | null;
	prompt: string;
	info: {
		update: boolean;
		// applicationId: number;
		// max: number;
		// current: number;
		// left: number;
		// rating: number;
		// comment: string | null;
	};
};

export const sessionMentibotApi = {
	getLastReply: (pairId: number, membershipId: number, applicationId: number, sessionId: number) =>
		httpClient.get<GetReplyOutput>(`/session-mentibot/${pairId}/${membershipId}/${applicationId}/${sessionId}`),
	sendMentibotRecommendation: (
		pairId: number,
		membershipId: number,
		applicationId: number,
		sessionId: number,
		data: any
	) =>
		httpClient.post<GetReplyOutput>(
			`/session-mentibot/${pairId}/${membershipId}/${applicationId}/${sessionId}`,
			data
		),
};
