import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useTranslation } from 'react-i18next';
import { statisticsApi } from 'api/statistics';
import { Spinner } from 'components/_new/Spinner';
import { StatsItem, StatsItemProgress } from '../components/StatsItem';

export const Mentee = () => {
	const { t } = useTranslation();

	const { data: program } = useProgramPanel();

	const { data: menteeData, isLoading: menteeIsLoading } = useQuery(
		['programStatistics', { type: 'mentee', programId: Number(program?.id) }],
		() => statisticsApi.getMentee(Number(program?.id)),
		{
			enabled: Boolean(program),
			cacheTime: 10000,
		}
	);

	if (menteeIsLoading || !menteeData) {
		return <Spinner />;
	}

	return (
		<div className="grid">
			<div className="col-12 lg:col-6 flex flex-column gap-3">
				<div className="text-lg my-3">
					<div className="text-darkgray font-bold">{t('programPanel.statistics.surveyMiddleSession')}:</div>
					<div>
						{t('programPanel.statistics.completedSurweys')}: {menteeData?.meta?.middle?.filled}{' '}
						<span className="no-break">
							(
							{t('programPanel.statistics.pairsPercent', {
								perc: menteeData?.meta?.middle?.percent,
							})}
							)
						</span>
					</div>
				</div>

				<StatsItemProgress
					name={t('programPanel.statistics.avgMenteeGoalsAchievement')}
					value={`${menteeData?.data?.middle?.menteeGoalsRealization}/10`}
					progressValue={
						menteeData?.data?.middle?.menteeGoalsRealization &&
						menteeData?.data?.middle?.menteeGoalsRealization > 0
							? (Number(menteeData?.data?.middle?.menteeGoalsRealization) / 10) * 100
							: 0
					}
					positivePercValue={menteeData?.data?.middle?.menteeGoalsRealizationPerc}
					variant="purplish"
				/>

				<StatsItemProgress
					name={t('programPanel.statistics.menteesProcessSatisfaction')}
					value={`${menteeData?.data?.middle?.menteeSatisfaction}/10`}
					progressValue={
						menteeData?.data?.middle?.menteeSatisfaction && menteeData?.data?.middle?.menteeSatisfaction > 0
							? (Number(menteeData?.data?.middle?.menteeSatisfaction) / 10) * 100
							: 0
					}
					variant="rose"
				/>

				<div className="grid flex-row flex-wrap">
					<div className="col-12 md:col-6">
						<StatsItem
							name={t('programPanel.statistics.mentorsInvolvementProcessEngagement')}
							value={`${menteeData?.data?.middle?.mentorEngagement}/10`}
							variant="lpurplish"
						/>
					</div>
					<div className="col-12 md:col-6">
						<StatsItem
							name={t('programPanel.statistics.menteesInvolvementProcessEngagement')}
							value={`${menteeData?.data?.middle?.menteeEngagement}/10`}
							variant="lpurplish"
						/>
					</div>
				</div>
			</div>

			<div className="col-12 lg:col-6 flex flex-column gap-3">
				<div className="text-lg my-3">
					<div className="text-darkgray font-bold">{t('programPanel.statistics.surveyLastSession')}:</div>
					<div>
						{t('programPanel.statistics.completedSurweys')}: {menteeData?.meta?.last?.filled}{' '}
						<span className="no-break">
							(
							{t('programPanel.statistics.pairsPercent', {
								perc: menteeData?.meta?.last?.percent,
							})}
							)
						</span>
					</div>
				</div>

				<StatsItemProgress
					name={t('programPanel.statistics.avgMenteeGoalsAchievement')}
					value={`${menteeData?.data?.last?.menteeGoalsRealization}/10`}
					progressValue={
						menteeData?.data?.last?.menteeGoalsRealization &&
						menteeData?.data?.last?.menteeGoalsRealization > 0
							? (Number(menteeData?.data?.last?.menteeGoalsRealization) / 10) * 100
							: 0
					}
					positivePercValue={menteeData?.data?.last?.menteeGoalsRealizationPerc}
					variant="purplish"
				/>

				<StatsItemProgress
					name={t('programPanel.statistics.menteesProcessSatisfaction')}
					value={`${menteeData?.data?.last?.menteeSatisfaction}/10`}
					progressValue={
						menteeData?.data?.last?.menteeSatisfaction && menteeData?.data?.last?.menteeSatisfaction > 0
							? (Number(menteeData?.data?.last?.menteeSatisfaction) / 10) * 100
							: 0
					}
					variant="rose"
				/>

				<div className="grid flex-row flex-wrap">
					<div className="col-12 md:col-6">
						<StatsItem
							name={t('programPanel.statistics.mentorsInvolvementProcessEngagement')}
							value={`${menteeData?.data?.last?.mentorEngagement}/10`}
							variant="lpurplish"
						/>
					</div>
					<div className="col-12 md:col-6">
						<StatsItem
							name={t('programPanel.statistics.menteesInvolvementProcessEngagement')}
							value={`${menteeData?.data?.last?.menteeEngagement}/10`}
							variant="lpurplish"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
