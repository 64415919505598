/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { apiUrl, authApi, httpClient } from 'api';
import { LoginPayload } from 'types/payloads/LoginPayload';
import TextField from 'components/_common/forms/TextField';
import ValidatePassword from 'components/_common/forms/ValidatePassword';
import GoogleIcon from 'assets/icons/google-logo.svg';
import OfficeIcon from 'assets/icons/office-logo.svg';
import { useAuth } from 'contexts/authContext';
import { useGlobal } from 'contexts/globalContext';
import { AuthData } from 'types/Auth';
import { Button } from 'components/_new/Button';
import { useInterval } from 'primereact/hooks';
import { MessageBox } from 'components/_new/MessageBox';
import { Checkbox } from 'primereact/checkbox';
import LanguageSwitcher from './LanguageSwitcher';

interface LoginFormObject {
	login: string;
	password: string;
	code?: number;
	remember2fa?: boolean;
}

const Login: FC = () => {
	const { t } = useTranslation();
	// const [persistLogin, setPersistLogin] = useState<boolean>(false);
	const { toastRef } = useGlobal();
	const { authenticate, isLogged, rememberToken } = useAuth();

	const { search } = useLocation();
	const queryParams = Object.fromEntries(Array.from(new URLSearchParams(search).entries()));

	const navigate = useNavigate();
	const { control, handleSubmit, setError, reset, getValues, clearErrors } = useForm<LoginFormObject>({
		defaultValues: {
			login: '',
			password: '',
			code: undefined,
			remember2fa: undefined,
		},
	});

	const [twoFactorAuthMode, setTwoFactorAuthMode] = useState<0 | 1 | 2 | 3>(0);
	const handleCancelTwoFactorAuth = useCallback(() => {
		setTwoFactorAuthMode(0);
		reset();
	}, []);

	const refineMessage = (message: string) => {
		return message === 'Internal server error' || message === 'Invalid credentials'
			? t('misc.forms.notMatchingPassword')
			: message;
	};

	const { mutate, isLoading: isSubmitting } = useMutation((data: LoginPayload) => authApi.loginUser(data), {
		onMutate: () => {
			clearErrors();
		},
		onSuccess: (response) => {
			if (Object.hasOwn(response, 'message')) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-destructuring
				const message = (response as any).message;
				if (message === '2FA_USER_REQUIRED') {
					setTwoFactorAuthMode(1);
				} else if (message === '2FA_PROGRAM_REQUIRED') {
					setTwoFactorAuthMode(2);
				} else if (message === '2FA_ADMIN_REQUIRED') {
					setTwoFactorAuthMode(3);
				}
			} else {
				const { refreshToken, accessToken, rememberToken } = response as AuthData;
				window.localStorage.removeItem('externalLoginType');
				authenticate({ refreshToken, accessToken, rememberToken }, () => {
					toastRef?.current?.show({ severity: 'success', detail: t('auth.loggedSuccessfully') });
					navigate({ pathname: '/', search });
				});
			}
		},
		onError: ({ response }) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-destructuring
			const message = response?.data?.message;
			if (Object.hasOwn(response.data, 'message') && typeof message === 'string') {
				switch (message) {
					case 'Invalid credentials':
						setError('login', {
							type: 'custom',
							message: refineMessage(response.data.message),
						});
						setError('password', {
							type: 'custom',
							message: refineMessage(response.data.message),
						});
						break;
					case 'Invalid verification code':
						setError('code', { message: t('auth.twoFactorAuthInvalidCode') });
						break;
					case 'Verification code expires':
						setError('code', { message: t('auth.twoFactorAuthInvalidExpires') });
						break;
					default:
						break;
				}
			} else {
				setError('login', {
					type: 'custom',
					message: refineMessage(response.data.message),
				});
				setError('password', {
					type: 'custom',
					message: refineMessage(response.data.message),
				});
			}
		},
	});

	const { mutate: externalLogin } = useMutation(
		['externalLogin'],
		({ type, ...data }: any) => httpClient.post<string>(`auth/${type}`, data),
		{
			onSuccess: (token: string, { type }: any) => {
				window.localStorage.setItem(`${type}-auth-data`, token);
				if (queryParams?.redirectUrl) {
					window.localStorage.setItem(`${type}-auth-redirect`, queryParams.redirectUrl);
				}
				window.location.href = `${apiUrl}/auth/${type}/redirect`;
			},
		}
	);
	const handleExternalLoginClick = (type: string) => {
		const data = { provider: type, programName: window.localStorage.getItem('programName') };
		externalLogin({ type, ...data });
	};

	const handleSubmitForm = handleSubmit(({ login, password, code, remember2fa }) => {
		const payload = {
			email: login.trim(),
			password,
			code: twoFactorAuthMode > 0 ? Number(code) : undefined,
			remember2fa: twoFactorAuthMode > 0 ? Boolean(remember2fa) : undefined,
			rememberToken: rememberToken || undefined,
		};
		mutate(payload);
	});

	const handleResend = () => {
		const { login, password } = getValues();
		setTimer(0);
		mutate({ email: login.trim(), password });
	};

	const [timer, setTimer] = useState(0);
	const countdown = timer <= 60 ? 60 - timer : 0;
	useInterval(() => setTimer((prev) => prev + 1), 1000, twoFactorAuthMode > 0);

	if (isLogged && !queryParams.logout) {
		return <Navigate to={{ pathname: '/', search }} />;
	}

	return (
		<>
			<Helmet title={t('auth.login')} />
			<form onSubmit={handleSubmitForm} className="flex flex-column row-gap-3">
				{twoFactorAuthMode > 0 && (
					<>
						<div>
							<Trans as="p" t={t} i18nKey="auth.twoFactorAuthInfo" components={[<strong />]} />
							{twoFactorAuthMode === 2 && (
								<MessageBox
									variant="purple"
									highlight
									message={t('userPanel.myAccount.twoFactorAuthProgramForced')}
								/>
							)}
							{twoFactorAuthMode === 3 && (
								<MessageBox
									variant="purple"
									highlight
									message={t('userPanel.myAccount.twoFactorAuthAdminForced')}
								/>
							)}
						</div>

						<TextField
							// noAppearance
							name="code"
							control={control}
							required
							placeholder={t('auth.twoFactorAuthCode')}
							inputClassName="text-2xl text-center bold"
							maxLength={6}
							rules={{
								validate: {
									isNumber: (value) => {
										const num = Number(value);
										if (!value || Number.isNaN(num)) {
											return t('auth.twoFactorAuthRInvalidInput');
										}
										return true;
									},
								},
							}}
						/>
						<div className="flex flex-row gap-2 justify-content-between">
							<Button
								label={t('actions.cancel')}
								className="border-round-3xl"
								onClick={handleCancelTwoFactorAuth}
								variant="primary-text"
								variantSize="sm"
							/>
							<Button
								label={`${t('auth.twoFactorAuthResend')}${countdown > 0 ? ` (${countdown}s)` : ''}`}
								className="border-round-3xl"
								onClick={handleResend}
								variant="primary-outlined"
								disabled={timer < 60}
								variantSize="sm"
							/>
						</div>

						<Controller
							control={control}
							name="remember2fa"
							render={({ field }) => (
								<label className="flex flex-row gap-2 align-items-center">
									<Checkbox {...field} checked={field.value} />{' '}
									<span>{t('auth.twoFactorAuthRememberDevice')}</span>
								</label>
							)}
						/>
					</>
				)}

				{twoFactorAuthMode === 0 && (
					<>
						<TextField name="login" placeholder={t('auth.inputs.login')} control={control} required />
						<ValidatePassword
							name="password"
							placeholder={t('auth.inputs.password')}
							control={control}
							required
						/>
					</>
				)}

				<Button submit label={t('auth.buttons.login')} rounded loading={isSubmitting} />

				<div className="text-sm text-center underline">
					<Button
						label={t('auth.resetPassword')}
						variant="primary-text"
						onClick={() => navigate('/password-reset')}
					/>
				</div>

				<span className="text-center">{t('misc.or')}</span>

				<div className="flex justify-content-between gap-2">
					<Button
						label={t('auth.buttons.loginWithGoogle')}
						onClick={() => handleExternalLoginClick('google')}
						className="flex column-gap-3 w-full p-button-raised p-button-text p-button-secondary border-round-3xl text-xs px-2 py-2 align-self-center"
						variant="primary-outlined"
					>
						<img src={GoogleIcon} alt="icon" />
						{t('auth.buttons.loginWithGoogle')}
					</Button>
					<Button
						label={t('auth.buttons.loginWithOffice')}
						onClick={() => handleExternalLoginClick('microsoft')}
						className="flex column-gap-3 w-full p-button-raised p-button-text p-button-secondary border-round-3xl text-xs px-2 py-2 align-self-center"
						variant="primary-outlined"
					>
						<img src={OfficeIcon} alt="icon" />
						{t('auth.buttons.loginWithOffice')}
					</Button>
				</div>
				<div className="flex justify-content-center mt-4">
					<LanguageSwitcher />
				</div>
			</form>
		</>
	);
};

export default Login;
