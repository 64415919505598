import { httpClient, objectToFormData } from 'api';
import { serialize } from 'object-to-formdata';
import { Answer } from 'types/Application';
import { ApplicationPayload } from '../types/payloads/ApplicationPayload';

export const applicationsApi = {
	adminDownloadApplicationsXLS: ({
		programId,
		applicationRole,
	}: {
		programId: number;
		applicationRole: 'mentee' | 'mentor';
	}) =>
		httpClient.get<string>(`programMemberships/admin/${programId}/xls/${applicationRole}`, undefined, {
			responseType: 'blob',
		}),

	adminApplicationEdit: (programId: number, programMembershipId: number, payload: ApplicationPayload) => {
		const formData = objectToFormData(payload);
		return httpClient.post(`programMemberships/admin/${programId}/${programMembershipId}/applications`, formData);
	},

	adminUploadProgramApplicationFile: (programId: number, applicationId: number, body: any) => {
		const formData = serialize(body);
		return httpClient.post<Answer>(
			`programMemberships/admin/program/${programId}/application/${applicationId}/file`,
			formData
		);
	},
	adminRemoveProgramApplicationFile: (programId: number, applicationId: number, data: any) => {
		const formData = serialize(data);
		return httpClient.put<boolean>(
			`programMemberships/admin/program/${programId}/application/${applicationId}/file`,
			formData
		);
	},

	adminUploadProgramMembershipFile: (programId: number, membershipId: number, body: any) => {
		const formData = serialize(body);
		return httpClient.post<Answer>(
			`programMemberships/admin/program/${programId}/membership/${membershipId}/file`,
			formData
		);
	},
	adminRemoveProgramMembershipFile: (programId: number, membershipId: number, data: any) => {
		const formData = serialize(data);
		return httpClient.put<boolean>(
			`programMemberships/admin/program/${programId}/membership/${membershipId}/file`,
			formData
		);
	},
};
