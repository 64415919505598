import React, { HTMLAttributes, Ref, forwardRef } from 'react';
import { classNames } from 'primereact/utils';

export type IconStyle = 'solid' | 'regular';

export type IconSet = 'pi' | 'fa';

export type IconProps = {
	id?: string;
	name: string;
	iconStyle?: IconStyle;
	iconSet?: IconSet;
	className?: string;
	style?: HTMLAttributes<HTMLElement>['style'];
};

export const Icon = forwardRef(
	(
		{ name, iconStyle = 'solid', iconSet = 'fa', className, style, ...restProps }: IconProps,
		ref: Ref<HTMLSpanElement>
	) => {
		return (
			<i
				ref={ref}
				className={classNames(
					{
						pi: iconSet !== 'fa',
						fas: iconSet === 'fa' && iconStyle === 'solid',
						far: iconSet === 'fa' && iconStyle === 'regular',
					},
					`${iconSet}-${name}`,
					className
				)}
				style={style}
				{...restProps}
			/>
		);
	}
);
