import React, { useRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { AdminQuestion, GroupedChoiceList, Question, QuestionTypes } from 'types/Question';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { Button } from '../../_common/Button';
import './ContractModuleItem.scss';
import SingleChoice from '../../_common/forms/SingleChoice';
import MultipleChoice from '../../_common/forms/MultipleChoice';
import MultipleChoiceGroup from '../../_common/forms/MultipleChoiceGroup';
import { InputTextArea } from '../../_new/InputTextArea';
import CustomLabel from '../../_common/forms/Label';
import { SectionedMultipleChoiceEditWrapper } from './SectionedMultipleChoiceEditWrapper';
import { MultipleChoiceEditWrapper } from './MultipleChoiceEditWrapper';
import { SingleChoiceEditWrapper } from './SingleChoiceEditWrapper';
import { Dropdown, DropdownItem } from '../../_new/Dropdown';
import { ApplicationRole } from '../../../types/Application';
import { DialogConfirm } from '../../_new/Dialog';
import { InputTextEditWrapper } from './InputTextEditWrapper';

interface QuestionCardProps {
	onSwap: (currentIndex: number, swapWithIndex: number) => void;
	index: number;
	canMoveUp: boolean;
	canMoveDown: boolean;
	onDelete: () => void;
	onEdit: (editedSetting: Partial<AdminQuestion>) => void;
	question: AdminQuestion;
	questionAnswered: boolean;
	filteredSystemQuestionTypes: DropdownItem[];
}

interface QuestionExtended extends Question {
	name: string;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
	onSwap,
	index,
	canMoveUp,
	canMoveDown,
	onDelete,
	onEdit,
	question,
	questionAnswered,
	filteredSystemQuestionTypes,
}) => {
	const [isEdited, setIsEdited] = useState(false);
	const [editedQuestion, setEditedQuestion] = useState<Partial<AdminQuestion>>({});
	const [confirmRemoveDialog, setConfirmRemoveDialog] = useState(false);
	const { t } = useTranslation();
	const cardRef = useRef<HTMLDivElement>(null);

	const applicationRoles = [
		{ label: t('programPanel.editQuestion.peer'), value: 'peer' },
		{ label: t('programPanel.editQuestion.mentor'), value: 'mentor' },
		{ label: t('programPanel.editQuestion.mentee'), value: 'mentee' },
	];

	const { control, handleSubmit, reset, watch } = useForm<Partial<QuestionExtended>>({
		defaultValues: {
			applicationRole: question.applicationRole,
			systemQuestionType: question.systemQuestionType,
		},
	});

	const watchedApplicationRole = watch('applicationRole', question.applicationRole);
	const watchedSystemQuestionType = watch('systemQuestionType');
	const isSystemQuestionForbidden = ['name', 'surname', 'areas'].includes(String(question.systemQuestionType));

	const scrollToTop = () => {
		if (cardRef.current) {
			cardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const renderCopy = () => {
		switch (question.questionType) {
			case QuestionTypes.infoText:
				return (
					<div className="ml-3">
						<Trans
							components={
								question.links
									? question.links.map((link) => (
											// eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
											<a
												key={index}
												href={link}
												target="_blank"
												rel="noreferrer"
												className="p-link"
											/>
									  ))
									: undefined
							}
						>
							{question.text}
						</Trans>
					</div>
				);
			case QuestionTypes.infoHeader:
				return (
					<div className="ml-3 text-xl font-medium pb-0">
						{question.text}
						<div className="text-sm font-normal">{question?.description}</div>
					</div>
				);

			case QuestionTypes.shortText:
			case QuestionTypes.link:
				return (
					<div>
						<CustomLabel name={`form.${index}.value`} required={question.required}>
							<Trans
								components={
									question.links
										? question.links.map((link) => (
												// eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
												<a
													key={index}
													href={link}
													target="_blank"
													rel="noreferrer"
													className="p-link"
												/>
										  ))
										: undefined
								}
							>
								{question.text}
							</Trans>
						</CustomLabel>
						<InputTextArea
							name={`form.${index}.value`}
							placeholder={question.description}
							required={question.required}
							disabled
						/>
					</div>
				);
			case QuestionTypes.longText:
				return (
					<div>
						<CustomLabel name={`form.${index}.value`} required={question.required}>
							<Trans
								components={
									question.links
										? question.links.map((link) => (
												// eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
												<a
													key={index}
													href={link}
													target="_blank"
													rel="noreferrer"
													className="p-link"
												/>
										  ))
										: undefined
								}
							>
								{question.text}
							</Trans>
						</CustomLabel>
						<InputTextArea
							name={`form.${index}.value`}
							placeholder={question.description}
							required={question.required}
							disabled
						/>
					</div>
				);
			case QuestionTypes.singleChoice:
				return (
					<SingleChoice
						choiceList={(question.choiceList as string[]) || []}
						label={question.text}
						description={question.description}
						control={control}
						required={question.required}
						links={question.links}
						name="name"
					/>
				);
			case QuestionTypes.multipleChoice:
				return (
					<MultipleChoice
						name="name"
						choiceList={question.choiceList as string[]}
						label={question.text}
						description={question.description}
						control={control}
						required={question.required}
						minAnswers={question.min || 1}
						maxAnswers={question.max || question.choiceList.length}
						links={question.links}
					/>
				);
			case QuestionTypes.multipleChoiceGroups:
				return (
					<MultipleChoiceGroup
						name="name"
						choiceList={question.choiceList as GroupedChoiceList[]}
						label={question.text}
						description={question.description}
						control={control}
						minAnswers={question.min}
						maxAnswers={question.max}
						required={question.required}
						links={question.links}
					/>
				);
			case QuestionTypes.checkbox:
				return (
					<div className="flex flex-row mt-2">
						<Checkbox name={`form.${index}.value`} disabled />
						<CustomLabel
							name={`form.${index}.value`}
							required={question.required}
							style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
						>
							<Trans
								components={
									question.links
										? question.links.map((link) => (
												// eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
												<a
													key={index}
													href={link}
													target="_blank"
													rel="noreferrer"
													className="p-link"
												/>
										  ))
										: undefined
								}
							>
								{question.description}
							</Trans>
						</CustomLabel>
					</div>
				);
			case QuestionTypes.attachment:
				return (
					<div>
						<CustomLabel name={`form.${index}.value`} label={question.text} required={question.required} />
						<FileUpload
							mode="basic"
							invalidFileSizeMessageSummary="Invalid file size"
							invalidFileSizeMessageDetail="Maximum upload filesize allowed is 30mb"
							customUpload
							chooseLabel={question.description}
						/>
					</div>
				);
			default:
				return <div />;
		}
	};

	const renderEdit = () => {
		return (
			<div className="mt-2">
				<div className="field">
					<Controller
						control={control}
						name="applicationRole"
						defaultValue={question.applicationRole}
						render={({ field }) => (
							<>
								<label className="ml-2 field-label" htmlFor="applicationRole">
									{t('programPanel.editQuestion.applicationRole')}
								</label>
								<Dropdown
									id="applicationRole"
									value={field.value}
									onChange={(e) => field.onChange(e.value)}
									options={applicationRoles}
									placeholder={t('programPanel.editQuestion.chooseApplicationRole')}
								/>
							</>
						)}
					/>
				</div>
				{filteredSystemQuestionTypes.length > 0 &&
					![QuestionTypes.infoHeader, QuestionTypes.infoText].includes(question.questionType) && (
						<div className="field">
							<Controller
								control={control}
								name="systemQuestionType"
								rules={{ required: true }}
								defaultValue={question.systemQuestionType}
								render={({ field }) => (
									<>
										<label className="ml-2 field-label" htmlFor="applicationRole">
											{t('programPanel.editQuestion.systemQuestionType')}
										</label>
										<Dropdown
											id="systemQuestionType"
											value={field.value}
											disabled={isSystemQuestionForbidden}
											onChange={(e) => field.onChange(e.value)}
											options={
												[
													...filteredSystemQuestionTypes,
													{
														label: t('programPanel.editQuestion.noSystemQuestion'),
														value: null,
													},
												] as DropdownItem[]
											}
											placeholder={t('programPanel.editQuestion.systemQuestionTypePlaceholder')}
										/>
									</>
								)}
							/>
						</div>
					)}
				{renderFormFields(question.questionType)}
			</div>
		);
	};

	const renderFormFields = (type: QuestionTypes) => {
		switch (type) {
			case QuestionTypes.checkbox:
			case QuestionTypes.infoText:
			case QuestionTypes.infoHeader:
				return (
					<InputTextEditWrapper
						questionType={type}
						initialLabel={question.description}
						initialText={question.text}
						required={question.required}
						hiddenFromUsers={question.hiddenFromUsers}
						initialLinks={question.links || []}
						onSave={handleSaveText}
						withLinks
						onCancel={() => setIsEdited(false)}
					/>
				);
			case QuestionTypes.link:
			case QuestionTypes.shortText:
			case QuestionTypes.longText:
			case QuestionTypes.attachment:
				return (
					<InputTextEditWrapper
						questionType={type}
						initialLabel={question.description}
						initialText={question.text}
						required={question.required}
						hiddenFromUsers={question.hiddenFromUsers}
						initialLinks={question.links || []}
						onSave={handleSaveText}
						withLinks={false}
						onCancel={() => setIsEdited(false)}
					/>
				);
			case QuestionTypes.singleChoice:
				return (
					<SingleChoiceEditWrapper
						initialItems={question.choiceList as string[]}
						initialLabel={question.description}
						initialText={question.text}
						required={question.required}
						onSave={handleSaveSingleChoice}
						hiddenFromUsers={question.hiddenFromUsers}
						onCancel={() => setIsEdited(false)}
					/>
				);
			case QuestionTypes.multipleChoice:
				return (
					<MultipleChoiceEditWrapper
						initialItems={question.choiceList as string[]}
						initialLabel={question.description}
						initialText={question.text}
						required={question.required}
						minAnswers={question.min || 1}
						maxAnswers={question.max || question.choiceList.length}
						onSave={handleSaveMultipleChoice}
						hiddenFromUsers={question.hiddenFromUsers}
						onCancel={() => setIsEdited(false)}
					/>
				);
			case QuestionTypes.multipleChoiceGroups:
				return (
					<SectionedMultipleChoiceEditWrapper
						initialItems={question.choiceList as GroupedChoiceList[]}
						initialLabel={question.description}
						initialText={question.text}
						required={question.required}
						minAnswers={question.min || 1}
						maxAnswers={question.max || question.choiceList.length}
						onSave={handleSaveMultipleChoice}
						hiddenFromUsers={question.hiddenFromUsers}
						onCancel={() => setIsEdited(false)}
					/>
				);
			default:
				return <div>Unsupported module type for editing</div>;
		}
	};

	const handleSaveSingleChoice = (
		choiceList: string[],
		description: string,
		text: string,
		isRequired: boolean,
		hiddenFromUsers: boolean
	) => {
		const applicationRole = watchedApplicationRole;
		setEditedQuestion({
			...question,
			choiceList,
			description,
			text,
			required: isRequired,
			hiddenFromUsers,
			applicationRole,
			systemQuestionType: watchedSystemQuestionType,
		});
		onEdit({
			...question,
			choiceList,
			description,
			text,
			required: isRequired,
			hiddenFromUsers,
			applicationRole,
			systemQuestionType: watchedSystemQuestionType,
		});
		setIsEdited(false);
		scrollToTop();
	};

	const handleSaveText = (
		text: string,
		description: string,
		links: string[],
		required: boolean,
		hiddenFromUsers: boolean
	) => {
		const applicationRole = watchedApplicationRole;
		setEditedQuestion({
			...question,
			description,
			text,
			links,
			required,
			hiddenFromUsers,
			applicationRole,
			systemQuestionType: watchedSystemQuestionType,
		});
		onEdit({
			...question,
			description,
			text,
			links,
			required,
			hiddenFromUsers,
			applicationRole,
			systemQuestionType: watchedSystemQuestionType,
		});
		setIsEdited(false);
		scrollToTop();
	};

	const handleSaveMultipleChoice = (
		updatedChoiceList: string[] | GroupedChoiceList[],
		updatedLabel: string,
		updatedText: string,
		isRequired: boolean,
		hiddenFromUsers: boolean,
		min?: number,
		max?: number
	) => {
		const applicationRole = watchedApplicationRole;
		const systemQuestionType = watchedSystemQuestionType === '' ? undefined : watchedSystemQuestionType;

		setEditedQuestion({
			...question,
			choiceList: updatedChoiceList,
			text: updatedText,
			description: updatedLabel,
			required: isRequired,
			hiddenFromUsers,
			min,
			max,
			applicationRole,
			systemQuestionType,
		});
		onEdit({
			...question,
			choiceList: updatedChoiceList,
			text: updatedText,
			description: updatedLabel,
			required: isRequired,
			hiddenFromUsers,
			min,
			max,
			applicationRole,
			systemQuestionType,
		});
		setIsEdited(false);
		scrollToTop();
	};

	const handleSaveApplicationRole = (applicationRole: ApplicationRole) => {
		setEditedQuestion({
			...question,
			applicationRole,
		});
		onEdit({
			...question,
			applicationRole,
		});
		setIsEdited(false);
		scrollToTop();
	};

	const onSubmit = (data: Partial<Question>) => {
		setEditedQuestion({ ...question, ...data });
		onEdit({ ...question, ...data });
		setIsEdited(false);
		scrollToTop();
	};

	const toggleEditMode = () => {
		setIsEdited(!isEdited);
		reset(question);
	};

	const tooltipText = isSystemQuestionForbidden
		? t('programPanel.editQuestion.systemQuestion')
		: t('programPanel.editQuestion.alreadyAnswered');

	const isDeleteDisabled = questionAnswered || isSystemQuestionForbidden;
	return (
		<div
			ref={cardRef}
			className={classNames(
				'p-module-item-container',
				'flex flex-column b-bottom pl-4 pr-4 pt-2 pb-4  mb-4 border-round-lg'
			)}
		>
			<DialogConfirm
				visible={confirmRemoveDialog}
				onHide={() => {
					setConfirmRemoveDialog(false);
				}}
				onConfirm={onDelete}
				onReject={() => {
					setConfirmRemoveDialog(false);
				}}
			/>

			{!isEdited ? (
				<div className="contract-module-box">
					<div
						className="flex flex-column w-full justify-content-between align-items-start md:flex-row pb-3 mb-3 mt-3"
						style={{ borderBottom: '1px solid lightGray' }}
					>
						<div className="flex flex-column justify-content-between w-full md:w-8 mb-3 md:mb-0">
							<span className="font-bold text-base mb-1">{question.text}</span>
							<div className="flex flex-row flex-wrap gap-2">
								<span className="min-w-[120px]">
									{t(`programPanel.editQuestion.${question.applicationRole}`)}
								</span>
								{question.systemQuestionType && (
									<span className="flex items-center gap-1">
										<i className="fa-solid fa-gears" />
										{question.systemQuestionType}
									</span>
								)}
							</div>
						</div>

						<div className="flex md:w-auto">
							<Button
								className="p-button-text p-button-label text-black-alpha-80 p-button-sm"
								icon="pi pi-pencil text-purple-500"
								onClick={toggleEditMode}
								label={t('actions.edit')}
								size="sm"
							/>
							<Button
								icon="fa-solid fa-up-long"
								className={classNames('p-button-sm p-button-text w-1', {
									'p-button-disabled': !canMoveUp,
									'opacity-full': canMoveUp,
									'opacity-half': !canMoveUp,
								})}
								onClick={() => canMoveUp && onSwap(index, index - 1)}
							/>
							<Button
								icon="fa-solid fa-down-long"
								className={classNames('p-button-sm p-button-text', {
									'p-button-disabled': !canMoveDown,
									'opacity-full': canMoveDown,
									'opacity-half': !canMoveDown,
								})}
								onClick={() => canMoveDown && onSwap(index, index + 1)}
							/>
							<span id={`disabled-button-${question.id}`}>
								<Button
									className="p-button-text p-button-label p-button-danger text-black-alpha-80 p-button-sm"
									icon="pi pi-trash text-pink-500 w-1"
									onClick={() => setConfirmRemoveDialog(true)}
									label={t('actions.remove')}
									size="sm"
									disabled={isDeleteDisabled}
								/>
							</span>
							<Tooltip
								disabled={!isDeleteDisabled}
								target={`#disabled-button-${question.id}`}
								position="right"
							>
								{tooltipText}
							</Tooltip>
						</div>
					</div>
					<div>{renderCopy()}</div>
				</div>
			) : (
				<div>{renderEdit()}</div>
			)}
		</div>
	);
};

export default QuestionCard;
