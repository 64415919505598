import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from '@tanstack/react-query';
import { applicationsApi, landingPagesApi, programsApi } from 'api';
import { Spinner } from 'components/_new/Spinner';
import FormQuestions from '../userPanel/components/FormQuestions';
import { Dialog, DialogBaseProps, DialogConfirm } from '../_new/Dialog';
import { ApplicationRole, ApplicationType } from '../../types/Application';
import { User } from '../../types/User';

interface EditApplicationProps extends DialogBaseProps {
	onHide: () => void;
	applicationData?: any;
	programMembershipId: number | null;
	applicationType: ApplicationType;
	applicationRole: ApplicationRole;
	editMode: boolean;
	programId: number;
	onApplicationAdded: () => void;
	user?: User | null;
	onCancel: () => void;
	addNewMode?: boolean;
}

export const EditApplicationDialog = ({
	visible,
	onHide,
	applicationData,
	programMembershipId,
	editMode,
	applicationRole,
	applicationType,
	programId,
	onApplicationAdded,
	user,
	onCancel,
	addNewMode = false,
}: EditApplicationProps) => {
	const { t } = useTranslation();
	const [skipConfirm, setSkipConfirm] = useState(false);
	const [cancelEdit, setCancelEdit] = useState(false);

	useEffect(() => {
		if (visible) {
			setSkipConfirm(false);
		}
	}, [visible]);

	const {
		data: questionsData,
		isLoading: isQuestionsLoading,
		isRefetching: isQuestionsRefetching,
	} = useQuery(
		['questions', { programId: Number(programId), applicationType, applicationRole }],
		() =>
			landingPagesApi.getFormQuestions(programId, {
				applicationType,
				applicationRole,
			}),
		{ enabled: visible && !!programMembershipId }
	);
	const isLoading = isQuestionsLoading || isQuestionsRefetching || !questionsData || !programMembershipId;

	return (
		<>
			<DialogConfirm
				visible={cancelEdit}
				onHide={() => {
					setCancelEdit(false);
				}}
				onReject={() => {
					setSkipConfirm(false);
				}}
				onConfirm={() => {
					onCancel();
					onHide();
				}}
			/>
			<Dialog
				title={t('misc.pageTitles.editApplication')}
				onHide={() => {
					if (!skipConfirm) {
						setCancelEdit(true);
					}
				}}
				visible={visible}
			>
				<Helmet title={t('misc.pageTitles.editApplication')} />
				{isLoading ? (
					<Spinner />
				) : (
					<FormQuestions
						key={applicationType === 'entry' ? 'entry' : 'proper'}
						type={applicationRole === 'mentee' ? 'mentee' : 'mentor'}
						programMembershipId={programMembershipId}
						questionsData={questionsData}
						membershipData={applicationData}
						applicationType={applicationType}
						editMode={editMode}
						programPanel
						addNewMode={addNewMode}
						onSubmit={() => {
							setSkipConfirm(true);
							onApplicationAdded();
							onHide();
						}}
						programId={programId}
						user={user}
					/>
				)}
			</Dialog>
		</>
	);
};
