import { httpClient, objectToFormData } from 'api';
import { Answer, Application } from 'types/Application';
import { Organization } from 'types/Organization';
import { Program, ProgramWithLanding } from 'types/Program';
import { Question } from 'types/Question';
import { ProgramMembershipPayload } from 'types/payloads/ProgramMembershipPayload';
import { ApplicationPayload } from 'types/payloads/ApplicationPayload';
import { serialize } from 'object-to-formdata';

export const landingPagesApi = {
	getOrganization: (organization: string) => httpClient.get<Organization>(`organizations/${organization}`),
	joinOrganization: (organization: string) => httpClient.post(`organizations/${organization}/join`),
	// clearOrganizations: () => httpClient.get('organizations/clear'), // TODO remove, for testing purposes only

	getProgram: (organization: string, program: string) =>
		httpClient.get<Program>(`programs/${organization}/${program}`),

	getProgramWithLanding: (organization: string, program: string) =>
		httpClient.get<ProgramWithLanding>(`programs/${organization}/${program}`, {
			landing: true,
		}),

	joinProgram: (organization: string, program: string) => httpClient.post(`programs/${organization}/${program}/join`),
	// clearPrograms: () => axiosInstance.get('programs/clear'), // TODO remove, for testing purposes only

	getProgramApplications: (programMembershipId: number, payload: ProgramMembershipPayload) =>
		httpClient.get<Application[]>(`programMemberships/${programMembershipId}/applications`, payload),
	cancelProgramApplication: (programMembershipId: number, payload: ProgramMembershipPayload) =>
		httpClient.post<Application[]>(`programMemberships/${programMembershipId}/cancel`, payload),

	postProgramApplications: (programMembershipId: number, payload: ApplicationPayload) => {
		const formData = objectToFormData(payload);
		return httpClient.post(`programMemberships/${programMembershipId}/applications`, formData);
	},
	uploadProgramApplicationFile: (programMembershipId: number, body: any) => {
		const formData = serialize(body);
		return httpClient.post<Answer>(`programMemberships/${programMembershipId}/applications/file`, formData);
	},
	removeProgramApplicationFile: (programMembershipId: number, data: any) => {
		const formData = serialize(data);
		return httpClient.put<boolean>(`programMemberships/${programMembershipId}/applications/file`, formData);
	},

	getFormQuestions: (programId: number, payload: ProgramMembershipPayload) =>
		httpClient.get<Question[]>(`questions/${programId}`, payload),
};
