const prepareMultipler = (num: number): number => {
	return Number(`1${Array(num).fill(0).join('')}`);
};

const isValidNumber = (num: number): boolean => {
	return Number.isFinite(num) && !Number.isNaN(num);
};

export const roundFixed = (num: number, decimals = 2): number => {
	const multipler = prepareMultipler(decimals);
	return isValidNumber(num) ? Math.round(num * multipler) / multipler : 0;
};

export const getRandomInt = (min: number, max: number) => {
	const finalMin = Math.ceil(min);
	const finalMax = Math.floor(max);
	return Math.floor(Math.random() * (finalMax - finalMin + 1)) + finalMin;
};
