import React, { FC, useState, useEffect, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { landingPagesApi, applicationsApi } from 'api';
import { useScope } from 'contexts/scopeContext';
import { Question } from 'types/Question';
import { Answer, Application, ApplicationType } from 'types/Application';
import { ApplicationPayload } from 'types/payloads/ApplicationPayload';
import { Button } from 'primereact/button';
import FormQuestion from 'components/landingPages/components/Question';
import { useGlobal } from 'contexts/globalContext';
import PageMissing from 'components/PageMissing';
import { Spinner } from 'components/_new/Spinner';
import { universalRenderer } from 'utils/universalRenderer';
import { getRandomInt } from 'utils/number';
import { User } from '../../../types/User';

export interface ApplyMembershipFormObject {
	form: Answer[];
}

interface FormQuestionsProps {
	setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
	type: 'mentor' | 'mentee';
	programMembershipId: number;
	questionsData: Question[];
	membershipData?: Application;
	applicationType: ApplicationType;
	editMode?: boolean;
	programPanel?: boolean;
	addNewMode?: boolean;
	onSubmit?: () => void;
	programId: number;
	user?: User | null;
	// renderBottom?: ReactNode | (() => ReactNode);
}
const FormQuestions: FC<FormQuestionsProps> = (props) => {
	const {
		setIsSubmitted,
		type,
		programMembershipId,
		questionsData,
		membershipData,

		editMode,
		programPanel,
		addNewMode,
		programId,
		user,
		onSubmit,
	} = props;

	const navigate = useNavigate();
	const { t } = useTranslation();
	const { organizationName, programName } = useParams();

	const { toastRef } = useGlobal();
	const { currentProgramMembership, applicationsRefetch } = useScope();
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const { control, handleSubmit, reset, setValue, getValues, setError } = useForm<ApplyMembershipFormObject>({
		defaultValues: {},
	});

	const { fields } = useFieldArray({
		name: 'form',
		control,
	});

	const { mutate: postEditAnswers, isLoading: isEditSubmitting } = useMutation(
		(data: ApplicationPayload) => {
			return applicationsApi.adminApplicationEdit(programId, programMembershipId, data);
		},
		{
			onSuccess: () => {
				if (setIsSubmitted) {
					setIsSubmitted(true);
				}
				if (onSubmit) {
					onSubmit();
				}
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.application.dataUpdatedSuccessfully'),
				});
			},
		}
	);

	const { mutate: postFormAnswers, isLoading: isSubmitting } = useMutation(
		(data: ApplicationPayload) => {
			return landingPagesApi.postProgramApplications(programMembershipId, data);
		},
		{
			onSuccess: () => {
				if (setIsSubmitted) {
					setIsSubmitted(true);
				}
				if (currentProgramMembership) {
					applicationsRefetch();
				}
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.application.dataUpdatedSuccessfully'),
				});
			},
		}
	);

	const { mutateAsync: uploadFileUser, isLoading: uploadFileLoadingUser } = useMutation(
		({ questionId, file }: { questionId: number; file: File }) =>
			landingPagesApi.uploadProgramApplicationFile(programMembershipId, {
				questionId,
				file,
				applicationRole: type,
			})
	);

	const { mutateAsync: removeFileUser, isLoading: removeFileLoadingUser } = useMutation(
		({ questionId }: { questionId: number; index: number }) =>
			landingPagesApi.removeProgramApplicationFile(programMembershipId, {
				questionId,
				applicationRole: type,
			}),
		{
			onSuccess: (data, { index }) => {
				setValue(`form.${index}.value`, null);
			},
		}
	);

	// temp form
	const [tempId, setTempId] = useState<number | null>(null);
	useEffect(() => {
		if (!addNewMode || !tempId || !String(tempId).length) {
			setTempId(getRandomInt(10000, 99999));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempId, addNewMode]);

	const { mutateAsync: uploadFileAdmin, isLoading: uploadFileLoadingAdmin } = useMutation(
		({ questionId, file }: { questionId: number; file: File }) =>
			applicationsApi.adminUploadProgramApplicationFile(programId, Number(membershipData?.id), {
				questionId,
				file,
				applicationRole: type,
			})
	);

	const { mutateAsync: removeFileAdmin, isLoading: removeFileLoadingAdmin } = useMutation(
		({ questionId }: { questionId: number; index: number }) =>
			applicationsApi.adminRemoveProgramApplicationFile(programId, Number(membershipData?.id), {
				questionId,
				applicationRole: type,
			}),
		{
			onSuccess: (data, { index }) => {
				setValue(`form.${index}.value`, null);
			},
		}
	);

	const { mutateAsync: uploadFileNewAdmin, isLoading: uploadFileNewLoadingAdmin } = useMutation(
		({ questionId, file }: { questionId: number; file: File }) =>
			applicationsApi.adminUploadProgramMembershipFile(programId, programMembershipId, {
				questionId,
				file,
				applicationRole: type,
				tempId,
			})
	);

	const { mutateAsync: removeFileNewAdmin, isLoading: removeFileLNewoadingAdmin } = useMutation(
		({ questionId }: { questionId: number; index: number }) =>
			applicationsApi.adminRemoveProgramMembershipFile(programId, programMembershipId, {
				questionId,
				applicationRole: type,
				tempId,
			}),
		{
			onSuccess: (data, { index }) => {
				setValue(`form.${index}.value`, null);
			},
		}
	);

	// eslint-disable-next-line no-nested-ternary
	const uploadFile = programPanel ? (addNewMode ? uploadFileNewAdmin : uploadFileAdmin) : uploadFileUser;
	// eslint-disable-next-line no-nested-ternary
	const removeFile = programPanel ? (addNewMode ? removeFileNewAdmin : removeFileAdmin) : removeFileUser;
	const fileFileActionLoading = programPanel
		? uploadFileLoadingAdmin || uploadFileNewLoadingAdmin || removeFileLoadingAdmin || removeFileLNewoadingAdmin
		: uploadFileLoadingUser || removeFileLoadingUser;

	useEffect(() => {
		if (editMode) {
			setIsLoading(true);
		}
		if (!editMode) {
			const tempValues = questionsData.map((question) => {
				let value = '';
				if (question.systemQuestionType === 'surname') {
					value = user?.lastName || '';
				} else if (question.systemQuestionType === 'name') {
					value = user?.firstName || '';
				}
				return {
					value,
					questionId: question.id,
				};
			});
			reset({
				form: tempValues,
			});
		}
		if (membershipData) {
			const tempValues = questionsData.map((question) => {
				const answer = membershipData.answers.find((answer) => answer.questionId === question.id);

				let value = answer?.value ?? '';
				if (value === 'true') {
					value = true;
				}
				if (value === 'false') {
					value = false;
				}

				return {
					value,
					questionId: question.id,
				};
			});
			reset({
				form: tempValues,
			});
		}

		setIsLoading(false);
	}, [questionsData, membershipData, reset, editMode]);

	const handleFormData = handleSubmit((data: ApplyMembershipFormObject) => {
		const preparedPayload = {
			applicationRole: type,
			applicationType: membershipData?.applicationType ?? questionsData[0].applicationType,
			answers: data.form,
		};
		if (programPanel) {
			if (addNewMode && tempId) {
				postEditAnswers({ tempId, ...preparedPayload });
			} else {
				postEditAnswers(preparedPayload);
			}
		} else {
			postFormAnswers(preparedPayload);
		}
	});

	if (isError) {
		return <PageMissing />;
	}

	if (fields.length !== questionsData.length || isLoading) {
		return <Spinner />;
	}
	return (
		<form className="flex flex-column row-gap-4" onSubmit={handleFormData}>
			{fields.map((field, index) => (
				<FormQuestion
					key={field.id}
					field={field}
					index={index}
					questionsData={questionsData}
					setIsError={setIsError}
					control={control}
					setValue={setValue}
					getValues={getValues}
					setError={setError}
					onFileUpload={uploadFile}
					onFileRemove={removeFile}
					fileActionLoading={fileFileActionLoading}
				/>
			))}
			<Button
				type="submit"
				label={editMode ? t('actions.save') : t('landingPages.buttons.sendForm')}
				className="border-round-3xl align-self-end w-6 mt-3"
				loading={isSubmitting || isEditSubmitting}
			/>
		</form>
	);
};

export default FormQuestions;
