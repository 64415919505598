/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import { Format, Post } from 'types/Dashboard';
import { User } from 'types/User';
import { dateFormat } from 'utils/dateFormat';
import { userDisplayName } from 'utils/userDisplayName';
import { Box, BoxSection } from 'components/_new/Box';
import { AttachmentList } from 'components/_new/Attachment';
import { Link } from 'components/_new/Link';
import { useProgramPanel } from 'contexts/programPanelContext';
import NotificationPostComments from './NotificationPostComments';
import { NotificationAvatar } from './NotificationAvatar';
import './NotificationPost.scss';

interface Props {
	postData: Post;
	withComments?: boolean;
	adminMode?: boolean;
	getPostCommentsRequest?: (params: any) => void;
	addPostCommentRequest?: (data: { postId: number; payload: any }) => void;
}

const NotificationPost = ({
	postData,
	withComments = false,
	adminMode = false,
	getPostCommentsRequest,
	addPostCommentRequest,
}: Props) => {
	const { t } = useTranslation();
	const { panelPath } = useProgramPanel();
	const { format, title, text, user, postedBy, menteeAccess, mentorAccess, updatedAt, applicationRecievers } =
		postData;

	const preparedName = useMemo(() => {
		switch (postedBy) {
			case 'application':
				return 'Mentiway';
			case 'mentibot':
				return 'Mentibot';
			case 'user':
			default:
				return userDisplayName(user as User);
		}
	}, [postedBy, user]);

	const getPostScope = () => {
		if (menteeAccess && mentorAccess) return t('userPanel.dashboard.everyone');
		if (menteeAccess) return t('userPanel.dashboard.everyMentee');
		if (mentorAccess) return t('userPanel.dashboard.everyMentor');
		return t('userPanel.dashboard.you');
	};

	const [showAllMode, setShowAllMode] = useState(false);
	const renderRecievers = () => {
		if (adminMode && applicationRecievers && applicationRecievers.length > 0) {
			const applicationUsers = applicationRecievers.map(({ id, programMembership }) => ({
				applicationId: id,
				displayName: userDisplayName(programMembership.user),
			}));
			return (
				<>
					<ul className="colon-list">
						{!showAllMode &&
							applicationUsers.slice(0, 3).map(({ applicationId, displayName }) => (
								<li key={applicationId} className="colon-list-item">
									<Link to={{ pathname: `${panelPath}/application/${applicationId}` }} variant="grey">
										{displayName}
									</Link>
								</li>
							))}

						{showAllMode &&
							applicationUsers.map(({ applicationId, displayName }) => (
								<li key={applicationId} className="colon-list-item">
									<Link to={{ pathname: `${panelPath}/application/${applicationId}` }} variant="grey">
										{displayName}
									</Link>
								</li>
							))}
					</ul>
					{!showAllMode && applicationUsers.length > 3 && (
						<Link
							to="#"
							onClick={() => {
								setShowAllMode(true);
							}}
							className="ml-1"
						>
							{t('userPanel.dashboard.andOtherApplications', { count: applicationUsers.length - 3 })}
						</Link>
					)}
				</>
			);
		}
		return getPostScope();
	};

	const getPostTitle = () => (
		<div className="flex">
			<NotificationAvatar postedBy={postedBy} user={user as User} name={preparedName} />
			<div className="ml-3 flex flex-column justify-content-between">
				<span className="text-gray">
					<span className="text-purplish-blue">{preparedName}</span>
					<span className="mx-1 text-lg">&rarr;</span>
					<span className="text-light">{renderRecievers()}</span>
				</span>
				<span className="text-light text-gray">{dateFormat(updatedAt, 'full')}</span>
			</div>
		</div>
	);

	const renderContent = () => {
		switch (format) {
			case Format.MD:
				return <ReactMarkdown>{text}</ReactMarkdown>;
			case Format.HTML:
			default:
				return parse(text);
		}
	};
	return (
		<div className="my-3">
			<Box variant="white-bordered">
				<BoxSection header={getPostTitle()} className="notification-post">
					<div className="font-bold mb-3">{title}</div>
					<div className="notification-post-content">{renderContent()}</div>
					{postData.attachments && postData.attachments.length > 0 && (
						<AttachmentList attachments={postData.attachments} />
					)}
					{withComments && postData.postedBy !== 'mentibot' && (
						<NotificationPostComments
							postId={postData.id}
							currentComments={postData.preComments}
							getPostCommentsRequest={getPostCommentsRequest}
							addPostCommentRequest={addPostCommentRequest}
							adminMode={adminMode}
						/>
					)}
				</BoxSection>
			</Box>
		</div>
	);
};

export default NotificationPost;
