import React, { useState, useEffect, useId } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { InputText } from '../../_new/InputText';
import { QuestionTypes } from '../../../types/Question';
import { InputTextArea } from '../../_new/InputTextArea';

type InputTextEditWrapperProps = {
	initialLabel: string;
	initialText: string;
	initialLinks: string[];
	required: boolean;
	hiddenFromUsers: boolean;
	withLinks: boolean;
	onSave: (text: string, label: string, links: string[], required: boolean, hiddenFromUsers: boolean) => void;
	onCancel: () => void;
	questionType: QuestionTypes;
};

interface EditFormObject {
	questionText: string;
	questionLabel: string;
	link: string;
	isRequired: boolean;
	hiddenFromUsers: boolean;
}

export const InputTextEditWrapper: React.FC<InputTextEditWrapperProps> = ({
	initialLabel,
	initialText,
	initialLinks,
	required,
	hiddenFromUsers,
	withLinks,
	onSave,
	onCancel,
	questionType,
}) => {
	const { t } = useTranslation();
	const requiredCheckboxId = useId();
	const hiddenFromUsersCheckboxId = useId();
	const [links, setLinks] = useState<string[]>(initialLinks);
	const [editing, setEditing] = useState<number | null>(null);
	const { control, handleSubmit, reset, setValue } = useForm<EditFormObject>({
		defaultValues: {
			questionText: initialText,
			questionLabel: initialLabel,
			isRequired: required,
			hiddenFromUsers,
		},
	});

	useEffect(() => {
		setValue('questionLabel', initialLabel);
		setValue('isRequired', required);
		setValue('hiddenFromUsers', hiddenFromUsers);
	}, [initialLabel, required, hiddenFromUsers, setValue]);

	const handleSave = (data: EditFormObject) => {
		onSave(data.questionText, data.questionLabel, links, data.isRequired, data.hiddenFromUsers);
	};

	const questionContentLabel = getLabelForType(questionType, t);
	const questionContentText = getTextForType(questionType, t);

	const handleAddOption = (e: React.MouseEvent) => {
		e.preventDefault();
		setLinks((prevItems) => [...prevItems, '']);
		setEditing(links.length);
		setValue('link', '');
	};

	const handleEditOption = (index: number) => {
		const option = links[index];
		setEditing(index);
		setValue('link', option);
	};

	const handleDeleteOption = (index: number) => {
		setLinks((prevItems) => prevItems.filter((_, i) => i !== index));
		setEditing(null);
	};

	const handleFormSubmit = (data: EditFormObject) => {
		setLinks((prevItems) => {
			const updatedItems = [...prevItems];
			if (editing !== null) {
				updatedItems[editing] = data.link.trim();
			}
			return updatedItems;
		});
		setEditing(null);
		setValue('link', '');
	};

	return (
		<div>
			<div className="field mt-2">
				<Controller
					name="questionText"
					control={control}
					rules={{ required: t('programPanel.editQuestion.questionTextIsRequired') }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="text">
								{questionContentText}
							</label>
							{questionType === QuestionTypes.infoText ? (
								<InputTextArea
									{...field}
									placeholder={t('programPanel.editQuestion.enterQuestionTextPlaceholder')}
								/>
							) : (
								<InputText
									{...field}
									placeholder={t('programPanel.editQuestion.enterQuestionTextPlaceholder')}
								/>
							)}
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			{![QuestionTypes.infoHeader, QuestionTypes.infoText].includes(questionType) && (
				<div className="field mt-2">
					<Controller
						name="questionLabel"
						control={control}
						// rules={{ required: 'Question label is required' }}
						render={({ field, fieldState }) => (
							<>
								<label className="ml-2 field-label" htmlFor="questionLabel">
									{questionContentLabel}
								</label>
								{questionType === QuestionTypes.checkbox ? (
									<InputTextArea
										{...field}
										placeholder={t('programPanel.editQuestion.enterQuestionLabelPlaceholder')}
										style={{
											whiteSpace: 'pre-wrap',
											minHeight: '80px',
											resize: 'vertical',
										}}
										rows={3}
										maxLength={1000}
										onChange={(e) => {
											field.onChange(e);
											const textarea = e.target;
											textarea.style.height = 'auto';
											textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`;
										}}
									/>
								) : (
									<InputText
										{...field}
										placeholder={t('programPanel.editQuestion.enterQuestionLabelPlaceholder')}
									/>
								)}
								{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
							</>
						)}
					/>
				</div>
			)}
			<div className="mt-3 ml-2">
				<Controller
					name="isRequired"
					control={control}
					render={({ field }) => (
						<Checkbox
							id={requiredCheckboxId}
							{...field}
							inputId="required-checkbox"
							checked={field.value}
							onChange={(e) => setValue('isRequired', e.checked)}
						/>
					)}
				/>
				<label htmlFor={requiredCheckboxId} className="ml-2">
					{t('programPanel.editQuestion.isRequired')}
				</label>
			</div>
			<div className="mt-3 ml-2">
				<Controller
					name="hiddenFromUsers"
					control={control}
					render={({ field }) => (
						<Checkbox
							id={hiddenFromUsersCheckboxId}
							{...field}
							inputId="hiddenFromUsers-checkbox"
							checked={field.value}
							onChange={(e) => setValue('hiddenFromUsers', e.checked)}
						/>
					)}
				/>
				<label className="ml-2 field-label" htmlFor={hiddenFromUsersCheckboxId}>
					{t('programPanel.editQuestion.hiddenFromUsers')}
				</label>
			</div>
			{withLinks && (
				<>
					<ul className="list-none mt-3">
						{links.map((item, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<li key={`${initialLabel}-${item}-${index}`}>
								{editing === index ? (
									<div className="flex align-items-center gap-2">
										<Controller
											name="link"
											control={control}
											rules={{
												required: t('programPanel.editQuestion.optionIsRequired'),
											}}
											render={({ field, fieldState }) => (
												<>
													<InputText
														{...field}
														placeholder={t(
															'programPanel.editQuestion.enterOptionPlaceholder'
														)}
													/>
													{fieldState.error && (
														<small className="p-error">{fieldState.error.message}</small>
													)}

													<Button
														type="button"
														icon="pi pi-check"
														className="p-button-primary p-button-sm"
														onClick={handleSubmit(handleFormSubmit)}
														disabled={!field.value.trim()}
													/>
													<Button
														type="button"
														icon="pi pi-times"
														className="p-button-secondary p-button-sm"
														onClick={() => handleDeleteOption(index)}
													/>
												</>
											)}
										/>
									</div>
								) : (
									<div className="inline-flex align-items-center ml-4">
										<span className="flex justify-center">{item}</span>
										<Button
											className="p-button-text p-button-sm"
											icon="pi pi-pencil text-purple-500"
											onClick={() => handleEditOption(index)}
										/>
										<Button
											className="p-button-text p-button-sm"
											icon="pi pi-trash text-red-500"
											onClick={() => handleDeleteOption(index)}
										/>
									</div>
								)}
							</li>
						))}
					</ul>
					<Button
						className="p-button-text p-button-sm"
						icon="pi pi-plus"
						onClick={handleAddOption}
						disabled={editing !== null}
						label={t('programPanel.editQuestion.addLink')}
					/>
				</>
			)}
			<div className="flex flex-row mt-2">
				<Button label={t('actions.save')} className="p-button p-button-sm" onClick={handleSubmit(handleSave)} />
				<Button
					label={t('actions.cancel')}
					className="p-button p-button-sm p-button-secondary ml-2"
					onClick={onCancel}
				/>
			</div>
		</div>
	);
};

const getLabelForType = (type: QuestionTypes, t: any) => {
	if (type === QuestionTypes.attachment) return t('programPanel.editQuestion.attachmentLabel');
	if (type === QuestionTypes.checkbox) return t('programPanel.editQuestion.checkboxLabel');
	if (type === QuestionTypes.link) return t('programPanel.editQuestion.linkLabel');
	if (type === QuestionTypes.shortText || type === QuestionTypes.longText)
		return t('programPanel.editQuestion.shortTextLabel');
	if (type === QuestionTypes.infoHeader || type === QuestionTypes.infoText)
		return t('programPanel.editQuestion.infoHeaderLabel');

	return t('programPanel.editQuestion.additionalInformations');
};

const getTextForType = (type: QuestionTypes, t: any) => {
	if (type === QuestionTypes.checkbox) return t('programPanel.editQuestion.checkboxText');
	if (type === QuestionTypes.infoHeader) return t('programPanel.editQuestion.infoHeaderText');
	if (type === QuestionTypes.infoText) return t('programPanel.editQuestion.infoHeaderLabel');

	return t('programPanel.editQuestion.questionContent');
};
