import React, { HTMLAttributes, ReactNode } from 'react';
import { classNames } from 'primereact/utils';
import './Badge.scss';

// export type BadgeContent = string | number | (() => JSX.Element) | null;
type BadgeProps = Omit<HTMLAttributes<HTMLSpanElement>, 'children'> & {
	children?: ReactNode;
};

export const Badge = ({ className, children, ...props }: BadgeProps) => {
	const isNumber = typeof children === 'number' && Number.isInteger(children);
	if (isNumber && children === 0) {
		return null;
	}
	if (typeof children === 'string' && children === '') {
		return null;
	}

	return (
		<span className={classNames('badge', className)} {...props}>
			{children}
		</span>
	);
};
