import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, BoxSection } from 'components/_new/Box';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { programsApi } from 'api';
import Handlebars from 'handlebars';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	Droppable,
	DroppableProvided,
	DropResult,
} from 'react-beautiful-dnd';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Button } from 'components/_new/Button';
import {
	ProgramLandingPageModule,
	ProgramLandingPageModuleDefine,
	ProgramLandingPageModuleDefineFieldType,
	ProgramLandingPageModulesAddPayload,
	ProgramLandingPageModulesEditPayload,
	ProgramLandingPageModulesSequencePayload,
	ProgramLandingPageModuleType,
} from 'types/Program';
import { Dropdown } from 'components/_new/Dropdown';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { InputText } from 'components/_new/InputText';
import { InputNumber } from 'components/_new/InputNumber';
import { MarkdownEditor } from 'components/_common/MarkdownEditor/MarkdownEditor';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Link } from 'components/_new/Link';
import { Dialog, DialogConfirm } from 'components/_new/Dialog';
import { clearInput } from 'utils/clearInput';
import { isObjectOfType } from 'utils/object';
import '../../landing-pages.scss';
import { classNames } from 'primereact/utils';

const useUploadImageHandler = (programId: number) => {
	const { mutateAsync, isLoading } = useMutation((file: File) =>
		programsApi.uploadProgramAdminLandingPageModuleImage(programId, { file })
	);
	return { upload: mutateAsync, isLoading };
};

const useModuleTypeMap = (programId: number) => {
	const { t } = useTranslation();

	const {
		data: moduleDefines,
		isLoading: moduleLoading,
		isSuccess: moduleSuccess,
	} = useQuery(
		['GET_PROGRAM_LANDING_PAGE_MODULES_AVAILABLE'],
		() => programsApi.getProgramAdminLandingPageModuleDefines(programId),
		{
			staleTime: 5000,
			enabled: true,
		}
	);

	const map: Array<{ value: ProgramLandingPageModuleType; label: string }> = useMemo(
		() =>
			moduleDefines
				? moduleDefines?.map(({ type }) => ({
						value: type,
						label: t(`programPanel.landingPageEditor.moduleTypeTitle.${String(type)}`),
				  }))
				: [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[moduleDefines]
	);

	return { map, moduleDefines, moduleLoading, moduleSuccess };
};

type ModulePreviewProps = {
	template: string | null;
	type: ProgramLandingPageModuleDefine['type'];
	data?: Record<string, string | number>;
};

const ModulePreview = (props: ModulePreviewProps) => {
	const { template, type, data } = props;

	const { t } = useTranslation();
	const [html, setHtml] = useState<TrustedHTML | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (type === 'wyswig') {
			const preparedHtml = `<div class="lp-custom-module-wyswig">${data?.content || ''}</div>`;
			setHtml(preparedHtml);
		} else if (template && template.length > 0 && data) {
			try {
				const templateObject = Handlebars.compile(template);
				const preparedHtml = templateObject(data);
				setHtml(preparedHtml);
				setError(null);
			} catch (err: any) {
				setError(err?.message || err);
			}
		} else {
			setHtml(null);
			setError(null);
		}
	}, [type, template, data]);

	return (
		<>
			{error && <div className="text-error">{t('programPanel.landingPageEditor.canNotDisplayPreview')}</div>}
			{html && (
				<div className="lp">
					<div
						className={classNames('lp-custom-module', `lp-custom-module-${type}`)}
						dangerouslySetInnerHTML={{ __html: html }}
					/>
				</div>
			)}
		</>
	);
};

type ModuleProps = {
	module: ProgramLandingPageModule;
	onEdit?: (data: ProgramLandingPageModule) => void;
	onDelete?: (module: ProgramLandingPageModule) => void;
	isFirst: boolean;
	isLast: boolean;
	onMoveUp?: (module: ProgramLandingPageModule) => void;
	onMoveDown?: (module: ProgramLandingPageModule) => void;
	draggableProvided?: DraggableProvided;
	isDragging?: boolean;
};

type ModifyModuleForm = {
	sequence: number;
	fields: Array<{ fieldName: string; fieldType: ProgramLandingPageModuleDefineFieldType; value: string | number }>;
};

const Module = (props: ModuleProps) => {
	const { module, onEdit, onDelete, isFirst, isLast, onMoveUp, onMoveDown, draggableProvided } = props;
	const contentObj = module.content;
	const { dragHandleProps, draggableProps, innerRef } = draggableProvided || {
		innerRef: undefined,
		draggableProps: undefined,
		dragHandleProps: undefined,
	};

	const { t } = useTranslation();

	const { data: program } = useProgramPanel();
	const { id } = module;
	const [editMode, setEditMode] = useState(false);
	// const [previewData, setPreviewData] = useState<Record<string, string | number> | undefined>(undefined);

	const onCancel = useCallback(() => {
		// setPreviewData(undefined);
		setEditMode(false);
	}, []);

	const getFieldDefault = () =>
		Object.keys(module?.define?.fields || {}).map((fieldName) => ({
			fieldName,
			value: module.content?.[fieldName] || '',
		}));

	const { control, handleSubmit, getValues, reset, watch } = useForm<ModifyModuleForm>({
		defaultValues: { sequence: module.sequence, fields: getFieldDefault() },
	});

	useEffect(() => {
		const fieldsDefault = getFieldDefault();
		reset({ sequence: module.sequence, fields: fieldsDefault });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const { fields } = useFieldArray({ control, name: 'fields' });

	const { mutate } = useMutation(
		(data: ProgramLandingPageModulesEditPayload) =>
			programsApi.editProgramAdminLandingPageModule(Number(program?.id), id, data),
		{
			onSuccess: (data) => {
				onEdit?.(data);
				setEditMode(false);
			},
		}
	);

	const { mutate: deleteMutate } = useMutation(
		(moduleId: number) => programsApi.deleteProgramAdminLandingPageModule(Number(program?.id), moduleId),
		{
			onSuccess: () => {
				onDelete?.(module);
			},
		}
	);

	const { upload: uploadMutateAsync } = useUploadImageHandler(Number(program?.id));

	const handleSubmitForm = handleSubmit((values) => {
		mutate(values);
	});
	const [previewDialog, setPreviewDialog] = useState(false);
	const [previewDataBump, setPreviewDataBump] = useState(0);
	const handlePreview = useCallback(() => {
		setPreviewDataBump((prev) => prev + 1);
		setPreviewDialog(true);
	}, []);

	const previewData: Record<string, string | number> | undefined = useMemo(() => {
		const defineFields = Object.fromEntries(
			Object.keys(module?.define?.fields || []).map((fieldName) => [fieldName, ''])
		);
		const inputValues = Object.fromEntries(getValues('fields').map(({ fieldName, value }) => [fieldName, value]));
		return { ...defineFields, ...inputValues };
	}, [getValues, module?.define?.fields, previewDataBump]);

	const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

	const templateValue = module?.define?.fields?.template
		? String(watch(`fields.${Object.keys(module.define?.fields).indexOf('template')}.value`))
		: null;
	const currentTemplate =
		module.define && templateValue && isObjectOfType<Record<string, string>>(module.define?.template)
			? module.define.template?.[templateValue]
			: String(module?.define?.template || '');

	return (
		<>
			<Dialog
				title={t('actions.preview')}
				visible={previewDialog}
				onHide={() => setPreviewDialog(false)}
				size="xl"
			>
				{editMode && module?.define && (
					<ModulePreview type={module.define.type} template={currentTemplate} data={previewData} />
				)}
			</Dialog>
			<DialogConfirm
				visible={confirmDeleteDialogOpen}
				onHide={() => setConfirmDeleteDialogOpen(false)}
				onConfirm={() => {
					deleteMutate(module.id);
				}}
			/>
			<form id={`module-form-${module.id}`} onSubmit={handleSubmitForm} />
			<Box ref={innerRef} {...draggableProps} variant="white-bordered" className="my-2" dropShadow>
				<BoxSection
					horizontal
					contentClassName="align-items-center"
					style={{ borderBottom: '1px solid lightGray' }}
				>
					<div className="flex-1" {...dragHandleProps}>
						<h3>{t('programPanel.landingPageEditor.moduleNo', { id: module.sequence })}</h3>
					</div>
					<div className="flex flex-row gap-1">
						{!editMode && (
							<Button
								label={t('actions.edit')}
								icon="pencil"
								iconSet="pi"
								variant="primary-text"
								variantSize="sm"
								onClick={() => setEditMode(true)}
							/>
						)}
						<Button
							label={t('programPanel.landingPageEditor.moveUp')}
							iconOnly
							icon="up-long"
							iconStyle="solid"
							variant="primary-text"
							variantSize="sm"
							disabled={isFirst}
							onClick={() => {
								onMoveUp?.(module);
							}}
						/>
						<Button
							label={t('programPanel.landingPageEditor.moveDown')}
							iconOnly
							icon="down-long"
							iconStyle="solid"
							variant="primary-text"
							variantSize="sm"
							disabled={isLast}
							onClick={() => {
								onMoveDown?.(module);
							}}
						/>
						<Button
							label={t('actions.remove')}
							icon="trash"
							iconSet="pi"
							variant="danger-text"
							variantSize="sm"
							onClick={() => {
								setConfirmDeleteDialogOpen(true);
							}}
						/>
					</div>
				</BoxSection>
				{editMode && (
					<BoxSection>
						<div className="grid grid-nogutter">
							<div className="col-3 flex align-items-center">
								{t('programPanel.landingPageEditor.moduleType')}:
							</div>
							<div className="col-9">
								{t(`programPanel.landingPageEditor.moduleTypeTitle.${module.module}`)}
							</div>
						</div>
						<div className="grid grid-nogutter">
							<div className="col-3 flex align-items-center">
								{t('programPanel.landingPageEditor.sequence')}:
							</div>
							<div className="col-9 align-items-start">
								<Controller
									control={control}
									name="sequence"
									render={({ field }) => (
										<InputNumber inline {...field} value={field.value || 0} min={0} />
									)}
								/>
							</div>
						</div>
					</BoxSection>
				)}
				{editMode && (
					<BoxSection>
						{fields.map(({ fieldName }, index) => {
							const fieldType = module?.define?.fields[fieldName];
							if (Array.isArray(fieldType)) {
								return (
									<div key={fieldName} className="grid grid-nogutter">
										<div className="col-3 flex align-items-center">
											{t(`programPanel.landingPageEditor.moduleFieldName.${fieldName}`)}
										</div>
										<div className="col-9">
											<Controller
												control={control}
												name={`fields.${index}.value`}
												render={({ field }) => (
													<Dropdown
														options={fieldType.map((name) => ({
															value: name,
															label: t(
																`programPanel.landingPageEditor.moduleFieldName.${name}`
															),
														}))}
														{...field}
														onChange={({ value }) => field.onChange(value)}
													/>
												)}
											/>
										</div>
									</div>
								);
							}
							switch (fieldType) {
								case 'image':
									return (
										<Controller
											key={fieldName}
											control={control}
											name={`fields.${index}.value`}
											render={({ field }) => (
												<div className="grid grid-nogutter">
													<div className="col-3 flex align-items-center">
														{t(
															`programPanel.landingPageEditor.moduleFieldName.${fieldName}`
														)}
													</div>
													<div className="col-9">
														<div className="grid grid-nogutter align-items-center flex-wrap">
															<div className="col-3 min200px">
																<InputText
																	type="file"
																	onChange={(event) => {
																		const file = event.currentTarget.files?.[0];
																		if (file) {
																			uploadMutateAsync(file).then((url) => {
																				field?.onChange(url);
																				setTimeout(() => {
																					clearInput(event.currentTarget);
																				}, 100);
																			});
																		}
																	}}
																/>
															</div>
															<div className="col-9 flex flex-row flex-nowrap align-items-center">
																<span className="text-center mx-2">{t('or')}</span>
																<InputText placeholder="https://..." {...field} />
															</div>
														</div>
													</div>
												</div>
											)}
										/>
									);
								case 'wyswig':
									return (
										<Controller
											key={fieldName}
											control={control}
											name={`fields.${index}.value`}
											render={({ field }) => (
												<div className="grid grid-nogutter">
													<div className="col-3 flex align-items-center">
														{t(
															`programPanel.landingPageEditor.moduleFieldName.${fieldName}`
														)}
													</div>
													<div className="col-9">
														<MarkdownEditor
															defaultValue={String(contentObj?.[fieldName] || '')}
															onChange={field?.onChange}
														/>
													</div>
												</div>
											)}
										/>
									);
								case 'text':
								default:
									return (
										<Controller
											key={fieldName}
											control={control}
											name={`fields.${index}.value`}
											render={({ field }) => (
												<div className="grid grid-nogutter">
													<div className="col-3 flex align-items-center">
														{t(
															`programPanel.landingPageEditor.moduleFieldName.${fieldName}`
														)}
													</div>
													<div className="col-9">
														<InputText {...field} className="w-full" />
													</div>
												</div>
											)}
										/>
									);
							}
						})}
					</BoxSection>
				)}
				{editMode && (
					<BoxSection horizontal contentClassName="justify-content-between">
						<Button label={t('actions.cancel')} onClick={() => onCancel?.()} />
						<div className="flex flex-nowrap gap-2">
							<Button label={t('actions.preview')} onClick={handlePreview} variant="primary-outlined" />
							<Button submit form={`module-form-${module.id}`} label={t('actions.save')} />
						</div>
					</BoxSection>
				)}
				{!editMode && module.define && (
					<div className="lp">
						<div
							className={classNames('lp-custom-module', `lp-custom-module-${module.define.type}`)}
							dangerouslySetInnerHTML={{ __html: module.compiled }}
						/>
					</div>
				)}
			</Box>
		</>
	);
};

type NewModuleForm = ModifyModuleForm & {
	type: string;
};

type NewModuleProps = {
	onAdd?: () => void;
	onCancel?: () => void;
};

const NewModule = ({ onAdd, onCancel }: NewModuleProps) => {
	const { t } = useTranslation();
	const { data: program } = useProgramPanel();
	const {
		map: moduleTypeOptions,
		moduleDefines,
		moduleLoading,
		moduleSuccess,
	} = useModuleTypeMap(Number(program?.id));
	const [previewData, setPreviewData] = useState<Record<string, string | number> | undefined>(undefined);

	const { control, handleSubmit, watch, setValue, getValues } = useForm<NewModuleForm>({
		defaultValues: { type: ProgramLandingPageModuleType.HEADER, sequence: 1, fields: [] },
	});
	const { fields } = useFieldArray({ control, name: 'fields' });
	const currentModuleType = watch('type');

	const { upload: uploadMutateAsync } = useUploadImageHandler(Number(program?.id));

	const currentDefine = useMemo(
		() =>
			moduleDefines && currentModuleType
				? moduleDefines?.find(({ type }) => String(type) === currentModuleType)
				: null,
		[moduleDefines, currentModuleType]
	);

	useEffect(() => {
		if (currentDefine && currentModuleType) {
			setValue(
				'fields',
				Object.entries(currentDefine.fields).map(([fieldName, fieldType]) => ({
					fieldName,
					fieldType,
					value: isObjectOfType(fieldType) ? Object.keys(fieldType)[0] : '',
				}))
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentDefine, currentModuleType, moduleDefines]);

	const { mutate: addMutate } = useMutation(
		(data: ProgramLandingPageModulesAddPayload) =>
			programsApi.addProgramAdminLandingPageModule(Number(program?.id), data),
		{
			onSuccess: () => {
				onAdd?.();
			},
		}
	);

	const handleSubmitForm = handleSubmit((values) => {
		addMutate(values);
	});

	const [previewDialog, setPreviewDialog] = useState(false);
	const handlePreview = useCallback(() => {
		setPreviewDialog(true);
		if (currentDefine) {
			const defineFields = Object.fromEntries(
				Object.keys(currentDefine?.fields || []).map((fieldName) => [fieldName, ''])
			);
			const inputValues = Object.fromEntries(
				getValues('fields').map(({ fieldName, value }) => [fieldName, value])
			);
			setPreviewData({ ...defineFields, ...inputValues });
		} else {
			setPreviewData(undefined);
		}
	}, [currentDefine, getValues]);

	const handleCancel = () => {
		setPreviewData(undefined);
		onCancel?.();
	};

	const templateValue = currentDefine?.fields?.template
		? String(watch(`fields.${Object.keys(currentDefine?.fields).indexOf('template')}.value`))
		: null;
	const currentTemplate =
		currentDefine && templateValue && isObjectOfType<Record<string, string>>(currentDefine.template)
			? currentDefine.template[templateValue]
			: String(currentDefine?.template || '');

	return (
		<>
			<Dialog
				title={t('actions.preview')}
				visible={previewDialog}
				onHide={() => setPreviewDialog(false)}
				size="xl"
			>
				{currentDefine && (
					<ModulePreview type={currentDefine.type} template={currentTemplate} data={previewData} />
				)}
			</Dialog>
			<form id="new-module-form" onSubmit={handleSubmitForm} />
			<Box variant="white-bordered" dropShadow>
				<BoxSection>
					<div className="grid grid-nogutter">
						<div className="col-3 flex align-items-center">
							{t('programPanel.landingPageEditor.moduleType')}:
						</div>
						<div className="col-9">
							<Controller
								control={control}
								name="type"
								render={({ field }) => (
									<Dropdown
										options={moduleTypeOptions}
										{...field}
										inputRef={field.ref}
										onChange={({ value }) => field.onChange(value)}
									/>
								)}
							/>
						</div>
					</div>
					<div className="grid grid-nogutter">
						<div className="col-3 flex align-items-center">
							{t('programPanel.landingPageEditor.sequence')}:
						</div>
						<div className="col-9 align-items-start">
							<Controller
								control={control}
								name="sequence"
								render={({ field }) => <InputNumber {...field} min={0} />}
							/>
						</div>
					</div>
				</BoxSection>
				<BoxSection>
					{fields.map(({ fieldName, fieldType }, index) => {
						if (Array.isArray(fieldType)) {
							return (
								<Controller
									key={fieldName}
									control={control}
									name={`fields.${index}.value`}
									render={({ field }) => (
										<div className="grid grid-nogutter">
											<div className="col-3 flex align-items-center">
												{t(`programPanel.landingPageEditor.moduleFieldName.${fieldName}`)}
											</div>
											<div className="col-9">
												<Dropdown
													options={fieldType.map((name) => ({
														value: name,
														label: t(
															`programPanel.landingPageEditor.moduleFieldName.${name}`
														),
													}))}
													{...field}
													onChange={({ value }) => field.onChange(value)}
												/>
											</div>
										</div>
									)}
								/>
							);
						}
						switch (fieldType) {
							case 'image':
								return (
									<Controller
										key={fieldName}
										control={control}
										name={`fields.${index}.value`}
										render={({ field }) => (
											<div className="grid grid-nogutter">
												<div className="col-3 flex align-items-center">
													{t(`programPanel.landingPageEditor.moduleFieldName.${fieldName}`)}
												</div>
												<div className="col-9">
													<div className="grid grid-nogutter align-items-center flex-wrap">
														<div className="col-3 min200px">
															<InputText
																type="file"
																onChange={(event) => {
																	const file = event.currentTarget.files?.[0];
																	if (file) {
																		uploadMutateAsync(file).then((url) => {
																			field?.onChange(url);
																			setTimeout(() => {
																				clearInput(event.currentTarget);
																			}, 100);
																		});
																	}
																}}
															/>
														</div>
														<div className="col-9 flex flex-row flex-nowrap align-items-center">
															<span className="text-center mx-2">{t('or')}</span>
															<InputText placeholder="https://..." {...field} />
														</div>
													</div>
												</div>
											</div>
										)}
									/>
								);
							case 'wyswig':
								return (
									<div key={fieldName} className="grid grid-nogutter">
										<div className="col-3 flex align-items-center">
											{t(`programPanel.landingPageEditor.moduleFieldName.${fieldName}`)}
										</div>
										<div className="col-9">
											<Controller
												key={fieldName}
												control={control}
												name={`fields.${index}.value`}
												render={({ field }) => (
													<MarkdownEditor defaultValue="" onChange={field?.onChange} />
												)}
											/>
										</div>
									</div>
								);
							case 'text':
							default:
								return (
									<div key={fieldName} className="grid grid-nogutter ">
										<div className="col-3 flex align-items-center">
											{t(`programPanel.landingPageEditor.moduleFieldName.${fieldName}`)}
										</div>
										<div className="col-9">
											<Controller
												control={control}
												name={`fields.${index}.value`}
												render={({ field }) => <InputText {...field} className="w-full" />}
											/>
										</div>
									</div>
								);
						}
					})}
				</BoxSection>
				<BoxSection horizontal contentClassName="justify-content-between">
					<Button label={t('actions.cancel')} onClick={handleCancel} variant="primary-outlined" />
					<div className="flex flex-nowrap gap-2">
						<Button
							label={t('actions.preview')}
							onClick={handlePreview}
							disabled={!moduleSuccess}
							loading={moduleLoading}
						/>
						<Button submit form="new-module-form" label={t('actions.add')} />
					</div>
				</BoxSection>
			</Box>
		</>
	);
};

export const ProgramLandingPageEditor = () => {
	const { t } = useTranslation();
	const { data: program, fullProgramUrl, programPath } = useProgramPanel();
	const [newModuleMode, setNewModuleMode] = useState(false);
	const [localModules, setLocalModules] = useState<ProgramLandingPageModule[]>([]);

	const { refetch: modulesRefetch } = useQuery(
		['GET_PROGRAM_LANDING_PAGE_MODULES', { programId: Number(program?.id) }],
		() => programsApi.getProgramAdminLandingPageModule(Number(program?.id)),
		{
			enabled: Boolean(program?.id),
			onSuccess: (data) => {
				setLocalModules(data);
			},
		}
	);

	const { mutate: reorderMutate, isLoading: reorederLoading } = useMutation(
		(data: ProgramLandingPageModulesSequencePayload) =>
			programsApi.setProgramAdminLandingPageModuleSequence(Number(program?.id), data)
	);
	const reorder = (list: ProgramLandingPageModule[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result.map((item, index) => ({ ...item, sequence: index + 1 }));
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		const items = reorder(localModules, result.source.index, result.destination.index);
		reorderMutate(items.map(({ id, sequence }) => ({ id, sequence })));
		setLocalModules(items);
	};

	const handleMoveUp = useCallback(
		(module: ProgramLandingPageModule) => {
			if (!reorederLoading) {
				const currentIndex = localModules.findIndex((m) => m.id === module.id);
				if (currentIndex > 0) {
					setLocalModules((prev) => {
						const next = reorder(prev, currentIndex, currentIndex - 1);
						reorderMutate(next.map(({ id }, index) => ({ id, sequence: index + 1 })));
						return next.map((module, index) => ({ ...module, sequence: index + 1 }));
					});
				}
			}
		},
		[reorederLoading, localModules]
	);

	const handleMoveDown = useCallback(
		(module: ProgramLandingPageModule) => {
			if (!reorederLoading) {
				const currentIndex = localModules.findIndex((m) => m.id === module.id);
				if (currentIndex < localModules.length - 1) {
					setLocalModules((prev) => {
						const next = reorder(prev, currentIndex, currentIndex + 1);
						reorderMutate(next.map(({ id }, index) => ({ id, sequence: index + 1 })));
						return next.map((module, index) => ({ ...module, sequence: index + 1 }));
					});
				}
			}
		},
		[reorederLoading, localModules]
	);

	const handleDelete = (module: ProgramLandingPageModule) => {
		setLocalModules((prev) => prev.filter(({ id }) => id !== module.id));
	};

	const handleOnEdit = (data: ProgramLandingPageModule) => {
		setLocalModules((prev) =>
			prev.map((mod) => {
				if (mod.id === data.id) {
					return { ...mod, ...data };
				}
				return mod;
			})
		);
	};

	return (
		<>
			<Helmet title={t('programPanel.landingPageEditor.title')} />
			<SubPageTitle
				title={t('programPanel.landingPageEditor.title')}
				right={
					<Link to={`/program/${program?.name}/config`} className="p-button p-button-text p-button-sm p-0">
						{t('programPanel.landingPageEditor.backToProgramConfig')}
					</Link>
				}
			/>

			<p>
				<Trans
					t={t}
					i18nKey="programPanel.landingPageEditor.description"
					values={{ preview: fullProgramUrl }}
					components={[<Link to={{ pathname: `/${programPath}` }} target="_blank" />]}
				/>
			</p>

			<div className="flex flex-column gap-2">
				{newModuleMode && (
					<NewModule
						onAdd={() => {
							setNewModuleMode(false);
							modulesRefetch();
						}}
						onCancel={() => {
							setNewModuleMode(false);
						}}
					/>
				)}

				{!newModuleMode && (
					<Button
						label={t('programPanel.landingPageEditor.addNewModule')}
						icon="plus"
						variant="primary-outlined"
						variantSize="lg"
						onClick={() => setNewModuleMode(true)}
					/>
				)}
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="modules">
						{(droppableProvided: DroppableProvided) => (
							<div
								className="flex flex-column"
								{...droppableProvided.droppableProps}
								ref={droppableProvided.innerRef}
							>
								{!newModuleMode &&
									localModules.map((module, index) => {
										const isFirst = index === 0;
										const isLast = localModules.length - 1 === index;
										return (
											<Draggable key={module.id} draggableId={`item-${module.id}`} index={index}>
												{(
													draggableProvided: DraggableProvided,
													snapshot: DraggableStateSnapshot
												) => (
													<Module
														key={module.id}
														module={module}
														onEdit={handleOnEdit}
														onDelete={handleDelete}
														isFirst={isFirst}
														isLast={isLast}
														onMoveUp={handleMoveUp}
														onMoveDown={handleMoveDown}
														draggableProvided={{ ...draggableProvided }}
														isDragging={snapshot.isDragging}
													/>
												)}
											</Draggable>
										);
									})}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</>
	);
};
