import React, { useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { RouterUrlParams } from 'App';
import { useScope } from 'contexts/scopeContext';
import { ProgramWithLanding } from 'types/Program';
import { LandingPageFormsAvailability } from 'types/LandingPageFormsAvailability';
import WelcomeMessage from 'components/landingPages/components/WelcomeMessage';
import UserSettings from 'components/_common/UserSettings';
import ChangeOrganization from 'components/_common/panel/ChangeOrganization';
import { fixedT } from 'locale';
import { useAuth } from 'contexts/authContext';
import { border, colors } from 'theme';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import MobileUserSettings from 'components/_common/MobileUserSettings';
import { Dialog } from 'components/_new/Dialog';
import { useNavigate } from 'hooks/useNavigate';
import { ChatButton } from 'components/chat/ChatButton';
import GuestMenu from '../../_common/GuestMobileSettings';

interface TopbarProps {
	programData: ProgramWithLanding;
	formsAvailability?: LandingPageFormsAvailability;
}
const Topbar = ({ programData, formsAvailability }: TopbarProps) => {
	const { organizationName, programName } = useParams() as RouterUrlParams;
	const navigate = useNavigate();
	const location = useLocation();
	const { currentUser } = useAuth();
	const [showOrgDialog, setShowOrgDialog] = useState(false);
	const { applicationsData } = useScope();
	const hasAcceptedApplication = applicationsData.some(({ approved }) => approved === 'accepted');

	const { displayName, subtitle, logotype } = programData;

	const baseLink = 'name' in programData ? programData.name : organizationName;
	const targetPath = `/${organizationName}/${programName}`;
	const isCurrentPath = location.pathname === targetPath;
	return (
		<div>
			<div className="flex justify-content-between landing-page-container py-3">
				<img
					src={logotype || '#'}
					alt="logo"
					onClick={() => navigate(`/${organizationName}/${programName}`)}
					className={classNames('max-h-3rem', { 'cursor-pointer': !isCurrentPath })}
				/>

				<div className="hidden sm:flex flex-column text-right">
					<h3 className="mt-0 mb-1">{displayName}</h3>
					<small>{subtitle}</small>
				</div>
			</div>

			<div style={{ borderBottom: border(colors.lightGray), backgroundColor: colors.violet }} className="z-1">
				<div className="flex justify-content-between landing-page-container py-0">
					<div className="hidden md:flex align-items-center column-gap-4">
						<NavLink
							to={`/${baseLink}`}
							className={({ isActive }) =>
								classNames('text-white font-bold text-sm p-3', {
									'bg-dark-purple-blue': isActive,
								})
							}
							end
						>
							{fixedT(programData.language, 'misc.aboutProgram')}
						</NavLink>
						{formsAvailability?.isMentorRecruitmentAvailable && (
							<>
								{!currentUser && <Tooltip target=".link" position="bottom" />}
								<span
									className="hidden md:inline-flex link"
									data-pr-tooltip={fixedT(programData.language, 'landingPages.linkTooltip')}
									id="text-white font-bold text-sm p-3"
								>
									<NavLink
										to={`/${baseLink}/apply-mentor`}
										className={({ isActive }) =>
											classNames('text-white font-bold text-sm p-3', {
												'bg-dark-purple-blue': isActive,
											})
										}
									>
										{fixedT(programData.language, 'landingPages.buttons.mentorApplication')}
									</NavLink>
								</span>
							</>
						)}
						{formsAvailability?.isMenteeRecruitmentAvailable && (
							<>
								{!currentUser && <Tooltip target=".link" position="bottom" />}
								<span
									className="hidden md:inline-flex link"
									data-pr-tooltip={fixedT(programData.language, 'landingPages.linkTooltip')}
									id="text-white font-bold text-sm p-3"
								>
									<NavLink
										to={`/${baseLink}/apply-mentee`}
										className={({ isActive }) =>
											classNames('text-white font-bold text-sm p-3', {
												'bg-dark-purple-blue': isActive,
											})
										}
									>
										{fixedT(programData.language, 'landingPages.buttons.menteeApplication')}
									</NavLink>
								</span>
							</>
						)}
						{currentUser && hasAcceptedApplication && (
							<span
								className="hidden md:inline-flex link"
								data-pr-tooltip={fixedT(programData.language, 'landingPages.linkTooltip')}
								id="text-white font-bold text-sm p-3"
							>
								<NavLink
									to={`/panel/${programData.name}`}
									className={classNames('text-white font-bold text-sm p-3')}
								>
									{fixedT(programData.language, 'landingPages.buttons.userArea')}
								</NavLink>
							</span>
						)}
					</div>

					<div className="flex align-items-center column-gap-4 py-2 ">
						<WelcomeMessage pageDetails={programData} />

						{currentUser && (
							<div className="hidden md:inline-flex gap-2">
								<ChatButton landingMode />
								<UserSettings
									currentUser={currentUser}
									setShowOrgDialog={setShowOrgDialog}
									source="landingPage"
									optionsLanguage={programData.language}
									hasApplicationSubmitted={!!applicationsData?.length}
								/>
							</div>
						)}
					</div>

					{currentUser && (
						<div className="flex flex-row flex-nowrap align-items-center column-gap-4 py-2 md:hidden gap-2">
							<ChatButton landingMode />
							<MobileUserSettings
								currentUser={currentUser}
								setShowOrgDialog={setShowOrgDialog}
								source="landingPage"
								optionsLanguage={programData.language}
								hasApplicationSubmitted={!!applicationsData?.length}
								formsAvailability={formsAvailability}
							/>
						</div>
					)}
					{!currentUser && (
						<div className="flex flex-row flex-nowrap align-items-center column-gap-4 py-2 md:hidden gap-2">
							<GuestMenu programData={programData} formsAvailability={formsAvailability} />
						</div>
					)}
				</div>
			</div>

			<Dialog title="Zmiana organizacji" size="sm" onHide={() => setShowOrgDialog(false)} visible={showOrgDialog}>
				<ChangeOrganization card onCancel={() => setShowOrgDialog(false)} />
			</Dialog>
		</div>
	);
};

export default Topbar;
