import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown, DropdownItem } from 'components/_new/Dropdown';
import { classNames } from 'primereact/utils';
import { AdminQuestion, QuestionTypes } from 'types/Question';
import { MultipleChoiceEditWrapper } from './partials/MultipleChoiceEditWrapper';
import { SingleChoiceEditWrapper } from './partials/SingleChoiceEditWrapper';
import { SectionedMultipleChoiceEditWrapper } from './partials/SectionedMultipleChoiceEditWrapper';
import { InputTextEditWrapper } from './partials/InputTextEditWrapper';

const questionTypes = [
	{ label: 'Short Text', value: QuestionTypes.shortText },
	{ label: 'Long Text', value: QuestionTypes.longText },
	{ label: 'Link', value: QuestionTypes.link },
	{ label: 'Single Choice', value: QuestionTypes.singleChoice },
	{ label: 'Multiple Choice', value: QuestionTypes.multipleChoice },
	{ label: 'Multiple Choice Groups', value: QuestionTypes.multipleChoiceGroups },
	{ label: 'Attachment', value: QuestionTypes.attachment },
	{ label: 'Checkbox', value: QuestionTypes.checkbox },
	{ label: 'Info Header', value: QuestionTypes.infoHeader },
	{ label: 'Info Text', value: QuestionTypes.infoText },
];

interface AddNewQuestionFormProps {
	onAddNewSubmit: (data: AdminQuestion) => void;
	onCancel: () => void;
	filteredSystemQuestionTypes: DropdownItem[];
}

const AddNewQuestionForm: React.FC<AddNewQuestionFormProps> = ({
	onAddNewSubmit,
	onCancel,
	filteredSystemQuestionTypes,
}) => {
	const { t } = useTranslation();
	const newElementRef = useRef<HTMLDivElement>(null);
	const applicationRoles = [
		{ label: t('programPanel.editQuestion.peer'), value: 'peer' },
		{ label: t('programPanel.editQuestion.mentor'), value: 'mentor' },
		{ label: t('programPanel.editQuestion.mentee'), value: 'mentee' },
	];

	const { control, reset, watch, setValue } = useForm<AdminQuestion>({
		defaultValues: {
			questionType: QuestionTypes.shortText,
			text: '',
			description: '',
			required: false,
			hiddenFromUsers: false,
			applicationRole: 'peer',
			choiceList: [],
			min: 0,
			max: 0,
		},
	});

	const watchedQuestionType = watch('questionType', QuestionTypes.shortText);
	const watchedApplicationRole = watch('applicationRole', 'peer');
	const watchedSystemQuestionType = watch('systemQuestionType', 'limit');

	useEffect(() => {
		if (newElementRef.current) {
			newElementRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	useEffect(() => {
		reset({
			...watch(),
			questionType: watchedQuestionType,
		});
	}, [watchedQuestionType, reset, watch]);

	const handleSave = (data: Partial<AdminQuestion>) => {
		const updatedData = {
			...data,
			questionType: watchedQuestionType,
			applicationRole: watchedApplicationRole,
			systemQuestionType: watchedSystemQuestionType,
		};
		onAddNewSubmit(updatedData as AdminQuestion);
	};

	const renderFormFields = (type: QuestionTypes) => {
		switch (type) {
			case QuestionTypes.checkbox:
			case QuestionTypes.infoText:
			case QuestionTypes.infoHeader:
				return (
					<InputTextEditWrapper
						initialText=""
						initialLabel=""
						required={false}
						hiddenFromUsers={false}
						initialLinks={[]}
						questionType={type}
						onSave={(text, description, links, required, hiddenFromUsers) => {
							handleSave({
								text,
								description,
								links,
								required,
								hiddenFromUsers,
								questionType: type,
							});
						}}
						withLinks
						onCancel={onCancel}
					/>
				);
			case QuestionTypes.shortText:
			case QuestionTypes.link:
			case QuestionTypes.longText:
			case QuestionTypes.attachment:
				return (
					<InputTextEditWrapper
						initialText=""
						initialLabel=""
						required={false}
						hiddenFromUsers={false}
						questionType={type}
						onSave={(text, description, links, required, hiddenFromUsers) => {
							handleSave({
								text,
								description,
								links,
								required,
								hiddenFromUsers,
								questionType: type,
							});
						}}
						onCancel={onCancel}
						initialLinks={[]}
						withLinks={false}
					/>
				);

			case QuestionTypes.singleChoice:
				return (
					<SingleChoiceEditWrapper
						initialItems={[]}
						initialLabel=""
						initialText=""
						required={false}
						hiddenFromUsers={false}
						onSave={(choices, label, text, required, hiddenFromUsers) => {
							handleSave({
								choiceList: choices,
								text,
								description: label,
								required,
								hiddenFromUsers,
								questionType: type,
							});
						}}
						onCancel={onCancel}
					/>
				);
			case QuestionTypes.multipleChoice:
				return (
					<MultipleChoiceEditWrapper
						initialItems={[]}
						initialLabel=""
						initialText=""
						required={false}
						hiddenFromUsers={false}
						minAnswers={0}
						maxAnswers={10}
						onSave={(choices, label, text, required, hiddenFromUsers, min, max) => {
							handleSave({
								questionType: type,
								choiceList: choices,
								text,
								description: label,
								required,
								hiddenFromUsers,
								min,
								max,
							});
						}}
						onCancel={onCancel}
					/>
				);
			case QuestionTypes.multipleChoiceGroups:
				return (
					<SectionedMultipleChoiceEditWrapper
						initialItems={[]}
						initialLabel=""
						initialText=""
						required={false}
						hiddenFromUsers={false}
						onSave={(choices, label, text, required, hiddenFromUser, min, max) => {
							handleSave({
								questionType: type,
								choiceList: choices,
								text,
								description: label,
								required,
								hiddenFromUsers: hiddenFromUser,
								min,
								max,
							});
						}}
						maxAnswers={10}
						minAnswers={1}
						onCancel={onCancel}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div
			className={classNames(
				'p-module-item-container',
				'flex flex-column b-bottom pl-4 pr-4 pt-2 pb-4 mb-4 border-round-lg'
			)}
		>
			<div ref={newElementRef}>
				<div className="pb-2 mb-2 mt-3" style={{ borderBottom: '1px solid lightGray' }}>
					<span className="font-bold text-base">{t('programPanel.editQuestion.newQuestion')}</span>
				</div>
				<div className="field">
					<Controller
						control={control}
						name="questionType"
						rules={{ required: true }}
						render={({ field }) => (
							<>
								<label className="ml-2 field-label" htmlFor="questionType">
									{t('programPanel.editQuestion.questionType')}
								</label>
								<Dropdown
									id="questionType"
									value={field.value}
									onChange={(e) => field.onChange(e.value)}
									options={questionTypes}
									placeholder={t('programPanel.editContract.chooseQuestionType')}
								/>
							</>
						)}
					/>
				</div>
				<div className="field">
					<Controller
						control={control}
						name="applicationRole"
						rules={{ required: true }}
						render={({ field }) => (
							<>
								<label className="ml-2 field-label" htmlFor="applicationRole">
									{t('programPanel.editQuestion.applicationRole')}
								</label>
								<Dropdown
									id="applicationRole"
									value={field.value}
									onChange={(e) => field.onChange(e.value)}
									options={applicationRoles}
									placeholder={t('programPanel.editContract.chooseApplicationRole')}
								/>
							</>
						)}
					/>
				</div>
				{filteredSystemQuestionTypes.length > 0 &&
					![QuestionTypes.infoHeader, QuestionTypes.infoText].includes(watchedQuestionType) && (
						<div className="field">
							<Controller
								control={control}
								name="systemQuestionType"
								rules={{ required: true }}
								render={({ field }) => (
									<>
										<label className="ml-2 field-label" htmlFor="applicationRole">
											{t('programPanel.editQuestion.systemQuestionType')}
										</label>
										<Dropdown
											id="systemQuestionType"
											value={field.value}
											onChange={(e) => field.onChange(e.value)}
											options={filteredSystemQuestionTypes}
											placeholder={t('programPanel.editQuestion.systemQuestionTypePlaceholder')}
										/>
									</>
								)}
							/>
						</div>
					)}
				{renderFormFields(watchedQuestionType)}
			</div>
		</div>
	);
};

export default AddNewQuestionForm;
