import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { usersApi } from 'api';
import { useAuth } from 'contexts/authContext';
import { Memberships } from 'types/Memberships';
import { Dropdown } from 'components/_common/forms/Dropdown';
import { Spinner } from 'components/_new/Spinner';
import { Button } from 'primereact/button';
import { Divider } from '../../_new/Divider';
import { Link } from '../../_new/Link';

type ChangeOrganizationProps = {
	card?: boolean;
	onCancel?: () => void;
};

const ChangeOrganization: FC<ChangeOrganizationProps> = ({ card, onCancel }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { organizationName, programName } = useParams();
	const { logout } = useAuth();

	const [selectedPath, setSelectedPath] = useState({ organization: organizationName, program: programName });
	const [userMemberships, setUserMemberships] = useState<Memberships[]>([]);
	const [isProgram, setIsProgram] = useState(!!programName);
	const [isLoading, setIsLoading] = useState(true);
	const [hasAdminAccess, setHasAdminAccess] = useState(false);

	const cutProgramStrings = (memberships: Memberships[]) =>
		memberships.map((membership) => {
			const cutPrograms = membership.programs?.map((program) => ({
				...program,
				name: program.name.split('/')[1],
			}));
			return { ...membership, programs: cutPrograms };
		});

	const { refetch: getMemberships } = useQuery(['auth', 'memberships'], usersApi.getMemberships, {
		onSuccess: (data) => {
			const cutData = cutProgramStrings(data);
			const filteredData = cutData.filter((membership) => membership.programs && membership.programs.length > 0);
			const hasAdmin = data.some((membership) => membership.role === 'organization admin');
			setHasAdminAccess(hasAdmin);
			setIsLoading(false);
			setUserMemberships(filteredData);
		},
	});

	useEffect(() => {
		getMemberships();
	}, [getMemberships]);

	const findOrg = (value: string | undefined) =>
		userMemberships?.find((membership) => membership.organization.name === value);

	const changeOrg = (value: string) => {
		setIsProgram(true);
		const selectedOrg = findOrg(value);

		setSelectedPath({
			organization: value,
			program: selectedOrg?.programs.length ? selectedOrg.programs[0].name : '',
		});
	};

	const getPrograms = () => {
		const selectedOrg = findOrg(selectedPath.organization);
		return selectedOrg?.programs?.map(({ name, displayName }) => ({
			label: displayName,
			value: name,
		}));
	};

	if (isLoading) return <Spinner center />;

	if (userMemberships.length === 0) {
		return (
			<div className="flex flex-column align-items-center">
				<p>{t('organizationPanel.noAdminAccess')}</p>
				<Button className="mt-3" onClick={() => navigate('/')}>
					{t('navigation.returnToHomepPage')}
				</Button>
			</div>
		);
	}
	return (
		<div className="flex flex-column w-full">
			<div>
				<span>{t('landingPages.buttons.chooseOrganization')}</span>
			</div>

			<Dropdown
				value={selectedPath.organization}
				options={
					userMemberships &&
					userMemberships.map(({ organization }) => ({
						label: organization.displayName,
						value: organization.name,
					}))
				}
				placeholder={t('navigation.chooseOrganization')}
				onChange={({ value }) => changeOrg(value)}
				className="text-left mt-4"
				rounded
			/>

			{isProgram && (
				<Dropdown
					value={selectedPath.program}
					options={getPrograms()}
					placeholder={t('navigation.chooseProgram')}
					onChange={({ value }) => setSelectedPath({ ...selectedPath, program: value })}
					className="text-left mt-3"
					rounded
				/>
			)}

			<Button
				className="flex justify-content-center mt-5 mb-3"
				onClick={() => {
					navigate({
						pathname: `/${selectedPath.organization}/${selectedPath.program}`,
					});
				}}
				disabled={
					!selectedPath.organization ||
					(isProgram &&
						selectedPath.program === programName &&
						selectedPath.organization === organizationName)
				}
			>
				{t('misc.choose')}
			</Button>

			{!card && (
				<>
					<div className="flex justify-content-center">
						<span>{t('misc.or')}</span>
					</div>
					<Button
						className="flex justify-content-center my-3"
						onClick={() => {
							logout();
						}}
					>
						{t('landingPages.buttons.logout')}
					</Button>
				</>
			)}

			{card && onCancel && (
				<>
					<div className="flex justify-content-center">
						<span>{t('misc.or')}</span>
					</div>
					<Button className="flex justify-content-center my-3" onClick={onCancel}>
						{t('actions.cancel')}
					</Button>
				</>
			)}

			{hasAdminAccess && (
				<div>
					<Divider />
					<Link className="p-link flex justify-content-center mt-3" to="/organization/list">
						{t('organizationPanel.adminPanel')}
					</Link>
				</div>
			)}
		</div>
	);
};

export default ChangeOrganization;
