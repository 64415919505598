/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { useController, FieldValues, UseControllerProps } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { GroupedChoiceList } from 'types/Question';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { matchOptionsByGroup } from './helpers';
import CustomLabel from './Label';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
	choiceList: GroupedChoiceList[];
	description: string;
	label?: string;
	underLabel?: string;
	required?: boolean;
	minAnswers: number;
	maxAnswers: number;
	links?: string[] | null;
	onChangeValue?: (values: any) => void;
}
const ValidateMultipleChoiceGroup = <T extends FieldValues>({
	name,
	description,
	label,
	underLabel,
	choiceList,
	control,
	required = false,
	rules,
	minAnswers,
	maxAnswers,
	links,
	onChangeValue,
}: Props<T>) => {
	const defaultGroups = choiceList.map((group) => ({
		title: group.title,
		options: [],
	}));

	const [values, setValues] = useState<GroupedChoiceList[]>(defaultGroups);

	const { t } = useTranslation();

	const getNumberOfOptions = (answers: GroupedChoiceList[]) =>
		Array.isArray(answers) ? answers.reduce((sum, answer) => sum + (answer.options || []).length || 0, 0) : 0;

	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: {
			required: required ? `${label} ${t('misc.forms.isRequired')}` : false,
			...rules,
			validate: (answers: GroupedChoiceList[]) => {
				const numberOfAnswers = answers && getNumberOfOptions(answers);
				return (
					(numberOfAnswers >= minAnswers && numberOfAnswers <= maxAnswers) ||
					t('misc.forms.atLeastTwoMaxFive', {
						min: minAnswers,
						max: maxAnswers,
					})
				);
			},
		},
	});

	useEffect(() => {
		// SAFETY MECHANISM - for checking matching options in case group titles/option assignment has changed
		if (field.value) {
			// Map groups and reduce through all values given by form controller, and then return an array of options by matched group
			const optionsAssignedByGroup = matchOptionsByGroup(choiceList, field.value);

			setValues(optionsAssignedByGroup);

			// check for a rare case when a person would have previously completed the survey and the options have changed
			// without it form values won't refresh if user won't interact with the question, and we would send the same values as previously, even if they don't exist anymore
			if (getNumberOfOptions(optionsAssignedByGroup) !== getNumberOfOptions(field.value)) {
				field.onChange(optionsAssignedByGroup);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- exhaustive deps demand including field as dependency, with it we get infinite rerender
	}, [field.value, choiceList]);

	const onValueChange = (value: string, checked: boolean, groupTitle: string) => {
		const selectedValues = [...values];
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const selectedValueGroup = selectedValues.find((group) => group.title === groupTitle)!.options;

		if (checked) {
			selectedValueGroup.push(value);
		} else {
			selectedValueGroup.forEach((item, index) => {
				if (item === value) {
					selectedValueGroup.splice(index, 1);
				}
			});
		}

		if (onChangeValue) {
			onChangeValue(selectedValues);
		}
		field.onChange(selectedValues);
	};
	return (
		<div>
			<CustomLabel name={name} required={required}>
				<Trans
					components={
						links
							? // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
							  links.map((link) => <a href={link} target="_blank" rel="noreferrer" className="p-link" />)
							: undefined
					}
				>
					{label}
				</Trans>
			</CustomLabel>
			{underLabel && <p className="mt-0 mb-2 px-3 text-sm text-muted">{underLabel}</p>}
			<div className="pl-3">
				<div className="mb-2">
					<Trans
						components={
							links
								? links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{description}
					</Trans>
				</div>
				{(choiceList || []).map((group, groupIndex) => (
					<div key={group.title}>
						<div className="font-medium mb-2">{group.title}</div>

						{group.options.map((item, optionIndex) => (
							<div key={item} className="field-checkbox">
								<Checkbox
									inputRef={groupIndex === 0 && optionIndex === 0 ? field.ref : undefined}
									inputId={item}
									onChange={({ value, checked }) => onValueChange(value, checked, group.title)}
									value={item}
									checked={values.reduce((prev, curr) => {
										if (curr.options.find((value) => value === item)) {
											return true;
										}
										return prev;
									}, false)}
									className={classNames({ 'p-invalid': !!error })}
								/>

								<label htmlFor={item} className={classNames({ 'p-error': !!error })}>
									{item}
								</label>
							</div>
						))}
					</div>
				))}
			</div>

			<div ref={field.ref} tabIndex={0}>
				{error && <small className="p-error font-bold ml-3">{error.message}</small>}
			</div>
		</div>
	);
};

export default ValidateMultipleChoiceGroup;
