/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import banner from 'assets/mentiway-banner.svg';
import { colors } from 'theme';
import { resolveSiteUrl } from 'utils/urlBuilder';
import { translatedStaticResourceResolver } from 'utils/translatedStaticResourceResolver';

const Footer = () => (
	<div className="mt-auto mb-0">
		<div className="flex justify-content-end column-gap-4 text-xs landing-page-container py-3 ">
			<a
				href={resolveSiteUrl('')}
				target="_blank"
				className="hidden sm:flex align-items-center no-underline"
				style={{ color: '#687178' }}
			>
				mentiway.com
			</a>
			{/* TODO files + translations after backend sends program language info */}
			<div className="flex align-items-center">
				<a
					href={translatedStaticResourceResolver('privacy')}
					target="_blank"
					rel="noreferrer"
					style={{ color: '#687178' }}
				>
					Polityka prywatności
				</a>
			</div>
			<div className="flex align-items-center">
				<a
					href={translatedStaticResourceResolver('terms')}
					target="_blank"
					rel="noreferrer"
					style={{ color: '#687178' }}
				>
					Regulamin serwisu
				</a>
			</div>
			<div className="flex align-items-center relative">
				<p
					style={{
						color: '#687178',
						position: 'absolute',
						marginTop: '-25px',
						marginLeft: '25px',
						fontSize: '8px',
					}}
				>
					powered by
				</p>
				<a href="https://mentiway.com/" target="_blank">
					<img src={banner} alt="banner" className="w-8rem" />
				</a>
			</div>
		</div>

		<div
			className="h-1rem"
			style={{
				background: `linear-gradient(90deg, ${colors.blue} 0%, ${colors.purple} 100%)`,
			}}
		/>
	</div>
);

export default Footer;
