import React, { useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v4 as UUIDv4 } from 'uuid';
import { postsApi, programsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Button } from 'components/_new/Button';
import ValidateTextField from 'components/_common/forms/TextField';
import ValidateDropdown from 'components/_common/forms/ValidateDropdown';
import ValidateCheckbox from 'components/_common/forms/Checkbox';
import NotificationPost from 'components/wall/NotificationPost';
import { Format, PostAttachment, PostAttachmentFileType, PostAttachmentLocal, UserData } from 'types/Dashboard';
import { MarkdownEditor } from 'components/_common/MarkdownEditor/MarkdownEditor';
import { useAuth } from 'contexts/authContext';
import { Dialog, DialogConfirm } from 'components/_new/Dialog';
import { Application } from 'types/Application';
import { userDisplayName } from 'utils/userDisplayName';
import { User } from 'types/User';
import { Avatar } from 'components/_new/Avatar';
import CustomLabel from 'components/_common/forms/Label';
import { Box, BoxSection } from 'components/_new/Box';
import { MultiSelect } from 'components/_new/MultiSelect';
import { AttachmentList } from 'components/_new/Attachment';
import { useGlobal } from 'contexts/globalContext';
import { readPostAttachmentTypeFromFile } from 'utils/postAttachment';

type ApplicationOption = {
	user: User;
	label: string;
	value: number;
};

type AddPostForm = {
	subject: string;
	content: string;
	recipients: string;
	applications: number[];
	withEmail: boolean;
};

export const ProgramPostAdd = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { current: postLocalUuid } = useRef<string>(UUIDv4());
	const { currentUser } = useAuth();
	const { toastRef } = useGlobal();
	const { data: program, programName, isSuccess } = useProgramPanel();

	const { control, handleSubmit, getValues, watch, setValue } = useForm<AddPostForm>({
		defaultValues: {
			withEmail: true,
		},
	});

	const { mutate: addPostMutate, isLoading: addPostLoading } = useMutation(postsApi.addProgramPostAdmin, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('programPanel.post.postHasBeenAdded'),
			});
			navigate(`/program/${program?.name}/communication/list`);
		},
	});
	const handleFormSubmit = handleSubmit((values) => {
		const { withEmail, ...data } = values;
		addPostMutate({ localUuid: postLocalUuid, programId: program?.id, withEmail: Boolean(withEmail), ...data });
	});

	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const handlePreviewClick = useCallback(() => {
		// const values = getValues();
		setPreviewModalOpen(true);
	}, []);

	const subject = watch('subject');
	const content = watch('content');
	const recipients = watch('recipients');

	const hasSubject = subject && subject.length > 0;
	const hasContent = content && content.length > 0;

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	// get applications for options
	const { data: applicationMenteesData, isLoading: applicationMenteesLoading } = useQuery(
		['getProgramApplications', { programName, applicationRole: 'mentee', acceptedOnly: true }],
		() =>
			programsApi.getProgramAdminApplicationsAll({
				programId: Number(program?.id),
				applicationRole: 'mentee',
			}),
		{
			enabled: isSuccess && recipients === 'selected',
		}
	);

	const { data: applicationMentorsData, isLoading: applicationMentorsLoading } = useQuery(
		['getProgramApplications', { programName, applicationRole: 'mentor', acceptedOnly: true }],
		() =>
			programsApi.getProgramAdminApplicationsAll({
				programId: Number(program?.id),
				applicationRole: 'mentor',
			}),
		{
			enabled: isSuccess && recipients === 'selected',
		}
	);
	const applicationDataLoading = applicationMenteesLoading || applicationMentorsLoading;
	const applicationsMerged = [...(applicationMenteesData || []), ...(applicationMentorsData || [])].filter(
		(application) => application.approved === 'accepted'
	);

	const mapApplicationOption = ({ id, programMembership: { user } }: Application): ApplicationOption => ({
		user,
		label: userDisplayName(user),
		value: id,
	});
	const applicationOptionTemplate = (option: ApplicationOption) => (
		<div className="inline-flex flex-row gap-2 align-items-center mr-2">
			<Avatar src={option.user.avatar} size="sm" name={userDisplayName(option.user, null)} />
			<span>{option.label}</span>
		</div>
	);
	const applicationSelectedOptionTemplate = (value: number) => {
		const application = applicationsMerged.find(({ id }) => id === value);
		if (!application) {
			return null;
		}
		const option = mapApplicationOption(application);
		return applicationOptionTemplate(option);
	};
	const applicationOptions = [
		{
			label: t('misc.mentees'),
			items: (applicationMenteesData || [])
				.filter((application) => application.approved === 'accepted')
				.sort((a, b) =>
					String(a.programMembership.user.lastName || '').localeCompare(
						String(b.programMembership.user.lastName || ''),
						'en',
						{ sensitivity: 'base' }
					)
				)
				.map(mapApplicationOption),
		},
		{
			label: t('misc.mentors'),
			items: (applicationMentorsData || [])
				.filter((application) => application.approved === 'accepted')
				.sort((a, b) =>
					String(a.programMembership.user.lastName || '').localeCompare(
						String(b.programMembership.user.lastName || ''),
						'en',
						{ sensitivity: 'base' }
					)
				)
				.map(mapApplicationOption),
		},
	];

	// upload post section
	const { mutateAsync: addPostAttachmentMutateAsync, isLoading: addPostAttachmentLoading } = useMutation(
		(data: FormData) => postsApi.addProgramPostAttachmentLocalAdmin({ postLocalUuid, data }),
		{
			onSuccess: () => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('programPanel.post.attachmentUploadSuccessfully'),
				});
			},
		}
	);

	const { mutateAsync: removePostAttachmentMutateAsync } = useMutation(postsApi.removeProgramPostAttachmentAdmin, {
		onSuccess: () => {
			toastRef?.current?.show({
				severity: 'success',
				life: 3000,
				summary: t('misc.success'),
				detail: t('programPanel.post.attachmentRemoveSuccessfully'),
			});
		},
	});

	const [newAttachments, setNewAttachments] = useState<PostAttachment[]>([]);
	const [uploadedAttachments, setUploadedAttachments] = useState<PostAttachmentLocal[]>([]);
	const handleAttachmentUpload = useCallback(
		(file: File) => {
			const formData = new FormData();
			formData.append('file', file);

			const localUuid = UUIDv4();
			const { name: originalFileName, size: originalFileSize, type } = file;
			const fileType = readPostAttachmentTypeFromFile(type);

			const maxFileSize = 1024 * 1024 * 3;
			if (originalFileSize > maxFileSize) {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: 'error',
					detail: t('misc.fileSizeExceeds', { count: 3 }),
				});
				return;
			}
			if (fileType === PostAttachmentFileType.UNKNOWN) {
				toastRef?.current?.show({
					severity: 'error',
					life: 3000,
					summary: 'error',
					detail: t('misc.fileInvalidFileType'),
				});
				return;
			}

			const postAttachmentLocal: PostAttachmentLocal = {
				localUuid,
				postUuidLink: postLocalUuid,
				originalFileName,
				originalFileSize,
				fileType,
				createdAt: new Date(),
			};
			setUploadedAttachments((prev) => [...prev, postAttachmentLocal]);
			addPostAttachmentMutateAsync(formData)
				.then((result) => {
					setUploadedAttachments((prev) => prev.filter((attachment) => attachment.localUuid !== localUuid));
					setNewAttachments((prev) => [...prev, result]);
					return result;
				})
				.catch(() => {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.error'),
						detail: t('misc.fileUnexpectedError'),
					});
				})
				.finally(() => {
					setTimeout(() => {
						setUploadedAttachments((prev) =>
							prev.filter((attachment) => attachment.localUuid !== localUuid)
						);
					}, 1000);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[postLocalUuid]
	);

	const handleAttachmentRemove = useCallback(
		(attachment: PostAttachment) =>
			removePostAttachmentMutateAsync(attachment.id)
				.then((result) => {
					setNewAttachments((prev) => prev.filter((newAttachment) => newAttachment.id !== attachment.id));
					return result;
				})
				.catch(() => {
					toastRef?.current?.show({
						severity: 'error',
						life: 3000,
						summary: t('misc.success'),
						detail: t('misc.fileUnexpectedError'),
					});
				}),
		[]
	);

	return (
		<>
			<Helmet title={t('organizationPanel.programCommunicationAddPost')} />

			<DialogConfirm
				visible={confirmDialogOpen}
				onHide={() => setConfirmDialogOpen(false)}
				title={t('programPanel.post.addConfirm')}
				confirmAction={{ submit: true, form: 'add-post-form' }}
			/>
			<Dialog
				title={t('programPanel.post.postPreview')}
				visible={previewModalOpen}
				onHide={() => setPreviewModalOpen(false)}
				size="lg"
			>
				{program && (
					<NotificationPost
						postData={{
							id: 0,
							language: 'pl',
							format: Format.HTML,
							menteeAccess: true,
							mentorAccess: true,
							postedBy: 'user',
							programId: program?.id,
							text: getValues('content'),
							title: getValues('subject'),
							user: currentUser as UserData,
							userId: (currentUser as UserData)?.id,
							updatedAt: new Date(),
							programType: 'test',
							applications: [],
							attachments: newAttachments,
						}}
					/>
				)}
			</Dialog>

			<SubPageTitle
				title={t('organizationPanel.programCommunicationAddPost')}
				additional={program?.displayName}
				right={
					<Link
						to={`/program/${program?.name}/communication/list`}
						className="p-button p-button-text p-button-sm p-0"
					>
						{t('programPanel.backToList')}
					</Link>
				}
			/>
			<form id="add-post-form" onSubmit={handleFormSubmit}>
				<Box variant="white-bordered" className="gap-4">
					<BoxSection header={t('programPanel.post.contentSectionTitle')}>
						<ValidateTextField
							label={t('programPanel.post.subject')}
							name="subject"
							control={control}
							required
						/>
						<MarkdownEditor
							onChange={(value) => {
								setValue('content', String(value));
							}}
						/>
						<AttachmentList
							newAttachments={newAttachments}
							uploadedAttachments={uploadedAttachments}
							onUpload={handleAttachmentUpload}
							onRemove={handleAttachmentRemove}
							isAdding={addPostAttachmentLoading}
							options={{
								addAttachmentLabel: t('programPanel.post.addAttachment'),
								removeAttachmentLabel: t('programPanel.post.addAttachment'),
								uploadDesc: t('programPanel.post.attachmentUploadDescription', { count: 3 }),
							}}
						/>
					</BoxSection>
					<BoxSection header={t('programPanel.post.settingsSectionTitle')}>
						<ValidateDropdown
							name="recipients"
							label={t('programPanel.recipients')}
							control={control}
							options={[
								{
									label: t('programPanel.recipientOptions.all'),
									value: 'all',
								},
								{
									label: t('programPanel.recipientOptions.mentorsOnly'),
									value: 'mentors',
								},
								{
									label: t('programPanel.recipientOptions.menteesOnly'),
									value: 'mentees',
								},
								{
									label: t('programPanel.recipientOptions.selected'),
									value: 'selected',
								},
							]}
							required
						/>

						{recipients === 'selected' && (
							<Controller
								control={control}
								name="applications"
								rules={{ required: t('programPanel.selectedPersons.required') }}
								render={({ field, fieldState: { error } }) => (
									<div>
										<CustomLabel
											name="applications"
											label={t('programPanel.selectedPersons.label')}
											required
										/>
										<MultiSelect
											{...field}
											options={applicationOptions}
											placeholder={t('programPanel.recipientOptions.selected')}
											className="w-full"
											itemTemplate={applicationOptionTemplate}
											selectedItemTemplate={applicationSelectedOptionTemplate}
											panelClassName="px-3"
											loading={applicationDataLoading}
											filter
											filterMatchMode="contains"
										/>
										{error && <small className="p-error font-bold ml-3">{error.message}</small>}
									</div>
								)}
							/>
						)}

						<ValidateCheckbox text={t('programPanel.post.sendEmail')} name="withEmail" control={control} />
					</BoxSection>
					<BoxSection horizontal contentClassName="justify-content-end">
						<Button
							onClick={handlePreviewClick}
							label={t('actions.preview')}
							variant="primary-outlined"
							loading={addPostLoading}
							disabled={!hasSubject || !hasContent}
						/>
						<Button
							onClick={() => setConfirmDialogOpen(true)}
							label={t('actions.add')}
							variant="primary"
							disabled={!hasSubject || !hasContent}
							loading={addPostLoading}
						/>
					</BoxSection>
				</Box>
			</form>
		</>
	);
};
