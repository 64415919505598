import React, { PropsWithRef, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ModuleType } from 'types/Contract';
import { parseHtml } from 'utils/htmlParser';
import { InputTextArea } from 'components/_new/InputTextArea';
import { ContractModule, ContractModuleCommonProps } from './ContractModule';

type EditTextModuleEditHandler = (module: ModuleType, hasChanges?: boolean) => boolean;
type EditTextModuleCancelHandler = (module: ModuleType) => void;
type EditTextModuleSaveHandler = (module: ModuleType, value: any) => Promise<boolean>;

type EditTextModuleProps = PropsWithRef<
	ContractModuleCommonProps & {
		module: ModuleType;
		onEdit?: EditTextModuleEditHandler;
		onCancel?: EditTextModuleCancelHandler;
		onSave?: EditTextModuleSaveHandler;
	}
>;

export const EditTextModule = ({
	onInit,
	module,
	onEdit,
	onCancel,
	onSave,
	loading,
	...contractModuleBoxProps
}: EditTextModuleProps) => {
	const value = typeof module.value === 'string' ? module.value : '';

	const { register, trigger, getValues, reset } = useForm({ defaultValues: { value } });

	const handleEdit = useCallback(() => {
		if (onEdit) {
			const success = onEdit(module);
			return success;
		}
		return false;
	}, [onEdit, module]);

	const handleCancel = useCallback(() => {
		reset({ value });
		if (onCancel) {
			onCancel(module);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, onCancel]);

	const handleSave = useCallback(async () => {
		const isValid = await trigger().then((isValid: boolean) => isValid);
		const value = getValues('value');
		if (isValid && onSave) {
			const success = await onSave(module, value);
			return success;
		}
		return isValid;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onSave]);

	const renderValue = useCallback(() => {
		if (value && String(value).length > 0) {
			return parseHtml(value);
		}
		return <span>-</span>;
	}, [value]);

	// pseudo-ref
	useEffect(() => {
		if (onInit) {
			onInit({
				save: handleSave,
			});
		}
	}, [handleSave, onInit]);

	return (
		<ContractModule
			module={module}
			editable
			onEdit={handleEdit}
			onCancel={handleCancel}
			onSave={handleSave}
			{...contractModuleBoxProps}
		>
			{({ editMode }) =>
				editMode ? (
					<div className="flex-1 flex flex-row gap-1">
						<InputTextArea
							disabled={loading === true}
							{...register('value', {
								onChange: () => {
									const currentValue = getValues('value');
									if (onEdit) {
										onEdit(module, currentValue !== value);
									}
								},
							})}
						/>
					</div>
				) : (
					<p>{renderValue()}</p>
				)
			}
		</ContractModule>
	);
};
