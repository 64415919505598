import React, { useRef } from 'react';
import { Control, Controller, UseFormSetError } from 'react-hook-form';
import { GroupedChoiceList, Question, QuestionTypes } from 'types/Question';
import { Answer } from 'types/Application';
import { ApplyMembershipFormObject } from 'components/landingPages/components/FormQuestions';
import TextField from 'components/_common/forms/TextField';
import TextArea from 'components/_common/forms/TextArea';
import Checkbox from 'components/_common/forms/Checkbox';
import SingleChoice from 'components/_common/forms/SingleChoice';
import MultipleChoiceGroup from 'components/_common/forms/MultipleChoiceGroup';
import MultipleChoice from 'components/_common/forms/MultipleChoice';
import { FileUpload } from 'primereact/fileupload';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { Button } from 'components/_new/Button';
import { useGlobal } from 'contexts/globalContext';
import { findParentByClass } from 'utils/parents';
import { findFocusableElements } from 'utils/findFocusableElements';

interface AnswerFieldWithId extends Answer {
	id: string;
}

interface FormQuestionProps {
	field: AnswerFieldWithId;
	index: number;
	questionsData: Question[];
	setIsError: React.Dispatch<React.SetStateAction<boolean>>;
	control: Control<ApplyMembershipFormObject>;
	setValue: any;
	getValues: any;
	setError: UseFormSetError<any>;
	onChangeValue?: (value: any) => void;
	fileActionLoading?: boolean;
	onFileUpload?: (data: { questionId: number; file: File }) => Promise<Answer>;
	onFileRemove?: (data: { questionId: number; index: number }) => Promise<boolean>;
}
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

const ALLOWED_FILE_TYPES = [
	'application/pdf',
	'video/mp4',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'image/png',
	'image/jpeg',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const FormQuestion = ({
	field,
	index,
	questionsData,
	setIsError,
	control,
	setValue,
	getValues,
	setError,
	onChangeValue,
	fileActionLoading,
	onFileUpload,
	onFileRemove,
}: FormQuestionProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();

	const fileUploadRef = useRef<FileUpload>(null);

	const question = questionsData.find((question) => question.id === field.questionId);

	const handleTextFieldChange = ({ target: { value } }: any) => {
		onChangeValue?.(value);
	};

	const handleSingleChoiceFieldChange = (value: any) => {
		onChangeValue?.(value);
	};

	const handlMultiChoiseFieldChange = (values: any) => {
		onChangeValue?.(values);
	};

	if (!question) {
		setIsError(true);
		return null;
	}

	switch (question.questionType) {
		case QuestionTypes.infoText:
			return (
				<div className="ml-3">
					<Trans
						components={
							question.links
								? question.links.map((link) => (
										// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
										<a href={link} target="_blank" rel="noreferrer" className="p-link" />
								  ))
								: undefined
						}
					>
						{question.text}
					</Trans>
				</div>
			);
		case QuestionTypes.infoHeader:
			return (
				<div className="ml-3 text-xl font-medium pb-0">
					{question.text}
					<div className="text-sm font-normal">{question?.description}</div>
				</div>
			);
		case QuestionTypes.shortText:
		case QuestionTypes.link:
			return (
				<TextField
					name={`form.${index}.value`}
					label={question.text}
					underLabel={
						question.hiddenFromUsers
							? t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')
							: undefined
					}
					placeholder={question.description}
					control={control}
					required={question.required}
					links={question.links}
					onChange={handleTextFieldChange}
				/>
			);
		case QuestionTypes.longText:
			return (
				<TextArea
					name={`form.${index}.value`}
					label={question.text}
					underLabel={
						question.hiddenFromUsers
							? t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')
							: undefined
					}
					placeholder={question.description}
					control={control}
					required={question.required}
					links={question.links}
					onChange={handleTextFieldChange}
				/>
			);
		case QuestionTypes.singleChoice:
			return (
				<SingleChoice
					name={`form.${index}.value`}
					choiceList={question.choiceList as string[]}
					label={question.text}
					underLabel={
						question.hiddenFromUsers
							? t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')
							: undefined
					}
					description={question.description}
					control={control}
					required={question.required}
					links={question.links}
					onChangeValue={handleSingleChoiceFieldChange}
				/>
			);
		case QuestionTypes.multipleChoice:
			return (
				<MultipleChoice
					name={`form.${index}.value`}
					choiceList={question.choiceList as string[]}
					label={question.text}
					underLabel={
						question.hiddenFromUsers
							? t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')
							: undefined
					}
					description={question.description}
					control={control}
					required={question.required}
					minAnswers={question.min || 1}
					maxAnswers={question.max || question.choiceList.length}
					links={question.links}
					onChangeValue={handlMultiChoiseFieldChange}
				/>
			);
		case QuestionTypes.multipleChoiceGroups:
			return (
				<MultipleChoiceGroup
					name={`form.${index}.value`}
					choiceList={question.choiceList as GroupedChoiceList[]}
					label={question.text}
					underLabel={
						question.hiddenFromUsers
							? t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')
							: undefined
					}
					description={question.description}
					control={control}
					minAnswers={question.min}
					maxAnswers={question.max}
					required={question.required}
					links={question.links}
					onChangeValue={handlMultiChoiseFieldChange}
				/>
			);
		case QuestionTypes.checkbox:
			return (
				<Checkbox
					name={`form.${index}.value`}
					text={question.description}
					underLabel={
						question.hiddenFromUsers
							? t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')
							: undefined
					}
					control={control}
					required={question.required}
					links={question.links}
				/>
			);
		case QuestionTypes.attachment:
			// eslint-disable-next-line no-case-declarations
			const url = getValues(`form.${index}.url`);
			return (
				<Controller
					name={`form.${index}.value`}
					control={control}
					rules={{
						validate: {
							customRequired: () => {
								if (question.required) {
									const currentFile = getValues(`form.${index}.file`);
									const currentValue = getValues(`form.${index}.value`);

									if (!currentFile && (!currentValue || !currentValue.length)) {
										return t('landingPages.fieldRequired');
									}
								}
								return true;
							},
						},
					}}
					render={({ field: { ref, value }, fieldState: { error } }) => (
						<div className="px-3">
							<label htmlFor={`form.${index}.file`} className={`${classNames({ 'p-error': !!error })}`}>
								{question.text}
								{question.required && <span className="text-red-500">*</span>}
							</label>
							{question.hiddenFromUsers ? (
								<p className="mt-0 mb-2 text-sm text-muted">
									{t('misc.forms.thisQuestionisIsVisibleOnlyForAdminProgram')}
								</p>
							) : null}
							<div className="pt-3">
								{value ? (
									<div className="flex gap-4 align-items-center justify-content-start">
										<span>{String(value)}</span>
										<Button
											onClick={() => {
												onFileRemove?.({
													index,
													questionId: question.id,
												});
											}}
											icon="times"
											iconSet="pi"
											className="p-button-danger p-button-xs"
											label={t('actions.remove')}
											loading={fileActionLoading}
										/>
										{error && <small className="p-error font-bold">{error.message}</small>}
									</div>
								) : (
									<>
										<input
											ref={ref}
											className="hidden-accessible"
											onFocus={() => {
												const inputEl = fileUploadRef?.current?.getInput();
												const fileUploadContainerEl = inputEl
													? findParentByClass(inputEl, 'p-fileupload')
													: null;
												if (fileUploadContainerEl) {
													const focusables = findFocusableElements(fileUploadContainerEl);
													focusables?.[0]?.focus();
												}
											}}
										/>
										<FileUpload
											disabled={fileActionLoading}
											mode="basic"
											ref={fileUploadRef}
											maxFileSize={MAX_FILE_SIZE}
											accept={ALLOWED_FILE_TYPES.join(',')}
											invalidFileSizeMessageSummary="Invalid file size"
											invalidFileSizeMessageDetail={t('misc.fileSizeExceeds', { count: 5 })}
											customUpload
											onSelect={({ originalEvent: event }) => {
												try {
													const selectedFile = (event.target as HTMLInputElement)?.files?.[0];
													if (((event?.target as any)?.files?.length || 0) > 0) {
														if (selectedFile) {
															const isTypeAllowed = ALLOWED_FILE_TYPES.includes(
																selectedFile.type
															);
															const isSizeAllowed = selectedFile.size <= MAX_FILE_SIZE; // 5MB in bytes

															if (!isTypeAllowed) {
																fileUploadRef?.current?.clear();
																setError(`form.${index}.value`, {
																	message: t('misc.fileInvalidFileType'),
																});
																return;
															}

															if (!isSizeAllowed) {
																fileUploadRef?.current?.clear();
																setError(`form.${index}.value`, {
																	message: t('misc.fileSizeExceeds', { count: 5 }),
																});
																return;
															}

															onFileUpload?.({
																questionId: question.id,
																file: selectedFile,
															})
																.then(({ value }) => {
																	setValue(`form.${index}.value`, value);
																})
																.catch((err) => {
																	fileUploadRef?.current?.clear();
																	setError(`form.${index}.value`, {
																		message: t('misc.fileUnexpectedError'),
																	});
																});
														}
													}
												} catch (error: any) {
													toastRef?.current?.show({
														severity: 'error',
														life: 3000,
														summary: t('auth.formError'),
														detail: `${error.message}`,
													});
												}
											}}
											chooseLabel={question.description}
										/>
										{error && <small className="p-error font-bold">{error.message}</small>}
									</>
								)}
							</div>
						</div>
					)}
				/>
			);
		default:
			// setIsError(true);
			return null;
	}
};

export default FormQuestion;
