import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'components/_new/Dropdown';
import type { DropdownItem } from 'components/_new/Dropdown';

const LANGUAGES = [
	{ value: 'pl', label: 'Polski', flag: '🇵🇱' },
	{ value: 'en', label: 'English', flag: '🇬🇧' },
] as const;

const LanguageOption = ({ value, label, flag }: typeof LANGUAGES[number]) => (
	<div className="flex align-items-center gap-2">
		<span className="text-lg">{flag}</span>
		<span>{label}</span>
	</div>
);

const LanguageSwitcher = () => {
	const { i18n, t } = useTranslation();

	const options: DropdownItem[] = LANGUAGES.map(({ value, label }) => ({
		value,
		label,
	}));

	const valueTemplate = (option: DropdownItem | null) => {
		if (!option) return null;
		const lang = LANGUAGES.find((l) => l.value === option.value);
		if (!lang) return null;

		return <LanguageOption {...lang} />;
	};

	return (
		<div className="flex align-items-center gap-2">
			<span className="text-gray-500">{t('programPanel.usersLanguage')}:</span>
			<Dropdown
				value={i18n.language}
				options={options}
				onChange={(e) => {
					i18n.changeLanguage(e.value);
					localStorage.setItem('language', e.value);
				}}
				className="w-16"
				valueTemplate={valueTemplate}
				itemTemplate={valueTemplate}
			/>
		</div>
	);
};

export default LanguageSwitcher;
