/* eslint-disable react/jsx-no-useless-fragment */
import React, { Ref, forwardRef, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { chatApi } from 'api/chat';
import { Dialog } from 'components/_new/Dialog';
import { Spinner } from 'components/_new/Spinner';
import { Avatar } from 'components/_new/Avatar';
import { userDisplayName } from 'utils/userDisplayName';
import { Button } from 'components/_new/Button';
import { classNames } from 'primereact/utils';
import { ChatMessage, ChatThread, ChatThreadWithMessage } from 'types/Chat';
import { useScope } from 'contexts/scopeContext';
import { useWindowSize } from '@uidotdev/usehooks';
import { useChat } from './useChat';
import { ChatConversation } from './ChatConversation';
import { useChatThreadSubscriber } from './useChatSubscrber';
import './chat.scss';

type ChatThreadListElement = {
	readThread: (programMembershipId: number) => void;
	updateThreadLastMessage: (message: ChatMessage) => void;
};

type ChatThreadListProps = {
	onThreadMissing?: (threadId: number) => void;
};

const ChatThreadList = forwardRef((props: ChatThreadListProps, ref: Ref<ChatThreadListElement>) => {
	const { onThreadMissing } = props;
	const { width: windowWidth } = useWindowSize();
	const isMediumWindowSize = windowWidth ? windowWidth > 786 : false;

	const { t } = useTranslation();

	const { currentProgramMembership } = useScope();
	const {
		openDialog,
		currentThread,
		queryUniqueParam,
		isInit,
		dialogProps: { visible },
	} = useChat();

	const [chatThreads, setChatThreads] = useState<ChatThreadWithMessage[]>([]);
	const { isLoading: chatThreadsLoading, isSuccess: chatThreadsSuccess } = useQuery(
		['chat-threads', queryUniqueParam],
		() => chatApi.getThreads(Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(currentProgramMembership?.id),
			onSuccess: (data) => {
				setChatThreads(data);
				if (
					currentThread &&
					!data
						.map(({ interlocutorProgramMembershipId }) => interlocutorProgramMembershipId)
						.includes(currentThread)
				) {
					onThreadMissing?.(currentThread);
				}
				// else if (data.length > 0 && !currentThread) {
				// 	openDialog(data[0].interlocutorProgramMembershipId);
				// }
			},
		}
	);

	const [startAction, setStartAction] = useState(false);
	useLayoutEffect(() => {
		if (
			visible &&
			chatThreads &&
			chatThreads.length > 0 &&
			!currentThread &&
			isMediumWindowSize &&
			isInit &&
			!startAction
		) {
			openDialog(chatThreads[0].interlocutorProgramMembershipId);
			setStartAction(true);
		}
	}, [visible, chatThreads, currentThread, isMediumWindowSize, openDialog, isInit, startAction]);

	const sortThreadsByLastMessage = ({ lastMessageAt: aDateAt }: ChatThread, { lastMessageAt: bDateAt }: ChatThread) =>
		new Date(aDateAt).getTime() <= new Date(bDateAt).getTime() ? 1 : -1;

	const handeUpdateThreadList = useCallback((data: ChatThreadWithMessage) => {
		setChatThreads((prev) => {
			// update exists thread
			if (prev.findIndex((thread) => thread.id === data.id) !== -1) {
				return prev
					.map((thread) => (thread.id === data.id ? { ...thread, ...data } : thread))
					.sort(sortThreadsByLastMessage);
			}
			// add new thread and sort it
			return [...prev, data].sort(sortThreadsByLastMessage);
		});
	}, []);

	// const handleUpdateThreadLastMessage = useCallback((data: ChatMessage) => {
	// 	setChatThreads((prev) =>
	// 		prev
	// 			.map((thread) => {
	// 				if (thread.id === data.threadId) {
	// 					return {
	// 						...thread,
	// 						lastMessage: data,
	// 						lastMessageAt: data.createdAt,
	// 					};
	// 				}
	// 				return thread;
	// 			})
	// 			.sort(sortThreadsByLastMessage)
	// 	);
	// }, []);

	// useEffect(() => {
	// 	const handleMessage = (rawEvent: MessageEvent<any>) => {
	// 		const { event, data } = parseMessage(rawEvent);
	// 		if (event === 'chat-thread' || event === 'chat-thread-self') {
	// 			handeUpdateThreadList(data);
	// 		}
	// 		if (event === 'chat-message') {
	// 			handleUpdateThreadLastMessage(data);
	// 		}
	// 	};
	// 	connection?.socket.addEventListener('message', handleMessage);
	// 	return () => {
	// 		connection?.socket.removeEventListener('message', handleMessage);
	// 	};
	// }, [connection?.socket]);

	const handeUpdateThreadListEvent = ({ detail }: CustomEvent<ChatThreadWithMessage>) =>
		handeUpdateThreadList(detail);
	useChatThreadSubscriber({ onChatThread: handeUpdateThreadListEvent, onChatThreadSelf: handeUpdateThreadListEvent });

	const renderThreads = () =>
		(chatThreads || []).map((thread) => {
			const {
				interlocutorProgramMembership,
				lastMessage: { authorProgramMembershipId, message, createdAt },
			} = thread;
			const isActive = currentThread === interlocutorProgramMembership.id;
			const lastMessageIsYou = Number(authorProgramMembershipId) === Number(currentProgramMembership?.id);
			return (
				<li key={thread.id}>
					<Button
						variant={isActive ? 'primary' : 'primary-outlined'}
						label={userDisplayName(interlocutorProgramMembership.user)}
						className="w-full py-1 px-2"
						onClick={() => {
							openDialog(interlocutorProgramMembership.id);
						}}
					>
						<div className="w-full flex flex-row gap-2 align-items-center">
							<Avatar
								src={interlocutorProgramMembership.user.avatar}
								size="lg"
								name={userDisplayName(interlocutorProgramMembership.user, null)}
							/>
							<div className="flex flex-column gap-0 text-left overflow-hidden">
								<div>{userDisplayName(interlocutorProgramMembership.user)}</div>
								<div
									className={classNames('text-overflow text-xs', {
										'font-bold': thread.hasNewMessage,
									})}
									style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
								>
									{`${lastMessageIsYou ? 'Ty: ' : ''}${message}`}
								</div>
								<div className="text-gray text-xxs">{dayjs(createdAt).fromNow()}</div>
							</div>
						</div>
						{/* <div className="w-full flex md:hidden flex-column text-left">
							<div className="text-xs">{userDisplayName(interlocutorProgramMembership.user)}</div>
							<div className="text-gray text-xxs">{dayjs(createdAt).fromNow()}</div>
						</div> */}
					</Button>
				</li>
			);
		});
	// eslint-disable-next-line react/jsx-fragments
	return (
		<>
			{chatThreadsLoading ? (
				<Spinner />
			) : (
				<>
					{chatThreadsSuccess && !chatThreads.length ? (
						<p className="w-full text-center text-gray">{t('chat.noConversations')}</p>
					) : (
						<ul className="flex flex-column gap-1 list-none">{renderThreads()}</ul>
					)}
				</>
			)}
		</>
	);
});

// eslint-disable-next-line @typescript-eslint/ban-types
type ChatMembersListProps = {};

const ChatMembersList = (props: ChatMembersListProps) => {
	const { t } = useTranslation();
	const { currentProgramMembership } = useScope();
	const { currentThread, openDialog, queryUniqueParam } = useChat();

	const {
		data: chatMembers,
		isLoading: chatMembersLoading,
		isSuccess: chatMembersSuccess,
	} = useQuery(
		['chat-unthread-members', queryUniqueParam],
		() => chatApi.getUnthreadMembers(Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(currentProgramMembership?.id),
		}
	);

	const renderMembers = () =>
		(chatMembers || []).map((programMembership) => {
			const isActive = currentThread === programMembership.id;
			return (
				<li key={programMembership.id}>
					<Button
						variant={isActive ? 'primary' : 'primary-outlined'}
						label={userDisplayName(programMembership.user)}
						className="w-full py-1 px-2"
						onClick={() => {
							openDialog(programMembership.id);
						}}
					>
						<div className="w-full flex flex-row gap-2 align-items-center">
							<Avatar
								src={programMembership.user.avatar}
								size="lg"
								name={userDisplayName(programMembership.user, null)}
							/>
							<div className="flex flex-column gap-0 text-left overflow-hidden">
								<div>{userDisplayName(programMembership.user)}</div>
							</div>
						</div>
						{/* <div className="w-full flex md:hidden flex-column text-left">
							<div className="text-xs">{userDisplayName(programMembership.user)}</div>
						</div> */}
					</Button>
				</li>
			);
		});

	// eslint-disable-next-line react/jsx-fragments
	return (
		<>
			{chatMembersLoading ? (
				<Spinner />
			) : (
				<>
					{chatMembersSuccess && !chatMembers.length ? (
						<p className="w-full text-center text-gray">{t('chat.noUnthreads')}</p>
					) : (
						<ul className="flex flex-column gap-1 list-none">{renderMembers()}</ul>
					)}
				</>
			)}
		</>
	);
};

export const ChatDialog = () => {
	const { t } = useTranslation();

	const { resetDialog, currentThread, dialogProps } = useChat();

	const [newChatMode, setNewChatMode] = useState(false);

	const handleNewChatClick = useCallback(() => {
		// if (!newChatMode) {
		resetDialog();
		// }
		setNewChatMode((prev) => !prev);
	}, [newChatMode, resetDialog]);

	useEffect(() => {
		if (dialogProps.visible) {
			setNewChatMode(false);
		}
	}, [dialogProps.visible]);

	// const [interlocutorProgramMembership, setInterlocutorProgramMembership] = useState<ProgramMembership | null>(null);
	// const handleThreadLoad = useCallback((interlocutorProgramMembership: ProgramMembership) => {
	// 	// setInterlocutorProgramMembership(interlocutorProgramMembership);
	// 	setNewChatMode(true);
	// }, []);

	const handleThreadMissing = () => {
		setNewChatMode(true);
	};

	const handleBackClick = useCallback(() => {
		resetDialog();
	}, [resetDialog]);

	return (
		<Dialog title={t('chat.title')} size="lg" maxHeight {...dialogProps} bodyClassName="overflow-y-hidden p-0">
			<div className="flex flex-row gap-2 h-full">
				<div
					className={classNames(
						' gap-3 px-2 py-2 pr-0 md:p-4 md:pr-2 overflow-y-scroll p-styled-scrollbar-purplishblue chat-thread-col',
						{
							'chat-active-thread': Boolean(currentThread),
						}
					)}
				>
					<Button
						onClick={handleNewChatClick}
						label={newChatMode ? t('chat.back') : t('chat.newChat')}
						variant="primary-outlined"
						variantSize="md"
						block
						responsiveClassName="hidden md:flex"
					/>
					<Button
						onClick={handleNewChatClick}
						label={newChatMode ? t('chat.back') : t('chat.newChatShort')}
						variant="primary-outlined"
						variantSize="md"
						block
						responsiveClassName="flex md:hidden"
					/>
					<div className="">
						<div className="mb-2 hidden md:block">
							{`${newChatMode ? t('chat.startNewConversation') : t('chat.currentThreads')}:`}
						</div>
						{newChatMode && <ChatMembersList />}
						{!newChatMode && (
							<>
								<ChatThreadList onThreadMissing={handleThreadMissing} />
							</>
						)}
					</div>
				</div>
				<div
					className={classNames('flex-1 h-full w-full pr-2 py-2 pl-0 md:p-4 md:pl-2 overflow-y-hidden', {
						'hidden md:flex': !currentThread,
					})}
				>
					{currentThread ? (
						<ChatConversation threadId={currentThread} onBackClick={handleBackClick} />
					) : (
						<div className="flex-1 h-full flex flex-column justify-content-center align-items-center">
							<span>{t('chat.emptyScreenDesc')}</span>
						</div>
					)}
				</div>
			</div>
		</Dialog>
	);
};
