import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Steps } from 'primereact/steps';
import { Button } from 'components/_new/Button';
import {
	Survey,
	SurveyQuestionBasTranslated,
	SurveyQuestionSingleChoicePresentation,
	SurveyQuestionType,
} from 'types/Survey';
import { Box, BoxSection } from 'components/_new/Box';
import { Controller, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { StepSlider } from 'components/_new/Slider';
import { ButtonGroupYesNo } from 'components/_new/ButtonGroup';
import { Line } from 'components/_new/Line';
import { InputTextArea } from 'components/_new/InputTextArea';
import { InputText } from 'components/_new/InputText';
import { Dropdown } from 'components/_new/Dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { useGlobal } from 'contexts/globalContext';

type SurveyFormAnswer = {
	id: number;
	valueText?: string | null;
	valueNumber?: number | null;
	valueBoolean?: boolean | null;
	choiceId?: number | null;
	choices?: number[];
};
type SurveyForm = {
	answers: Array<SurveyFormAnswer | undefined>;
};

type StepQuestion = SurveyQuestionBasTranslated & { questionId: number; sequence: number };
type Step = {
	// eslint-disable-next-line react/no-unused-prop-types
	num: number;
	questions: StepQuestion[];
};

type FinalSurveyStepProps = Step & {
	form: UseFormReturn<SurveyForm, object>;
};
const FinalSurveyStep = ({ form, questions }: FinalSurveyStepProps) => {
	const { t } = useTranslation();
	const { control, setValue } = form;

	const finalQuestions = useMemo(
		() =>
			questions.map(({ options, choiceOptionPresentation, ...question }) => {
				const finalOptions = options?.length ? options.sort(() => 0.5 - Math.random()) : [];
				// eslint-disable-next-line no-case-declarations
				const finalChoiceOptionPresentation =
					// eslint-disable-next-line no-nested-ternary
					choiceOptionPresentation !== null
						? choiceOptionPresentation
						: (options?.length || 0) <= 5
						? SurveyQuestionSingleChoicePresentation.INPUT
						: SurveyQuestionSingleChoicePresentation.DROPDOWN;
				return { ...question, options: finalOptions, choiceOptionPresentation: finalChoiceOptionPresentation };
			}),
		[questions]
	);

	return (
		<Box>
			{finalQuestions.map(({ questionId, type, text, choiceOptionPresentation, required, options }) => {
				switch (type) {
					case SurveyQuestionType.INFO:
						return (
							<Fragment key={questionId}>
								<p>{text}</p>
							</Fragment>
						);
					case SurveyQuestionType.LONGTEXT:
						return (
							<Fragment key={questionId}>
								<Box variant="white-bordered">
									<BoxSection
										header={() => (
											<div className="flex flex-row gap-2 justify-content-start align-items-center">
												{text}
												{required && <span className="text-red-500">*</span>}
											</div>
										)}
										headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
										contentClassName="pb-2"
									>
										<Controller
											name={`answers.${questionId}`}
											control={control}
											render={({ field: { ref, value } }) => (
												<InputTextArea
													ref={ref}
													value={String(value?.valueText || '')}
													onChange={(event) => {
														setValue(`answers.${questionId}`, {
															id: questionId,
															valueText: event.currentTarget.value,
														});
													}}
												/>
											)}
										/>
									</BoxSection>
								</Box>
							</Fragment>
						);
					case SurveyQuestionType.TEXT:
						return (
							<Fragment key={questionId}>
								<Box variant="white-bordered">
									<BoxSection
										header={() => (
											<div className="flex flex-row gap-2 justify-content-start align-items-center">
												{text}
												{required && <span className="text-red-500">*</span>}
											</div>
										)}
										headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
										contentClassName="pb-2"
									>
										<Controller
											name={`answers.${questionId}`}
											control={control}
											render={({ field: { ref, value } }) => (
												<InputText
													ref={ref}
													value={String(value?.valueText || '')}
													onChange={(event) => {
														setValue(`answers.${questionId}`, {
															id: questionId,
															valueText: event.currentTarget.value,
														});
													}}
												/>
											)}
										/>
									</BoxSection>
								</Box>
							</Fragment>
						);
					case SurveyQuestionType.SCALE:
						return (
							<Fragment key={questionId}>
								<Box variant="white-bordered">
									<Controller
										name={`answers.${questionId}`}
										control={control}
										render={({ field: { ref, value } }) => (
											<BoxSection
												header={() => (
													<div className="flex flex-row gap-2 justify-content-start align-items-center">
														{text}
														{required && <span className="text-red-500">*</span>}
													</div>
												)}
												headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
												contentClassName="pb-2"
											>
												<StepSlider
													ref={ref}
													min={0}
													max={10}
													onChange={(newValue) => {
														setValue(`answers.${questionId}`, {
															id: questionId,
															valueNumber: newValue,
														});
													}}
													defaultValue={Number(value?.valueNumber || 0)}
													colors={[
														['#ecbadb', '#ef4444'],
														['#ecbadb', '#ef4444'],
														['#ecbadb', '#ef4444'],
														['#ecbadb', '#ef4444'],
														['#ecbadb', '#ef4444'],
														['#ecbadb', '#ef4444'],
														['#c2cdf9', '#5035c1'],
														['#c2cdf9', '#5035c1'],
														['#8ad083', '#26751f'],
														['#8ad083', '#26751f'],
													]}
												/>
											</BoxSection>
										)}
									/>
								</Box>
							</Fragment>
						);
					case SurveyQuestionType.BOOLEAN:
						return (
							<Fragment key={questionId}>
								<BoxSection>
									<Controller
										name={`answers.${questionId}`}
										control={control}
										render={({ field: { value } }) => (
											<div className="flex flex-row gap-2 align-items-center">
												<div className="">
													<h3 className="font-semibold heading-16-24 text-on-blue-grey">
														{text}
														{required && <span className="text-red-500">*</span>}
													</h3>
												</div>
												<Line className="flex-1" minWidth={20} />
												<ButtonGroupYesNo
													value={
														value?.valueBoolean !== null
															? Boolean(value?.valueBoolean)
															: undefined
													}
													onChange={({ value }) => {
														setValue(`answers.${questionId}`, {
															id: questionId,
															valueBoolean: value,
														});
													}}
												/>
											</div>
										)}
									/>
								</BoxSection>
							</Fragment>
						);
					case SurveyQuestionType.SINGLE_CHOICE:
						return (
							<Fragment key={questionId}>
								<Box variant="white-bordered">
									<Controller
										name={`answers.${questionId}`}
										control={control}
										render={({ field: { value } }) => (
											<BoxSection
												header={() => (
													<div className="flex flex-row gap-2 justify-content-start align-items-center">
														{text}
														{required && <span className="text-red-500">*</span>}
													</div>
												)}
												headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
												contentClassName="pb-2"
											>
												{!options?.length ? (
													<p className="text-error">
														{t('userPanel.finalSurvey.unableToDisplayOptions')}
													</p>
												) : (
													<>
														{choiceOptionPresentation ===
															SurveyQuestionSingleChoicePresentation.DROPDOWN && (
															<Dropdown
																value={value?.choiceId}
																options={options.map((option) => ({
																	label: option.text,
																	value: option.id,
																}))}
																onChange={({ value }) => {
																	setValue(`answers.${questionId}`, {
																		id: questionId,
																		choiceId: Number(value),
																	});
																}}
															/>
														)}
														{choiceOptionPresentation ===
															SurveyQuestionSingleChoicePresentation.INPUT && (
															<div className="flex flex-column gap-2">
																{options.map((option) => (
																	<label
																		key={option.id}
																		className="flex flex-row gap-2 align-items-center"
																	>
																		<RadioButton
																			name={`answers.${questionId}`}
																			value={option.id}
																			checked={value?.choiceId === option.id}
																			onChange={(event) => {
																				setValue(`answers.${questionId}`, {
																					id: questionId,
																					choiceId: Number(event.value),
																				});
																			}}
																		/>
																		<span>{option.text}</span>
																	</label>
																))}
															</div>
														)}
													</>
												)}
											</BoxSection>
										)}
									/>
								</Box>
							</Fragment>
						);
					case SurveyQuestionType.MULTI_CHOICE:
						return (
							<Fragment key={questionId}>
								<Box variant="white-bordered">
									<Controller
										name={`answers.${questionId}`}
										control={control}
										render={({ field: { value } }) => (
											<BoxSection
												header={() => (
													<div className="flex flex-row gap-2 justify-content-start align-items-center">
														{text}
														{required && <span className="text-red-500">*</span>}
													</div>
												)}
												headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
												contentClassName="pb-2"
											>
												{!options?.length ? (
													<p className="text-error">
														{t('userPanel.finalSurvey.unableToDisplayOptions')}
													</p>
												) : (
													<>
														{/* {finalChoiceOptionPresentation ===
															SurveyQuestionSingleChoicePresentation.DROPDOWN && (
															<Dropdown
																options={options.map((option) => ({
																	label: option.text,
																	value: option.id,
																}))}
															/>
														)} */}
														{/* {finalChoiceOptionPresentation ===
															SurveyQuestionSingleChoicePresentation.INPUT && ( */}
														<div className="flex flex-column gap-2 ">
															{options.map((option) => (
																<label
																	key={option.id}
																	className="flex flex-row gap-2 align-items-center"
																>
																	<Checkbox
																		name={`answers.${questionId}`}
																		value={option.id}
																		checked={value?.choices?.includes(option.id)}
																		onChange={(event) => {
																			const eventValue = Number(event.value);
																			const finalValue = event.checked
																				? [
																						...(value?.choices || []),
																						eventValue,
																				  ]
																				: (value?.choices || []).filter(
																						(id) => id !== eventValue
																				  );
																			setValue(`answers.${questionId}`, {
																				id: questionId,
																				choices:
																					finalValue.length > 0
																						? finalValue
																						: undefined,
																			});
																		}}
																	/>
																	<span>{option.text}</span>
																</label>
															))}
														</div>
														{/* )} */}
													</>
												)}
											</BoxSection>
										)}
									/>
								</Box>
							</Fragment>
						);
					default:
						return null;
				}
			})}
		</Box>
	);
};

type FinalSurveyPresenterProps = {
	survey: Survey;
	updating?: boolean;
	onSave?: (step: number, values: Array<SurveyFormAnswer>) => void;
	onEnd?: () => void;
	panelMode?: boolean;
};

export const FinalSurveyPresenter = ({
	survey,
	updating,
	onSave,
	onEnd,
	panelMode = false,
}: FinalSurveyPresenterProps) => {
	const { t } = useTranslation();
	const { toastRef } = useGlobal();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const questions = survey.questions || [];

	useEffect(() => {
		const answers = questions
			.map((question) => {
				const { id, answer, questionBase } = question;
				if (questionBase) {
					const { type } = questionBase;
					switch (type) {
						case SurveyQuestionType.INFO:
							// skip
							break;
						case SurveyQuestionType.TEXT:
						case SurveyQuestionType.LONGTEXT:
							return {
								id,
								valueText:
									answer && answer?.valueText && answer.valueText.length > 0
										? answer.valueText
										: null,
							};
						case SurveyQuestionType.SCALE:
							return {
								id,
								valueNumber:
									answer && answer?.valueNumber && answer.valueNumber > 0 ? answer.valueNumber : null,
							};
						case SurveyQuestionType.BOOLEAN:
							return {
								id,
								valueBoolean:
									answer && typeof answer?.valueBoolean !== undefined && answer?.valueBoolean !== null
										? answer.valueBoolean
										: null,
							};
						case SurveyQuestionType.SINGLE_CHOICE:
							return {
								id,
								choiceId: answer?.choiceId || null,
							};
						case SurveyQuestionType.MULTI_CHOICE:
							return {
								id,
								choices: answer?.choices ? answer.choices.map(({ id }) => id) : [],
							};
						default:
							console.log('Unsupported question type', type);
							return null;
					}
				}
				return null;
			})
			.filter(Boolean) as SurveyFormAnswer[];

		answers.forEach(({ id, ...values }) => {
			form.setValue(`answers.${id}`, { id, ...values });
		});
	}, [questions]);

	const form = useForm<SurveyForm>({ defaultValues: {} });
	useFieldArray({ name: 'answers', control: form.control });

	const steps: Step[] = useMemo(
		() =>
			questions
				.reduce<Step[]>((current, question) => {
					if (question && question.questionBase) {
						const { step, sequence } = question.questionBase;

						const currentStepIdx = current.findIndex(({ num }) => num === step);
						const stepQuestion = {
							...question.questionBaseTranslated!,
							questionId: question.id,
							sequence,
						};
						if (currentStepIdx === -1) {
							current.push({ num: step, questions: [stepQuestion] });
						} else {
							current[currentStepIdx].questions.push(stepQuestion);
						}
					}
					return current;
				}, [])
				.map((step) => {
					return {
						...step,
						questions: step.questions.sort(({ sequence: sequenceA }, { sequence: sequenceB }) =>
							sequenceA > sequenceB ? 1 : -1
						),
					};
				}),
		[questions]
	);

	useEffect(() => {
		return () => {
			setCurrentStepNumber(-1);
			setAfterFinishStep(false);
		};
	}, []);

	const [currentStepNumber, setCurrentStepNumber] = useState(Math.min(...steps.map(({ num }) => num)));
	const [afterFinishStep, setAfterFinishStep] = useState(false);
	const currentStep = steps.find(({ num }) => num === currentStepNumber);
	const isLastStep = currentStepNumber === Math.max(...steps.map(({ num }) => num));

	const handlePrevStepClick = useCallback(() => setCurrentStepNumber((prev) => prev - 1), []);
	const handleNextStepClick = useCallback(() => setCurrentStepNumber((prev) => prev + 1), []);

	const handleSubmitForm = form.handleSubmit(({ answers }) => {
		if (onSave) {
			const currentStepAnswers = answers
				.filter(Boolean)
				.map((answer) => answer as SurveyFormAnswer)
				.filter(({ id }) => (currentStep?.questions || []).map(({ questionId }) => questionId).includes(id));

			const requiredQuestionAnswers = questions
				.filter(({ questionBase }) => questionBase?.required || false)
				.map(({ id }) => id);
			const checkIsValid = (type: SurveyQuestionType, answer: SurveyFormAnswer) => {
				switch (type) {
					case SurveyQuestionType.TEXT:
					case SurveyQuestionType.LONGTEXT:
						if (!answer.valueText || String(answer.valueText).length === 0) {
							return false;
						}
						break;
					case SurveyQuestionType.SCALE:
						if (answer.valueNumber === null) {
							return false;
						}
						break;
					case SurveyQuestionType.BOOLEAN:
						if (answer.valueBoolean === null) {
							return false;
						}
						break;
					case SurveyQuestionType.SINGLE_CHOICE:
						if (answer.choiceId === null) {
							return false;
						}
						break;
					case SurveyQuestionType.MULTI_CHOICE:
						if ((answer.choices || []).length === 0) {
							return false;
						}
						break;
					default:
						break;
				}
				return true;
			};
			// eslint-disable-next-line no-restricted-syntax
			for (const answer of currentStepAnswers) {
				const { id: answerId } = answer;
				const questionBase = questions.find(({ id }) => id === answerId)?.questionBase || null;
				if (questionBase && requiredQuestionAnswers.includes(answerId)) {
					if (!checkIsValid(questionBase.type, answer)) {
						toastRef?.current?.show({
							severity: 'error',
							life: 3000,
							summary: t(`misc.error`),
							detail: t('userPanel.finalSurvey.completeAllRequiredFields'),
						});
						return false;
					}
				}
			}

			const transformedValues = currentStepAnswers.reduce<Array<any>>(
				(current, { id, valueBoolean, valueNumber, valueText, choiceId, choices }) => {
					if (typeof valueText !== 'undefined' && valueText !== null) {
						current.push({ id, valueText });
					} else if (typeof valueNumber !== 'undefined' && valueNumber !== null) {
						current.push({ id, valueNumber });
					} else if (typeof valueBoolean !== 'undefined' && valueBoolean !== null) {
						current.push({ id, valueBoolean });
					} else if (typeof choiceId !== 'undefined' && choiceId !== null) {
						current.push({ id, choiceId });
					} else if (typeof choices !== 'undefined' && Array.isArray(choices) && choices.length > 0) {
						current.push({ id, choices });
					}
					return current;
				},
				[]
			);

			onSave(currentStepNumber, transformedValues);
			if (!isLastStep) {
				handleNextStepClick();
			} else {
				setAfterFinishStep(true);
			}
		}
		return true;
	});

	return (
		<div className="flex flex-column gap-4">
			<div className="w-full overflow-x-auto">
				{steps.length > 1 && (
					<Steps
						className="w-full"
						model={steps
							.sort(({ num: numA }, { num: numB }) => (numA > numB ? 1 : -1))
							.map((step) => ({
								id: String(step.num),
								label: t('userPanel.finalSurvey.pageNo', { count: step.num }),
								disabled: true,
							}))}
						activeIndex={currentStepNumber - 1}
						onSelect={({ item }) => {
							if (!afterFinishStep) {
								setCurrentStepNumber(Number(item.id));
							}
						}}
						readOnly
					/>
				)}
			</div>

			{afterFinishStep && (
				<div className="flex flex-column justify-content-center align-items-center gap-4">
					<p>{t('userPanel.finalSurvey.thanks')}</p>
					{panelMode && (
						<Button
							label={t('userPanel.finalSurvey.backToUserPanel')}
							onClick={() => {
								onEnd?.();
							}}
						/>
					)}
					{!panelMode && (
						<Button
							label={t('userPanel.finalSurvey.backToProgramPage')}
							onClick={() => {
								onEnd?.();
							}}
						/>
					)}
				</div>
			)}

			{!afterFinishStep && (
				<form onSubmit={handleSubmitForm}>
					{currentStep && <FinalSurveyStep form={form} {...currentStep} />}

					<div className="flex flex-row flex-wrap gap-2 justify-content-center md:justify-content-between">
						{currentStepNumber > 1 ? (
							<Button
								label={t('userPanel.finalSurvey.prevPage')}
								onClick={handlePrevStepClick}
								variant="primary-outlined"
							/>
						) : (
							<div />
						)}
						{!isLastStep && (
							<Button submit label={t('userPanel.finalSurvey.savecAndNextPage')} loading={updating} />
						)}
						{isLastStep && (
							<Button submit label={t('userPanel.finalSurvey.sendAnswers')} loading={updating} />
						)}
					</div>
				</form>
			)}
		</div>
	);
};
