import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePanel } from 'contexts/panelContext';
import { Page } from 'components/_new/Page';
import { Button } from 'components/_new/Button';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { Link } from 'components/_new/Link';
import { MessageBox } from 'components/_new/MessageBox';
import { useGlobal } from 'contexts/globalContext';
import { usePair } from 'contexts/pairContext';
import { Icon } from 'components/_new/Icon';

export const MentibotAI = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { organizationName, programName, type } = useParams();

	const { currentProgram } = usePanel();
	const {
		pair: { id: pairId },
	} = usePair();

	const isList = pathname.endsWith('/list');

	const { setReportIssueModalIsOpen } = useGlobal();

	return (
		<>
			<SubPageTitle
				title={
					<>
						<Icon name="wand-magic-sparkles" iconStyle="solid" className="text-purple pr-2" />
						{t('userPanel.navigation.mentibotAi')}
					</>
				}
				additional="(beta)"
				right={
					!isList
						? () => (
								<Link
									to={`/panel/${organizationName}/${programName}/${type}/pair/${pairId}/mentibot-ai/list`}
									className="font-bold"
									icon="arrow-left"
									iconSet="fa"
									iconStyle="solid"
									noUnderline
									responsive="sm"
								>
									{t('userPanel.mentibotAi.backToList')}
								</Link>
						  )
						: undefined
				}
			/>
			<Page className="flex flex-column gap-4">
				<MessageBox message={t('userPanel.mentibotAi.featureInfo')} variant="purple" />

				{/* {!currentProgram.mentibotEnabled && (
					<MessageBox message={t('userPanel.mentibotAi.isDisabledForProgram')} />
				)}

				<Outlet />

				<div className="flex flex-column gap-4 mt-4">
					<div>{t('userPanel.mentibotAi.feedback.desc')}</div>
					<div className="flex flex-row justify-content-center">
						<Button
							onClick={() => {
								setReportIssueModalIsOpen?.(true);
							}}
							label={t('userPanel.mentibotAi.feedback.action')}
							rounded
						/>
					</div>
				</div> */}
			</Page>
		</>
	);
};
