import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import i18n from 'locale';
import ReportIssues from 'components/_common/ReportIssues';
import PanelLayout from 'components/_layouts/panel/PanelLayout';
import { MenuItemExt, PanelMenu } from 'components/_common/PanelMenu';
import ChangeOrganization from 'components/_common/panel/ChangeOrganization';
import { border, colors } from 'theme';
import UserSettings from 'components/_common/UserSettings';
import ProgramPanelContextProvider, { useProgramPanel } from 'contexts/programPanelContext';
import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'hooks/useNavigate';
import { Button } from 'components/_new/Button';
import { Divider } from 'components/_new/Divider';
import { useAuth } from 'contexts/authContext';
import { Dialog } from 'components/_new/Dialog';
import { Spinner } from 'components/_new/Spinner';
import { MyCalendarDialog } from 'components/myCalendar/MyCalendarDialog';
import { useGlobal } from 'contexts/globalContext';
import { MyAccountDialog } from 'components/myAccount/MyAccountDialog';
import { ChangeAvatarDialog } from 'components/myAccount/components/ChangeAvatarDialog';
import { ChatButton } from 'components/chat/ChatButton';
import { ChatContainer } from 'components/chat/ChatContainer';

const ProgramPanelComponent = () => {
	const { currentUser } = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate({
		scroll: ['scrollWrapperRef'],
	});
	const { data: program, isSuccess, isLoading, isError } = useProgramPanel();

	const hasOrganizationAdminPrivilage =
		currentUser &&
		program &&
		currentUser.organizationMemberships.find(({ organizationId }) => program.organizationId === organizationId)
			?.role === 'organization admin';

	const {
		myAccountModalOpen,
		setMyAccountModalOpen,
		myCalendarModalOpen,
		setMyCalendarModalOpen,
		avatarModalIsOpen,
		setAvatarModalIsOpen,
	} = useGlobal();
	const [showOrgDialog, setShowOrgDialog] = useState(false);
	const [visibleLeft, setVisibleLeft] = useState(false);
	const [reportIssueModalIsOpen, setReportIssueModalIsOpen] = useState(false);

	const navigationOptions: MenuItemExt[] =
		program && isSuccess
			? [
					{
						id: `program-${program.id}-dashboard`,
						label: t(`organizationPanel.programDashboard`),
						icon: <i className="pi pi-home" />,
						command: () => {
							navigate(`/program/${program.name}/dashboard`);
						},
					},
					{
						id: `program-${program.id}-communication`,
						label: t(`organizationPanel.programCommunication`),
						icon: <i className="pi pi-comments" />,
						command: () => {
							navigate(`/program/${program.name}/communication`);
						},
					},
					program && {
						id: `program-${program.id}-users`,
						label: t(`organizationPanel.programUsers`),
						icon: <i className="pi pi-user" />,
						command: () => {
							navigate(`/program/${program.name}/membership/list`);
						},
					},
					{
						id: `program-${program.id}-tests`,
						label: t(`organizationPanel.programMentorsAndMentees`),
						icon: <i className="pi pi-list" />,
						command: () => {
							navigate(`/program/${program.name}/application/list`);
						},
					},
					{
						id: `program-${program.id}-pairs`,
						label: t(`organizationPanel.programPairs`),
						icon: <i className="pi pi-users" />,
						command: () => {
							navigate(`/program/${program.name}/pair/list`);
						},
					},
					{
						id: `program-${program.id}-statistics`,
						label: t(`organizationPanel.programStatistics`),
						icon: <i className="pi pi-chart-bar" />,
						command: () => {
							navigate(`/program/${program.name}/statistics`);
						},
					},
					{
						id: `program-${program.id}-knowledge`,
						label: t('organizationPanel.knowledgeFiles'),
						icon: <i className="pi pi-book" />,
						command: () => {
							navigate(`/program/${program.name}/knowledge`);
						},
					},
					{
						id: `program-${program.id}-config`,
						label: t('organizationPanel.programConfig'),
						icon: <i className="pi pi-cog" />,
						command: () => {
							navigate(`/program/${program.name}/config`);
						},
					},
			  ]
			: [];

	const navigationBottomOptions: MenuItemExt[] = useMemo(
		() =>
			[
				program && {
					id: 'programPage',
					icon: <i className="pi pi-send" />,
					label: t('userPanel.navigation.programPage'),
					command: () => navigate(`/${program.name}`),
				},
				hasOrganizationAdminPrivilage && {
					id: 'backToOrgnization',
					icon: <i className="fa fa-solid fa-building-columns" />,
					label: t('organizationPanel.backToOrganization'),
					command: () => navigate(`/organization/${program.organization?.name}`),
				},
				{
					id: 'reportIssue',
					icon: <i className="pi pi-info-circle" />,
					label: t('userPanel.navigation.reportIssue'),
					command: () => setReportIssueModalIsOpen?.(true),
				},
			].filter(Boolean) as MenuItemExt[],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[program]
	);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<>
			<Helmet title={t('misc.pageTitles.panel')} />

			<MyAccountDialog
				visible={myAccountModalOpen}
				onHide={() => {
					setMyAccountModalOpen(false);
				}}
			/>
			<ChangeAvatarDialog visible={avatarModalIsOpen} onHide={() => setAvatarModalIsOpen(false)} />
			<MyCalendarDialog
				visible={myCalendarModalOpen}
				onHide={() => {
					setMyCalendarModalOpen(false);
				}}
			/>

			<Dialog title="Zmiana organizacji" size="sm" onHide={() => setShowOrgDialog(false)} visible={showOrgDialog}>
				<ChangeOrganization card onCancel={() => setShowOrgDialog(false)} />
			</Dialog>
			<Dialog
				title={t('userPanel.navigation.reportIssue')}
				size="lg"
				onHide={() => setReportIssueModalIsOpen(false)}
				visible={reportIssueModalIsOpen}
			>
				<ReportIssues />
			</Dialog>

			<PanelLayout
				navigationOptions={navigationOptions}
				navigationTop={
					<div className="h-5rem p-2">
						<div className="flex flex-column align-items-end justify-content-center h-full w-full text-base text-right">
							<span>
								{t('userPanel.youAreIn')} <b>{t('programPanel.inTitle')}</b>
							</span>
						</div>
					</div>
				}
				navigationBottom={<PanelMenu model={navigationBottomOptions} multiple />}
				header={
					<>
						<Button
							icon="fa fa-bars fa-2x"
							iconOnly
							label="Toggle menu"
							onClick={() => setVisibleLeft(true)}
							variant="primary-text"
							className="mx-2 flex md:hidden"
						/>
						<Sidebar
							role="navigation"
							visible={visibleLeft}
							onHide={() => setVisibleLeft(false)}
							showCloseIcon={false}
							style={{
								backgroundColor: `${colors.purplishBlue}`,
								borderRadius: '0px 20px 20px 0px',
								maxWidth: '70%',
							}}
						>
							<PanelMenu model={navigationOptions} onHide={() => setVisibleLeft(false)} multiple />
							<Divider margin={4} />
							<PanelMenu model={navigationBottomOptions} onHide={() => setVisibleLeft(false)} multiple />
						</Sidebar>
						{program?.organization && (
							<>
								{program?.organization.logo && (
									<img src={program?.organization.logo} alt="logo" className="max-h-3rem mr-3" />
								)}
								<div
									className="h-3rem pl-3 hidden md:block"
									style={{ borderLeft: border(colors.lightGray) }}
								>
									<h3 className="mt-0 mb-1">{program?.organization.displayName}</h3>
									<small>{t('organizationPanel.yourOrganization')}</small>
								</div>
							</>
						)}

						{currentUser && (
							<div
								className="flex align-items-center h-3rem ml-auto pr-3 pl-4 gap-2"
								style={{ borderLeft: border(colors.lightGray) }}
							>
								<ChatButton programPanel />
								<UserSettings
									currentUser={currentUser}
									setShowOrgDialog={setShowOrgDialog}
									optionsLanguage={i18n.resolvedLanguage}
									source="panel"
								/>
							</div>
						)}
					</>
				}
			>
				{isError && <p>{t('userPanel.noAccess')}</p>}
				{isSuccess && <Outlet />}
			</PanelLayout>
		</>
	);
};

const ProgramPanel = () => (
	<ProgramPanelContextProvider>
		<ProgramPanelComponent />
	</ProgramPanelContextProvider>
);

export default ProgramPanel;
