/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useMemo, forwardRef, useEffect, CSSProperties } from 'react';
import composeRefs from '@seznam/compose-react-refs';
import { DomHandler, classNames } from 'primereact/utils';
import { useEventListener } from 'primereact/hooks';
import { SliderBase, SliderCommonProps, prepareSliderData } from './SliderBase';

type StepSliderProps = SliderCommonProps & { defaultValue?: number; onChange?: (newValue: number) => void };

export const StepSlider = forwardRef(
	({ defaultValue, onChange, disabled, colors, ...props }: StepSliderProps, ref: any) => {
		const { min = 0, max, step } = props;

		const { stepped } = prepareSliderData(min, max, step);

		const [currentValue, setCurrentValue] = useState<number | null>(null);
		const sliderBaseRef = useRef<HTMLDivElement>(null);
		const handlerRef = useRef<HTMLDivElement>(null);

		useEffect(() => {
			setCurrentValue(defaultValue || min);
		}, [defaultValue, min]);

		const [dragging, setDragging] = useState(false);
		const initX = useRef(0);
		const barWidth = useRef(0);
		// const handleIndex = useRef(0);
		const sliderHandleClick = useRef(false);

		const [bindDocumentMouseMoveListener, unbindDocumentMouseMoveListener] = useEventListener({
			type: 'mousemove',
			listener: (event: any) => onDrag(event),
		});
		const [bindDocumentMouseUpListener, unbindDocumentMouseUpListener] = useEventListener({
			type: 'mouseup',
			listener: (event: any) => onDragEnd(event),
		});
		const [bindDocumentTouchMoveListener, unbindDocumentTouchMoveListener] = useEventListener({
			type: 'touchmove',
			listener: (event: any) => onDrag(event),
		});
		const [bindDocumentTouchEndListener, unbindDocumentTouchEndListener] = useEventListener({
			type: 'touchend',
			listener: (event: any) => onDragEnd(event),
		});

		const onDragStart = (event: any, index?: number) => {
			// for disabled state
			if (disabled) {
				return;
			}

			if (handlerRef?.current) {
				handlerRef.current.focus();
			}

			// dragging.current = true;
			setDragging(true);
			updateDomData();
			sliderHandleClick.current = true;
			// handleIndex.current = index;
			event.preventDefault();
		};

		const onDragEnd = (event: any) => {
			if (dragging) {
				// dragging.current = false;
				setDragging(false);

				unbindDocumentMouseMoveListener();
				unbindDocumentMouseUpListener();
				unbindDocumentTouchMoveListener();
				unbindDocumentTouchEndListener();
			}
		};

		const onMouseDown = (event: any, index?: number) => {
			bindDocumentMouseMoveListener();
			bindDocumentMouseUpListener();
			onDragStart(event, index);
		};

		const onTouchStart = (event: any, index?: number) => {
			bindDocumentTouchMoveListener();
			bindDocumentTouchEndListener();
			onDragStart(event, index);
		};

		const spin = (event: any, dir: number) => {
			const step = (props.step || 1) * dir;
			updateValue(event, (currentValue || 0) + step);
			event.preventDefault();
		};

		const onKeyDown = (event: any, index?: number) => {
			// for disabled state
			if (disabled) {
				return;
			}

			// handleIndex.current = index;
			const { key } = event;

			if (key === 'ArrowRight' || key === 'ArrowUp') {
				spin(event, 1);
			} else if (key === 'ArrowLeft' || key === 'ArrowDown') {
				spin(event, -1);
			}
		};

		const onBarClick = (event: any) => {
			// for disabled state
			if (disabled) {
				return;
			}

			if (!sliderHandleClick.current) {
				updateDomData();
				setValue(event);

				// props.onSlideEnd && props.onSlideEnd({ originalEvent: event, value });
			}

			sliderHandleClick.current = false;
		};

		const setValue = (event: any) => {
			const pageX = event.touches ? event.touches[0].pageX : event.pageX;
			const handleValue = ((pageX - initX.current) * 100) / barWidth.current;

			let newValue = (max - min) * (handleValue / 100) + min;

			if (props.step) {
				const oldValue = currentValue || 0;
				const diff = newValue - oldValue;

				if (diff < 0) {
					newValue = oldValue + Math.ceil(newValue / props.step - oldValue / props.step) * props.step;
				} else if (diff > 0) {
					newValue = oldValue + Math.ceil(newValue / props.step - oldValue / props.step) * props.step;
				}
			} else {
				newValue = Math.ceil(newValue);
			}

			return updateValue(event, newValue);
		};

		const updateValue = (event: any, val: number) => {
			let parsedValue = parseFloat(val.toFixed(10));
			let newValue = parsedValue;

			if (parsedValue < min) {
				parsedValue = min;
			} else if (parsedValue > max) {
				parsedValue = max;
			}

			newValue = parsedValue;

			if (onChange) {
				onChange(newValue);
			}

			setCurrentValue(newValue);

			return newValue;
		};

		const onDrag = (event: any) => {
			if (dragging) {
				setValue(event);
				event.preventDefault();
			}
		};

		const updateDomData = () => {
			if (sliderBaseRef?.current) {
				const rect = sliderBaseRef.current.getBoundingClientRect();
				initX.current = rect.left + DomHandler.getWindowScrollLeft();
				barWidth.current = sliderBaseRef.current.offsetWidth;
			}
		};

		const preparePercentages = useMemo(() => {
			const cv = currentValue || 0;
			let handleValue;
			if (cv < min) {
				handleValue = min;
			} else if (cv > max) {
				handleValue = max;
			} else {
				handleValue = ((cv - min) * 100) / (max - min);
			}

			return handleValue;
		}, [currentValue, max, min]);

		// const prapareTrackStyle = useMemo(() => {
		// 	const rangeStyle = { width: `${preparePercentages}%` };
		// 	return rangeStyle;
		// }, [currentValue, max, min]);

		const handlerCurrentColor = useMemo(() => {
			const fixedMin = min >= max ? max : min;
			const fixedMax = max <= min ? min : max;
			const range = fixedMax - fixedMin + 1;
			const fixedStep = step && step >= 0 ? step : 0;
			const stepsCount = fixedStep ? Math.ceil(range / fixedStep) : range;

			const colorCount = colors?.length || 0;
			if (currentValue !== null && colorCount > 0) {
				const colorIndex = colorCount ? Math.floor((currentValue * colorCount) / stepsCount) : 0;
				const selectedColor = colors?.[colorIndex];
				if (selectedColor) {
					const color = Array.isArray(selectedColor) ? selectedColor?.[1] || undefined : selectedColor;
					return color || undefined;
				}
				return undefined;
			}
			return undefined;
		}, [min, max, step, colors, currentValue]);

		const prapareRangeStyle = useMemo(() => {
			const rangeStyle: CSSProperties = {
				left: `${preparePercentages}%`,
				backgroundColor: handlerCurrentColor,
			};
			return rangeStyle;
		}, [preparePercentages, handlerCurrentColor]);

		return (
			<SliderBase
				ref={composeRefs(ref, sliderBaseRef)}
				whileDrag={dragging}
				onBarClick={onBarClick}
				// track={() => { // for colorize before handler track
				// 	return stepped ? (
				// 		stepsArr.map(() => <div className="slider-track-step" />)
				// 	) : (
				// 		<div className="slider-track-step" />
				// 	);
				// }}
				disabled={disabled}
				colors={colors}
				{...props}
			>
				<div
					ref={handlerRef}
					className={classNames('slider-handler', {
						'slider-handler-ondrag': dragging,
						'slider-handler-animated': stepped,
						'slider-handler-disabled': disabled,
					})}
					role="slider"
					// eslint-disable-next-line jsx-a11y/tabindex-no-positive
					tabIndex={0}
					aria-valuemin={min}
					aria-valuemax={max}
					aria-valuenow={currentValue || 0}
					onMouseDown={(event) => onMouseDown(event)}
					onTouchStart={(event) => onTouchStart(event)}
					onKeyDown={(event) => onKeyDown(event)}
					style={prapareRangeStyle}
				>
					{currentValue}
				</div>
			</SliderBase>
		);
	}
);
