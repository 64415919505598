import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { usersApi } from 'api';
import banner from 'assets/mentiway-banner.svg';
import { Memberships } from 'types/Memberships';
import { Loader } from 'components/_new/Loader';
import { Box, BoxSection } from 'components/_new/Box';
import ChangeOrganization from './panel/ChangeOrganization';
import { ProgramFinishedPage } from '../ProgramFinishedPage';

const ChangeOrganizationWrapper = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const cutProgramStrings = (memberships: Memberships[]) =>
		memberships
			.filter((membership) => membership.programs && membership.programs.length > 0)
			.map((membership) => {
				const cutPrograms = membership.programs?.map((program) => ({
					...program,
					name: program.name.split('/')[1],
				}));
				return { ...membership, programs: cutPrograms };
			});

	const { data: memberships, isLoading: getMembershipsLoading } = useQuery(
		['auth', 'memberships'],
		usersApi.getMemberships,
		{
			enabled: true,
			onSuccess: (data) => {
				const userMembershipsCutData = cutProgramStrings(data);
				if (userMembershipsCutData.length === 1 && userMembershipsCutData[0].programs.length === 1) {
					const organizationName = userMembershipsCutData[0].organization.name;
					const programName = userMembershipsCutData[0].programs[0].name;
					const proposedProgramPath = `/${organizationName}/${programName}/`
						.toLocaleLowerCase()
						.replaceAll('//', '/');
					navigate(proposedProgramPath);
				}
			},
		}
	);
	const userMemberships = memberships ? cutProgramStrings(memberships) : [];

	if (getMembershipsLoading) {
		return <Loader />;
	}
	if (!getMembershipsLoading && userMemberships.length === 0) {
		return <ProgramFinishedPage />;
	}

	return (
		<>
			<Helmet title={t('landingPages.buttons.chooseOrganization')} />
			<div className="flex flex-column align-items-center justify-content-center row-gap-6 pt-8 pb-7">
				<Box variant="white" dropShadow className="w-480-auto">
					<BoxSection className="sm:px-6 py-4 text-center">
						<img src={banner} alt="banner" className="mt-2 mb-6 align-self-center" />
						<ChangeOrganization />
					</BoxSection>
				</Box>
			</div>
		</>
	);
};

export default ChangeOrganizationWrapper;
