import React, { useRef, useState, useCallback, useMemo, useLayoutEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { dayjs } from 'utils/dayjs';
import { userFilesApi, sessionAgendaApi, userPanelApi } from 'api';
import { RouterUrlParams } from 'App';
import { useSessionsData } from 'components/userPanel/sessions/Sessions';
import { Icon, IconStyle } from 'components/_new/Icon';
import { dateFormat } from 'utils/dateFormat';
import { SessionPayload } from 'types/payloads/SessionPayload';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePanel } from 'contexts/panelContext';
import { useGlobal } from 'contexts/globalContext';
import { AvatarSet } from 'components/_new/AvatarSet';
import { Box, BoxSection, BoxSectionList } from 'components/_new/Box';
import { NavTabs, NavTabsNavLink } from 'components/_new/NavTabs';
import { Page } from 'components/_new/Page/Page';
import { Button } from 'components/_new/Button';
import { OverlayElement } from 'components/_new/Overlay';
import { Menu, MenuItemLink } from 'components/_new/Menu';
import { Tag } from 'components/_new/Tag';
import { Line } from 'components/_new/Line';
import { scrollToElementById } from 'utils/scroll';
import { SessionFile } from 'types/SessionFile';
import { Link } from 'components/_new/Link/Link';
import { useNavigate } from 'hooks/useNavigate';
import { useAuth } from 'contexts/authContext';
import PageMissing from 'components/PageMissing';
import { usePair } from 'contexts/pairContext';
import { userDisplayName } from 'utils/userDisplayName';
import { Tooltip } from 'primereact/tooltip';
import { File } from 'types/File';
import { useScope } from 'contexts/scopeContext';
import { Rating } from './components/Rating';
import { ReflectionDialog } from './components/ReflectionDialog';
import { EditSessionDetailsDialog } from './components/EditSessionDetailsDialog';
import { AgendaSubpage } from './subpages/AgendaSubpage';
import { MaterialsSubpage } from './subpages/MaterialsSubpage';
import { EvaluationMenteeSubpage } from './subpages/EvaluationMenteeSubpage';
import { EvaluationMentorSubpage } from './subpages/EvaluationMentorSubpage';
import { YourNotesSubpage } from './subpages/YourNotestSubpage';
import { HomeworkSubpage } from './subpages/HomeworkSubpage';
import { RemoveSessionDialogConfirm } from './components/RemoveSessionDialogConfirm';
import { SendMeetingCalendarDialog } from './components/SendMeetingCalendarDialog';
import { useMapSessionTasksToItems } from './partials/utils/useMapSessionTasksToItems';
import { RecommendationAISubpage } from './subpages/RecommendationAISubpage';

type Subpage = {
	key: string;
	to: string;
	label: string;
	path: string;
	element: ReactNode;
	badge?: number;
	iconRight?: string;
	iconRightStyle?: IconStyle;
};

const SessionAgenda = () => {
	const { t } = useTranslation();
	const { organizationName, programName, type, sessionId } = useParams() as RouterUrlParams;

	const { toastRef } = useGlobal();
	const { currentUser } = useAuth();
	const { currentProgramMembership, currentApplicationData } = usePanel();
	const { pair } = usePair();
	const { sessionsData, sessiosRefetch } = useSessionsData();
	const { currentProgram } = useScope();

	const currentSession = sessionsData.find((session) => session.id === +sessionId);
	const items = useMapSessionTasksToItems(currentSession?.sessionTasks || []) as unknown as MenuItemLink[];

	// your notes
	const { data: getUserfilesData, refetch: getUserFilesRefetch } = useQuery(
		[
			'userfiles',
			{
				programMembershipId: currentProgramMembership.id,
				applicationId: currentApplicationData.id,
				pairId: pair.id,
				sessionId: currentSession?.id,
			},
		],
		() =>
			userFilesApi.getUserfiles(
				Number(currentProgramMembership?.id),
				Number(currentApplicationData?.id),
				pair.id,
				{
					sessionId: currentSession?.id || null,
				}
			),
		{ enabled: Boolean(currentProgramMembership?.id) && Boolean(currentApplicationData?.id) }
	);
	const userFiles: SessionFile[] = getUserfilesData || [];

	// modal states
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [meetingCalendarDialogOpen, setMeetingCalendarDialogOpen] = useState(false);
	const [removeSessionDialogConfirmOpen, setRemoveSessionDialogConfirmOpen] = useState(false);
	const [autoreflectionModalOpen, setAutoreflectionModalOpen] = useState(false);
	const openAutoreflectionDialog = () => setAutoreflectionModalOpen(true);

	// const isFinished = Boolean(currentSession?.finished);

	// scroll to content
	const { hash } = useLocation();
	useLayoutEffect(() => {
		if (hash && hash.length > 0) {
			const elementId = hash.replace('#', '').trim();
			scrollToElementById(elementId, -150);
		}
	}, [hash]);

	const { mutate: updateSession, mutateAsync: updateSessionAsync } = useMutation(
		(data: SessionPayload) =>
			userPanelApi.updateSession(
				Number(currentSession?.pairId),
				currentProgramMembership.id,
				Number(currentSession?.id),
				data
			),
		{
			onSuccess: () => {
				sessiosRefetch();
			},
		}
	);

	const handleRatingChange = useCallback(
		(rating: number) => {
			updateSession({ rating });
		},
		[updateSession]
	);

	const prepareSessionTypeName = (sessionType: string) => {
		switch (String(sessionType)) {
			case 'first':
				return t('userPanel.sessions.first.fullTitle');
			case 'second':
				return t('userPanel.sessions.second.fullTitle');
			case 'middle':
				return t('userPanel.sessions.middle.fullTitle');
			case 'penultimate':
				return t('userPanel.sessions.penultimate.fullTitle');
			case 'last':
				return t('userPanel.sessions.last.fullTitle');
			case 'standard':
				return t('userPanel.sessions.standard.fullTitle');
			default:
				return '';
		}
	};

	const canRemove = currentSession?.sessionType === 'standard';

	const menuButtonRef = useRef(null);
	const menuRef = useRef<OverlayElement>(null);

	const homeworkSaveHandler = ({
		mostImportant,
		practice,
		homework,
	}: {
		mostImportant: string;
		practice: string;
		homework: string;
	}) => updateSessionAsync({ mostImportant, practice, homework });

	const [reflectionsExpanded, setReflectionsExpanded] = useState(false);
	const toggleExpandReflections = () => {
		setReflectionsExpanded((prev) => !prev);
	};

	const [updatingReflections, setUpdatingReflections] = useState(false);
	const handleReflectionsSave = useCallback(
		(values: Record<string, string>) => {
			setUpdatingReflections(true);
			updateSessionAsync({ reflection: values }).finally(() => {
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessionsNew.reflection.updatedSuccessfully'),
				});

				setUpdatingReflections(false);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateSession]
	);

	const reflectionKeys = useMemo(() => {
		switch (type) {
			case 'mentee':
				return ['general', 'thingsEasy', 'happyFor', 'thingsHard', 'nextSteps', 'feedback', 'other'];
			case 'mentor':
			default:
				return [
					'general',
					'techniques',
					'barriers',
					'thingsHard',
					'selfReflection',
					'happyFor',
					'sadFor',
					'other',
				];
		}
	}, [type]);

	const reflections = useMemo(() => {
		return reflectionKeys.map((key) => ({
			key,
			title: t(`userPanel.sessionsNew.reflection.${currentApplicationData.applicationRole}.${key}`),
			value: (currentSession?.reflection || {})[key] || null,
		}));
	}, [reflectionKeys, t, currentApplicationData.applicationRole, currentSession?.reflection]);
	const hasReflections = reflections.some(({ value }) => value && value.length > 0);

	const navigate = useNavigate();

	if (!currentSession) return <PageMissing />;

	// agenda
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data: sessionAgenda, isFetched: sessionAgendaFetched } = useQuery(
		['session-agenda', currentSession.id],
		() => sessionAgendaApi.getSessionAgendaForSessionId(currentSession.id, pair.id, currentApplicationData.id),
		{
			enabled: Boolean(currentSession),
			onError: () => {
				// none
			},
		}
	);

	// evaluation
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { refetch: getSessionQuestionnaires, data: questionnairesData } = useQuery(
		['questionnaires', currentSession?.sessionType],
		() => userPanelApi.getQuestionnaires(pair.id, currentProgramMembership.id, currentSession?.sessionType),
		{ enabled: Boolean(currentSession) }
	);

	// delete session
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { mutate: deleteSession } = useMutation(
		() => userPanelApi.deleteSession(pair.id, currentProgramMembership.id, currentSession?.id),
		{
			onSuccess: () => {
				navigate(`/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session`);
				if (sessiosRefetch) {
					sessiosRefetch();
				}
				toastRef?.current?.show({
					severity: 'success',
					life: 3000,
					summary: t('misc.success'),
					detail: t('userPanel.sessions.removeSuccess'),
				});
			},
		}
	);

	const currentSessionIndex = sessionsData.findIndex((session) => session.id === currentSession.id);

	const prevSession = sessionsData[currentSessionIndex - 1];
	const hasPrevSession = Boolean(prevSession?.id);

	const nextSession = sessionsData[currentSessionIndex + 1];
	const hasNextSession = Boolean(nextSession?.id);

	const isLast = currentSession.sessionType === 'last';

	const sessionDisplayableFiles = currentSession.files.filter((file) => file[`${type}Access` as keyof File]);

	const menteeQuestionnairesData =
		questionnairesData?.filter((questionnaire) => questionnaire.forMentorOnly === false) || undefined;
	const menteeQuestionnaireAnswers = menteeQuestionnairesData?.find(
		(questionnaire) => questionnaire.sessionType === currentSession.sessionType
	)?.questionnaireAnswers;
	const hasMenteeQuestionnaireAnswers =
		menteeQuestionnaireAnswers &&
		Object.entries(menteeQuestionnairesData).every(([k, v]) =>
			// eslint-disable-next-line no-nested-ternary
			typeof v !== 'undefined' && typeof v === 'number'
				? v > 0
				: typeof v === 'string'
				? String(v).length > 0
				: true
		);

	const mentorQuestionnairesData =
		questionnairesData?.filter((questionnaire) => questionnaire.forMentorOnly === true) || undefined;
	const mentorQuestionnaireAnswers = mentorQuestionnairesData?.find(
		(questionnaire) => questionnaire.sessionType === currentSession.sessionType
	)?.questionnaireAnswers;
	const hasMentorQuestionnaireAnswers =
		mentorQuestionnaireAnswers &&
		Object.entries(mentorQuestionnairesData).every(([k, v]) =>
			// eslint-disable-next-line no-nested-ternary
			typeof v !== 'undefined' && typeof v === 'number'
				? v > 0
				: typeof v === 'string'
				? String(v).length > 0
				: true
		);

	const subpages: Subpage[] = [
		pair &&
			sessionAgenda && {
				key: 'agenda',
				to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/agenda`,
				label: t('userPanel.sessionsNew.agenda'),
				path: 'agenda',
				element: (
					<AgendaSubpage
						session={currentSession}
						pair={pair}
						sessionAgenda={sessionAgenda}
						prevSession={prevSession}
						nextSession={nextSession}
						handlers={{
							openAutoreflectionDialog,
						}}
					/>
				),
			},
		sessionDisplayableFiles.length > 0 && {
			key: 'materials',
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/materials`,
			label: t('userPanel.sessionsNew.materials.title'),
			badge: sessionDisplayableFiles.length,
			path: 'materials',
			element: <MaterialsSubpage files={sessionDisplayableFiles} />,
		},
		['middle', 'last'].includes(currentSession.sessionType) && {
			key: 'evaluation-mentee',
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/evaluation-mentee`,
			label: t('userPanel.sessionsNew.evaluation.forMentee'),
			iconRight: !hasMenteeQuestionnaireAnswers ? 'circle-exclamation' : undefined,
			iconRightStyle: 'solid',
			path: 'evaluation-mentee',
			element: (
				<EvaluationMenteeSubpage
					session={currentSession}
					questionnairesData={menteeQuestionnairesData}
					refetchQuestionnaires={() => getSessionQuestionnaires()}
				/>
			),
		},
		mentorQuestionnairesData &&
			type === 'mentor' &&
			['second', 'middle', 'last'].includes(currentSession.sessionType) && {
				key: 'evaluation-mentor',
				to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/evaluation-mentor`,
				label: t('userPanel.sessionsNew.evaluation.forMentor'),
				iconRight: !hasMentorQuestionnaireAnswers ? 'circle-exclamation' : undefined,
				iconRightStyle: 'solid',
				path: 'evaluation-mentor',
				element: (
					<EvaluationMentorSubpage
						session={currentSession}
						questionnairesData={mentorQuestionnairesData}
						refetchQuestionnaires={() => getSessionQuestionnaires()}
					/>
				),
			},
		{
			key: 'notes',
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/notes`,
			label: t('userPanel.sessionsNew.yourNotes.title'),
			badge: userFiles.length,
			path: 'notes',
			element: (
				<YourNotesSubpage
					session={currentSession}
					files={userFiles}
					getUserFilesRefetch={getUserFilesRefetch}
				/>
			),
		},
		!isLast && {
			key: 'homework',
			to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/homework`,
			label: t('userPanel.sessionsNew.homework.title'),
			path: 'homework',
			element: <HomeworkSubpage session={currentSession} saveHandler={homeworkSaveHandler} />,
		},
		currentProgram?.mentibotEnabled &&
			currentSession.sessionType !== 'first' && {
				key: 'ai',
				to: `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${currentSession.id}/recommendation`,
				label: t('userPanel.sessionsNew.aiRecommendations.title'),
				path: 'recommendation',
				element: <RecommendationAISubpage />,
				iconRight: 'wand-magic-sparkles',
				iconRightStyle: 'solid',
			},
	].filter(Boolean) as Subpage[];

	return (
		<>
			<Menu
				ref={menuRef}
				placement="bottom-end"
				items={[
					{
						key: 'edit',
						type: 'button',
						label: t('actions.edit'),
						onClick: () => setIsEditModalOpen(true),
					},
					// {
					// 	key: 'duplicate',
					// 	type: 'button',
					// 	label: 'Duplikuj',
					// 	onClick: () => {
					// 		console.log('duplicate');
					// 	},
					// },
					{
						key: 'remove',
						type: 'button',
						label: t('actions.remove'),
						className: 'text-danger',
						disabled: !canRemove,
						onClick: () => setRemoveSessionDialogConfirmOpen(true),
					},
				]}
			/>
			<SendMeetingCalendarDialog
				session={currentSession}
				visible={meetingCalendarDialogOpen}
				onHide={() => setMeetingCalendarDialogOpen(false)}
			/>
			<EditSessionDetailsDialog
				previousSession={prevSession}
				nextSession={nextSession}
				session={currentSession}
				visible={isEditModalOpen}
				onHide={() => setIsEditModalOpen(false)}
			/>
			<RemoveSessionDialogConfirm
				visible={removeSessionDialogConfirmOpen}
				onHide={() => setRemoveSessionDialogConfirmOpen(false)}
				confirmAction={{
					onClick: () => {
						deleteSession();
					},
				}}
			/>
			<ReflectionDialog
				size="lg"
				reflections={reflections}
				visible={autoreflectionModalOpen}
				onHide={() => setAutoreflectionModalOpen(false)}
				onSave={handleReflectionsSave}
				loading={updatingReflections}
			/>
			<Page
				beforeLeft={
					<Link
						to={`/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session`}
						variant="standard"
						icon="arrow-left"
						iconSet="fa"
						iconStyle="solid"
						noUnderline
						className="font-bold"
					>
						{t('userPanel.sessionsNew.backToSessionList')}
					</Link>
				}
				beforeRight={
					<div className="flex flex-row gap-3">
						<Link
							to={
								hasPrevSession
									? `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${
											sessionsData[currentSessionIndex - 1]?.id
									  }`
									: '#'
							}
							className="font-bold"
							icon="angle-left"
							iconSet="fa"
							iconStyle="solid"
							noUnderline
							disabled={!hasPrevSession}
							responsive="sm"
						>
							{t('userPanel.sessionsNew.prevSession')}
						</Link>
						<Line vertical />
						<Link
							to={
								hasNextSession
									? `/panel/${organizationName}/${programName}/${type}/pair/${pair.id}/session/${
											sessionsData[currentSessionIndex + 1]?.id
									  }`
									: '#'
							}
							className="font-bold"
							icon="angle-right"
							iconPosition="right"
							iconSet="fa"
							iconStyle="solid"
							noUnderline
							disabled={!hasNextSession}
							responsive="sm"
						>
							{t('userPanel.sessionsNew.nextSession')}
						</Link>
					</div>
				}
				header={
					<div className="flex flex-row justify-content-between align-items-center gap-2">
						<div className="flex flex-row gap-3 align-items-center">
							<AvatarSet
								className="hidden sm:inline-flex"
								avatars={[
									{
										name: userDisplayName(pair?.application.programMembership.user, null),
										src: pair?.application.programMembership.user.avatarActive
											? pair?.application.programMembership.user.avatar
											: undefined,
									},
									{
										name: userDisplayName(currentUser!, null),
										src: currentUser?.avatar ? currentUser?.avatar : undefined,
									},
								]}
							/>
							<h2 className="inline-block m-0">{`${t('userPanel.sessionsNew.session')} ${
								currentSession.sessionNumber
							}`}</h2>
							<Tag variant="grey" style={{ marginTop: '3px' }}>
								{prepareSessionTypeName(currentSession.sessionType)}
							</Tag>
						</div>
						<Button
							ref={menuButtonRef}
							onClick={() => {
								if (menuRef?.current && menuButtonRef?.current) {
									menuRef.current.toggle(menuButtonRef?.current);
								}
							}}
							label="Menu"
							icon="fa fa-solid fa-ellipsis"
							iconOnly
							rounded
							variant="primary-text"
						/>
					</div>
				}
			>
				<div className="flex flex-row flex-wrap gap-4 mb-2">
					<div className="w-full flex-3">
						<div className="flex flex-column gap-4">
							<div className="flex flex-column md:flex-row justify-content-start flex-1 gap-2">
								<div className="flex flex-row flex-1 bold md:py-4 px-2">
									{t('userPanel.sessionsNew.deadline')}
								</div>
								<div className="flex flex-column md:flex-row flex-3 align-items-center gap-2">
									<Box variant="blue" vertical full slim>
										<BoxSection
											className="min-w-0 text-nowrap sm:min-w-1"
											contentClassName="flex flex-row align-items-center gap-2"
										>
											<Button
												icon="calendar-day"
												iconOnly
												label={t('actions.edit')}
												variant="primary-text"
												variantSize="sm"
												onClick={() => setIsEditModalOpen(true)}
											/>
											<span>
												{currentSession.date
													? dateFormat(currentSession.date, 'dayAndMonthPlusYear')
													: '-'}
											</span>
										</BoxSection>
										<BoxSection
											className="text-nowrap ml-4 sm:ml-0"
											contentClassName="flex flex-row align-items-center gap-2"
										>
											<Button
												icon="clock"
												iconOnly
												label={t('actions.edit')}
												variant="primary-text"
												variantSize="sm"
												onClick={() => setIsEditModalOpen(true)}
											/>
											<span>
												{currentSession.date ? dateFormat(currentSession.date, 'hours') : '-'}
											</span>
										</BoxSection>
										<Button
											className="mr-3"
											icon="pen"
											iconOnly
											label={t('actions.edit')}
											variant="primary-text"
											variantSize="sm"
											onClick={() => setIsEditModalOpen(true)}
										/>
									</Box>
									{/* <Box variant="blue" vertical full slim className="flex flex-col min-w-0"> */}
									{/* 	<BoxSection */}
									{/* 		className="text-nowrap min-w-0" */}
									{/* 		contentClassName="flex flex-row align-items-center gap-2" */}
									{/* 	> */}
									{/* 		<Button */}
									{/* 			icon="calendar-day" */}
									{/* 			iconOnly */}
									{/* 			label={t('actions.edit')} */}
									{/* 			variant="primary-text" */}
									{/* 			variantSize="sm" */}
									{/* 			onClick={() => setIsEditModalOpen(true)} */}
									{/* 		/> */}
									{/* 		<span className="flex-1"> */}
									{/* 			{currentSession.date */}
									{/* 				? dateFormat(currentSession.date, 'dayAndMonthPlusYear') */}
									{/* 				: '-'} */}
									{/* 		</span> */}
									{/* 	</BoxSection> */}

									{/* 	<BoxSection */}
									{/* 		className="text-nowrap min-w-0" */}
									{/* 		contentClassName="flex flex-row align-items-center gap-2" */}
									{/* 	> */}
									{/* 		<div className="flex-shrink-0"> */}
									{/* 			<Button */}
									{/* 				icon="clock" */}
									{/* 				iconOnly */}
									{/* 				label={t('actions.edit')} */}
									{/* 				variant="primary-text" */}
									{/* 				variantSize="sm" */}
									{/* 				onClick={() => setIsEditModalOpen(true)} */}
									{/* 			/> */}
									{/* 		</div> */}
									{/* 		<span className="flex-1"> */}
									{/* 			{currentSession.date ? dateFormat(currentSession.date, 'hours') : '-'} */}
									{/* 		</span> */}
									{/* 	</BoxSection> */}

									{/* 	<BoxSection contentClassName="flex justify-end flex-shrink-0"> */}
									{/* 		<Button */}
									{/* 			icon="pen" */}
									{/* 			iconOnly */}
									{/* 			label={t('actions.edit')} */}
									{/* 			variant="primary-text" */}
									{/* 			variantSize="sm" */}
									{/* 			onClick={() => setIsEditModalOpen(true)} */}
									{/* 		/> */}
									{/* 	</BoxSection> */}
									{/* </Box> */}
									<div className="flex flex-column">
										{currentSession.date && dayjs().isAfter(currentSession.date) ? (
											// eslint-disable-next-line react/jsx-no-useless-fragment
											<>
												{currentSession.finished ? (
													<div className="flex flex-row gap-1 align-items-center text-success">
														<Icon name="check" />
														<span>{t('userPanel.sessionsNew.sessionIsFinished')}</span>
													</div>
												) : (
													<Button
														label={t('userPanel.sessionsNew.markAsFinished')}
														icon="info-circle"
														variant="primary-text"
														variantSize="sm"
														disabled={!currentSession.date}
														onClick={() => {
															updateSession({ finished: true });
														}}
													/>
												)}
											</>
										) : (
											<Button
												label={t('userPanel.sessionsNew.sendReminder')}
												icon="plus"
												variant="primary-text"
												variantSize="sm"
												disabled={!currentSession.date}
												onClick={() => setMeetingCalendarDialogOpen(true)}
											/>
										)}
									</div>
								</div>
							</div>
							{currentSession.link && dayjs().add(-2, 'hours').isBefore(currentSession.date) && (
								<div className="flex flex-column md:flex-row justify-content-start flex-1 gap-2">
									<div className="flex flex-row flex-1 bold md:py-4 px-2">
										{t('userPanel.sessions.meetingLinkLabelAgenda')}
									</div>
									<div className="flex flex-column md:flex-row flex-3 align-items-center gap-2">
										<Box variant="blue" full wrap slim>
											<BoxSection
												// className="text-nowrap"
												contentClassName="flex flex-row align-items-center gap-2 overflow-hidden"
												onClick={() => window.open(currentSession.link, '_blank')}
												style={{ cursor: 'pointer' }}
											>
												<Button
													variant="primary-text"
													variantSize="sm"
													label={t('userPanel.sessions.meetingLinkLabel')}
													icon="external-link"
													iconOnly
													onClick={() => window.open(currentSession.link, '_blank')}
												/>
												<div className="flex-1 text-elipsis">
													{t('userPanel.sessions.meetingLinkLabel')}
												</div>
											</BoxSection>
										</Box>
									</div>
								</div>
							)}
							<div className="flex flex-column md:flex-row justify-content-start flex-1 gap-2">
								<div className="flex flex-row flex-1 bold md:py-4 px-2">
									{t('userPanel.sessionsNew.purposeOfTheMeeting')}
								</div>
								<div className="flex flex-column md:flex-row flex-3 align-items-center gap-2">
									<Box variant="blue" full wrap slim>
										<BoxSection
											// className="text-nowrap"
											contentClassName="flex flex-row align-items-center gap-2 overflow-hidden"
										>
											<Button
												icon="pencil"
												iconOnly
												label={t('actions.edit')}
												variant="primary-text"
												variantSize="sm"
												onClick={() => setIsEditModalOpen(true)}
												className="flex-0"
											/>
											<div className="flex-1 text-elipsis">{currentSession.goal || '-'}</div>
										</BoxSection>
									</Box>
								</div>
							</div>
							<div className="flex flex-column md:flex-row justify-content-start flex-1 gap-2">
								<div className="flex flex-row flex-1 bold md:py-4 px-2">
									{t('userPanel.sessionsNew.selfReflection')}
								</div>
								<div className="flex flex-column md:flex-row flex-3 align-items-center gap-2">
									<Box variant="white-bordered" full wrap slim>
										{!hasReflections ? (
											<BoxSection contentClassName="flex flex-row gap-2">
												<Button
													label={t('userPanel.sessionsNew.reflection.fill')}
													icon="pencil"
													variant="primary-text"
													variantSize="sm"
													noPad
													onClick={() => setAutoreflectionModalOpen(true)}
												/>
											</BoxSection>
										) : (
											<>
												{reflections
													.filter(({ value }) =>
														reflectionsExpanded ? true : value && value.length > 0
													)
													.slice(0, reflectionsExpanded ? reflections.length : 2)
													.map(({ key, title, value }) => (
														<BoxSection key={key} header={title}>
															{value && value.length > 0 ? (
																<div className="text-elipsis-2">{value}</div>
															) : (
																<span className="text-muted">
																	{t('userPanel.sessionsNew.reflection.noAnswer')}
																</span>
															)}
														</BoxSection>
													))}
												<BoxSection contentClassName="flex flex-row gap-2">
													<Button
														label={
															reflectionsExpanded
																? t('userPanel.sessionsNew.reflection.collapse')
																: t('userPanel.sessionsNew.reflection.expand')
														}
														icon={reflectionsExpanded ? 'arrow-up' : 'arrow-down'}
														variant="primary-text"
														variantSize="sm"
														noPad
														onClick={toggleExpandReflections}
													/>
													<Button
														label={t('userPanel.sessionsNew.reflection.edit')}
														icon="pencil"
														variant="primary-text"
														variantSize="sm"
														noPad
														onClick={() => setAutoreflectionModalOpen(true)}
													/>
												</BoxSection>
											</>
										)}
									</Box>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-1 flex flex-column gap-4">
						{currentSession.sessionTasks?.length !== 0 && (
							<Box variant="white-bordered">
								<BoxSectionList header={t(`userPanel.sessions.doBeforeSession`)}>
									{items?.map((item) => (
										<div key={item.key}>
											<Link
												key={item.key}
												noUnderline
												icon={item?.icon}
												iconClassName="text-purple"
												variant="grey"
												to={item?.to}
											>
												<span>{item?.label}</span>
											</Link>
										</div>
									))}
								</BoxSectionList>
							</Box>
						)}
						<Tooltip
							target="#rate-session"
							content={t('userPanel.sessionsNew.rateSessionHint')}
							position="bottom"
							style={{ maxWidth: 220 }}
						/>
						<Box variant="white-bordered" id="rate-session">
							<BoxSection header={t('userPanel.sessionsNew.rateSession')}>
								<Rating value={currentSession.rating} onChange={handleRatingChange} />
							</BoxSection>
						</Box>
					</div>
				</div>

				<NavTabs
					tabs={subpages.map(({ key, to, label, badge, iconRight, iconRightStyle }) => ({
						key,
						to,
						label,
						badge,
						iconRight,
						iconRightStyle,
					}))}
					minusMargin
				/>

				<div id="content" style={{ minHeight: '500px' }}>
					{sessionAgendaFetched && (
						<Routes>
							<Route index element={<Navigate to={subpages[0].path} replace />} />
							{subpages.map(({ path, element }) => (
								<Route key={path} path={path} element={element} />
							))}
							<Route path="*" element={<Navigate to={subpages[0].path} replace />} />
						</Routes>
					)}
				</div>

				{/* <div className="flex justify-content-center mt-2">
					<Button
						onClick={() => {
							updateSession({ ...currentSession, finished: !currentSession.finished });
						}}
						loading={isSubmitting}
						label={
							isFinished
								? t('userPanel.sessions.markSessionAsUnfinished')
								: t('userPanel.sessions.markSessionAsFinished')
						}
						className={classNames({
							'p-button-outlined': isFinished,
						})}
					/>
				</div> */}

				{/* <div className="flex justify-content-between align-items-start mb-4">

							<SessionSharedFiles
								headerTitle={t('userPanel.sessions.sharedNotes')}
								session={currentSession}
							/>
						</div> */}
			</Page>
		</>
	);
};

export default SessionAgenda;
