import React, {
	ButtonHTMLAttributes,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ReactTagManager } from 'react-gtm-ts';
import { isPWASupported } from './utils';

const googleTagId = process.env.REACT_APP_GTM;

interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[];
	readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
	prompt: () => Promise<void>;
}

type InstallPromptButtonProps = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'disabled'>;
interface InstallPromptContextType {
	isInstallPromptAvailable: boolean;
	promptInstall: () => Promise<void>;
	isInstalled: boolean;
	buttonProps: InstallPromptButtonProps;
	isSupported: boolean;
	sendAnalitics: () => void;
}

const InstallPromptContext = createContext<InstallPromptContextType | undefined>(undefined);

const swEnabled = () => 'serviceWorker' in navigator;
export const InstallPromptProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [isInstallPromptAvailable, setIsInstallPromptAvailable] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
	const [isInstalled, setIsInstalled] = useState(false);

	const [isSupported, setIsSupported] = useState(false);

	useEffect(() => {
		setIsSupported(isPWASupported());

		if (window.matchMedia('(display-mode: standalone)').matches) {
			setIsInstalled(true);
		}

		const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
			event.preventDefault();
			setIsInstallPromptAvailable(true);
			setDeferredPrompt(event);
			setIsSupported(swEnabled);
		};

		const handleAppInstalled = (event: any) => {
			event.preventDefault();
			setIsInstalled(true);
			setIsInstallPromptAvailable(false);
			setDeferredPrompt(null);
		};

		window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
		window.addEventListener('appinstalled', handleAppInstalled as EventListener);

		return () => {
			window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as EventListener);
			window.removeEventListener('appinstalled', handleAppInstalled as EventListener);
		};
	}, []);

	const promptInstall = useCallback(async () => {
		if (!deferredPrompt) {
			console.warn('Deferred prompt not found');
		} else {
			deferredPrompt.prompt();
			const { outcome } = await deferredPrompt.userChoice;
			if (outcome === 'accepted') {
				setIsInstalled(true);
			}
			setDeferredPrompt(null);
			setIsInstallPromptAvailable(false);
		}
	}, [deferredPrompt]);

	const buttonProps = useMemo(
		() => ({
			onClick: promptInstall,
			disabled: !isInstallPromptAvailable || isInstalled,
		}),
		[isInstallPromptAvailable, isInstalled, promptInstall]
	);

	// do not check is supported when is installed
	const finalIsSupported = isInstalled || isSupported;

	const [analitics, setAnalitics] = useState(false);
	const sendAnalitics = useCallback(() => {
		if (!analitics && googleTagId) {
			ReactTagManager.action({
				event: 'pwa_button_show',
				pwaIsInstalled: isInstalled,
				pwaIsSupported: finalIsSupported,
				serviceWorkerSupport: 'serviceWorker' in navigator,
				pwaPromptIntercepted: Boolean(deferredPrompt),
			});
			setAnalitics(true);
		}
	}, [analitics, isInstalled, finalIsSupported, deferredPrompt]);

	return (
		<InstallPromptContext.Provider
			value={{
				isInstallPromptAvailable,
				promptInstall,
				isInstalled,
				buttonProps,
				isSupported: finalIsSupported,
				sendAnalitics,
			}}
		>
			{children}
		</InstallPromptContext.Provider>
	);
};

export const useInstallPrompt = () => {
	const context = useContext(InstallPromptContext);
	if (!context) {
		throw new Error('useInstall must be used within an InstallProvider');
	}
	return context;
};
