import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'locale';
import ReportIssues from 'components/_common/ReportIssues';
import PanelLayout from 'components/_layouts/panel/PanelLayout';
import { MenuItemExt, PanelMenu } from 'components/_common/PanelMenu';
import { border, colors } from 'theme';
import UserSettings from 'components/_common/UserSettings';
import OrganizationPanelContextProvider, { useOrganizationPanel } from 'contexts/organizationPanelContext';
import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'hooks/useNavigate';
import { Button } from 'components/_new/Button';
import { Divider } from 'components/_new/Divider';
import { useAuth } from 'contexts/authContext';
import { Dialog } from 'components/_new/Dialog';
import { useGlobal } from 'contexts/globalContext';
import { MyCalendarDialog } from 'components/myCalendar/MyCalendarDialog';
import { MyAccountDialog } from 'components/myAccount/MyAccountDialog';
import { ChangeAvatarDialog } from 'components/myAccount/components/ChangeAvatarDialog';
import { Organization } from 'types/Organization';

const OrganizationPanelComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate({
		scroll: ['scrollWrapperRef'],
	});

	const { currentUser } = useAuth();
	const {
		myOrganizations: { data: organizations },
		currentOrganization: { data },
	} = useOrganizationPanel();

	const {
		myAccountModalOpen,
		setMyAccountModalOpen,
		myCalendarModalOpen,
		setMyCalendarModalOpen,
		avatarModalIsOpen,
		setAvatarModalIsOpen,
	} = useGlobal();
	const [showOrgDialog, setShowOrgDialog] = useState(false);
	const [reportIssueModalIsOpen, setReportIssueModalIsOpen] = useState(false);
	const [visibleLeft, setVisibleLeft] = useState(false);

	const navigationOrganizationMapper = (organization: Organization): MenuItemExt =>
		({
			id: `organization-${organization.id}`,
			key: `organization-${organization.id}`,
			label: organization.displayName,
			items: [
				{
					id: `organization-${organization.id}-memberships`,
					key: `organization-${organization.id}-memberships`,
					label: t(`organizationPanel.organizationMemberships`),
					command: () => {
						navigate(`/organization/${organization.name}/memberships`);
					},
				},
				{
					id: `organization-${organization.id}-programs`,
					key: `organization-${organization.id}-programs`,
					label: t(`organizationPanel.organizationPrograms`),
					command: () => {
						navigate(`/organization/${organization.name}/program`);
					},
				},
			],
		} as unknown as MenuItemExt);
	const [showAll, setShowAll] = useState(false);
	const navigationOptions: MenuItemExt[] = useMemo(() => {
		if (organizations) {
			const hasInactiveOrganizations = organizations.some(({ stats }) => (stats?.programsInactive || 0) > 0);
			if (hasInactiveOrganizations && organizations?.length >= 5) {
				if (!showAll) {
					return [
						...organizations
							.filter((organization) => (organization.stats?.programsActive || 0) > 0)
							.map(navigationOrganizationMapper),
						{
							id: `show-all`,
							key: `show-all`,
							label: t('organizationPanel.showInactiveOrganizations'),
							command: () => {
								setShowAll(true);
							},
						},
					];
				}
				return [
					...organizations
						.filter((organization) => (organization.stats?.programsActive || 0) > 0)
						.map(navigationOrganizationMapper),
					...organizations
						.filter((organization) => !organization.stats?.programsActive)
						.map(navigationOrganizationMapper),
				];
			}
			return organizations.map(navigationOrganizationMapper);
		}
		return [];
		// return (organizations || []).map();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations, showAll]);

	const navigationBottomOptions: MenuItemExt[] = useMemo(
		() =>
			[
				data && {
					id: 'organizationPage',
					icon: <i className="pi pi-send" />,
					label: t('userPanel.navigation.programPage'),
					command: () => navigate(`/${data.name}`),
				},
				data && {
					id: 'organizationMemberships',
					icon: <i className="pi pi-users" />,
					label: t(`organizationPanel.organizationMemberships`),
					command: () => navigate(`/organization/${data.name}/memberships`),
				},
				{
					id: 'reportIssue',
					icon: <i className="pi pi-info-circle" />,
					label: t('userPanel.navigation.reportIssue'),
					command: () => setReportIssueModalIsOpen?.(true),
				},
			].filter(Boolean) as MenuItemExt[],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	);

	return (
		<>
			<Helmet title={t('misc.pageTitles.panel')} />

			<MyAccountDialog
				visible={myAccountModalOpen}
				onHide={() => {
					setMyAccountModalOpen(false);
				}}
			/>
			<ChangeAvatarDialog visible={avatarModalIsOpen} onHide={() => setAvatarModalIsOpen(false)} />
			<MyCalendarDialog
				visible={myCalendarModalOpen}
				onHide={() => {
					setMyCalendarModalOpen(false);
				}}
			/>

			<Dialog
				title={t('userPanel.navigation.reportIssue')}
				size="lg"
				onHide={() => setReportIssueModalIsOpen(false)}
				visible={reportIssueModalIsOpen}
			>
				<ReportIssues />
			</Dialog>
			<PanelLayout
				navigationOptions={navigationOptions}
				navigationTop={
					<div className="h-5rem p-2">
						<div className="flex flex-column align-items-end justify-content-center h-full w-full text-base text-right">
							<span>
								{t('userPanel.youAreIn')} <b className="capitalize">{t('organizationPanel.inTitle')}</b>
							</span>
						</div>
					</div>
				}
				navigationBottom={<PanelMenu model={navigationBottomOptions} multiple />}
				header={
					<>
						{data && (
							<>
								<Button
									icon="fa fa-bars fa-2x"
									iconOnly
									label="Toggle menu"
									onClick={() => setVisibleLeft(true)}
									variant="primary-text"
									className="mx-2 flex md:hidden"
								/>
								<Sidebar
									role="navigation"
									visible={visibleLeft}
									onHide={() => setVisibleLeft(false)}
									showCloseIcon={false}
									style={{
										backgroundColor: `${colors.purplishBlue}`,
										borderRadius: '0px 20px 20px 0px',
										maxWidth: '70%',
									}}
								>
									<PanelMenu
										model={navigationOptions}
										onHide={() => setVisibleLeft(false)}
										multiple
									/>
									<Divider margin={4} />
									<PanelMenu
										model={navigationBottomOptions}
										onHide={() => setVisibleLeft(false)}
										multiple
									/>
								</Sidebar>
								{data.logo && <img src={data.logo} alt="logo" className="max-h-3rem mr-3" />}
								<div
									className="h-3rem pl-3 hidden md:block"
									style={{ borderLeft: border(colors.lightGray) }}
								>
									<h3 className="mt-0 mb-1">{data.displayName}</h3>
									<small>{t('organizationPanel.yourOrganization')}</small>
								</div>
							</>
						)}

						{currentUser && (
							<div
								className="flex align-items-center h-3rem ml-auto pr-3 pl-4"
								style={{ borderLeft: border(colors.lightGray) }}
							>
								<UserSettings
									currentUser={currentUser}
									setShowOrgDialog={setShowOrgDialog}
									optionsLanguage={i18n.resolvedLanguage}
									source="panel"
								/>
							</div>
						)}
					</>
				}
			>
				<Outlet />
			</PanelLayout>
		</>
	);
};

const OrganizationPanel = () => (
	<OrganizationPanelContextProvider>
		<OrganizationPanelComponent />
	</OrganizationPanelContextProvider>
);

export default OrganizationPanel;
