/* eslint-disable no-nested-ternary */
import React, { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Box, BoxSectionList } from 'components/_new/Box';
import { Button } from 'components/_new/Button';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, UseControllerProps, UseFormReturn } from 'react-hook-form';
import { InputText } from 'components/_new/InputText';
import { InputNumber } from 'components/_common/forms/InputNumber';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { dateFormat } from 'utils/dateFormat';
import { Dropdown, DropdownItem } from 'components/_new/Dropdown';
import { InputTextArea } from 'components/_new/InputTextArea';
import { MarkdownEditor } from 'components/_common/MarkdownEditor/MarkdownEditor';
import { To, useNavigate } from 'react-router-dom';
import { FileUpload } from 'primereact/fileupload';
import { clearInput } from 'utils/clearInput';

const editModeCtx = React.createContext({ editMode: false, isLoading: false });
const EditModeCtxProvider = editModeCtx.Provider;
const useEditMode = () => useContext(editModeCtx);

type PanelItemDisplayValue = 'date-only' | 'on/off' | 'yes/no' | 'active/unactive';
type ValueTransformHandler<V> = (transform: { value?: V; editMode: boolean; label?: JSX.Element }) => ReactNode;

interface PanelItemProps<T extends FieldValues, V> {
	title?: string;
	value?: V;
	transformValue?: PanelItemDisplayValue | ValueTransformHandler<V>;
	type?: 'string' | 'string-long' | 'string-editor' | 'number' | 'date' | 'boolean' | 'select' | 'image';
	className?: string;
	controller?: UseControllerProps<T>;
	clearable?: boolean;
	inputTextProps?: {
		maxLength?: number;
	};
	inputNumberProps?: {
		min?: number;
		max?: number;
		step?: number;
	};
	inputCheckboxProps?: {
		invertValue?: boolean;
	};
	inputDropdownProps?: {
		options?: DropdownItem[];
		noOptionsText?: string;
	};
	withEmptyText?: boolean | 'default';
	editable?: boolean;
	bold?: boolean;
	visibleOnlyInEditMode?: boolean;
	large?: boolean;
	children?: ReactNode[] | ReactNode;
}

export const PanelItem = <T extends FieldValues, V>({
	title,
	value = undefined,
	transformValue,
	type = 'string',
	className,
	controller,
	clearable = false,
	inputTextProps,
	inputNumberProps,
	inputCheckboxProps,
	inputDropdownProps,
	withEmptyText = false,
	editable = false,
	bold = false,
	visibleOnlyInEditMode = false,
	large = false,
	children,
}: PanelItemProps<T, V>) => {
	const { t } = useTranslation();
	const { editMode, isLoading } = useEditMode();

	const emptyText = useMemo(() => {
		if (withEmptyText === 'default') {
			return t('misc.default');
		}
		return t('misc.empty');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withEmptyText]);

	const displayValueAsOnOff = useCallback((val: boolean) => {
		const text = val ? t('misc.on-off.on') : t('misc.on-off.off');
		return val ? (
			<span className="bold text-green-400 no-break">
				{text}
				<i className="ml-1 fa fa-regular fa-circle-check" />
			</span>
		) : (
			<span className="bold text-muted no-break">{text}</span>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayValueAsActiveUnactive = useCallback((val: boolean) => {
		const text = val ? t('misc.active-unactive.active') : t('misc.active-unactive.unactive');
		return val ? (
			<span className="bold text-green-400 no-break">
				{text}
				<i className="ml-1 fa fa-regular fa-circle-check" />
			</span>
		) : (
			<span className="bold text-danger no-break">
				{text}
				<i className="ml-1 fa fa-regular fa-circle-xmark" />
			</span>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayValueAsYesNo = useCallback((val: boolean) => {
		const text = val ? t('misc.yes-no.yes') : t('misc.yes-no.no');
		return val ? <span className="bold text-green-400">{text}</span> : <span className="bold">{text}</span>;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayValueFromOptionLabel = useCallback(
		(val: any) => {
			const options = inputDropdownProps?.options || [];
			const label = options.find((item) => item.value === val)?.label;
			return label ? <span>{String(label)}</span> : undefined;
		},
		[inputDropdownProps?.options]
	);

	const transformedValue: ReactNode | undefined = useMemo(() => {
		if (type === 'select') {
			const label = value ? displayValueFromOptionLabel(value) : undefined;
			if (typeof transformValue === 'function') {
				return transformValue({ value, editMode, label });
			}
			return label;
		}
		if (type === 'image') {
			if (typeof value === 'string') {
				return <img src={value} className="upload-preview-image logotype" alt="logotype" />;
			}
			return null;
		}
		switch (transformValue) {
			case 'date-only':
				return value ? dateFormat(value as string, 'date') : undefined;
			case 'on/off':
				return displayValueAsOnOff(value as boolean);
			case 'active/unactive':
				return displayValueAsActiveUnactive(value as boolean);
			case 'yes/no':
				return displayValueAsYesNo(value as boolean);
			default:
				if (typeof transformValue === 'function') {
					return transformValue({ value, editMode });
				}
				return typeof value === 'undefined' || value === null ? (
					''
				) : (
					<span className="text-right">{String(value)}</span>
				);
		}
	}, [
		type,
		transformValue,
		value,
		displayValueFromOptionLabel,
		displayValueAsOnOff,
		displayValueAsActiveUnactive,
		displayValueAsYesNo,
		editMode,
	]);

	const displayValue: ReactNode = useMemo(() => {
		if (withEmptyText) {
			return transformedValue ? (
				// eslint-disable-next-line prettier/prettier
				<span className={bold ? 'bold' : ''}>{transformedValue}</span>
			) : (
				<span className="text-muted">{emptyText}</span>
			);
		}
		return transformedValue ? <span className={bold ? 'bold' : ''}>{transformedValue}</span> : undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withEmptyText, transformedValue]);

	const inputFileRef = useRef<HTMLInputElement>(null);
	const [previewImage, setPreviewImage] = useState<string | null>(null);

	const renderController = () => {
		if (controller) {
			const { control, name } = controller;
			return (
				<Controller
					control={control}
					name={name}
					render={({ field }) => {
						switch (type) {
							case 'string':
								return <InputText {...field} disabled={isLoading} {...inputTextProps} />;
							case 'string-long':
								return <InputTextArea {...field} disabled={isLoading} {...inputTextProps} />;
							case 'string-editor':
								// eslint-disable-next-line no-case-declarations
								const defaultValue = value && String(value).length > 0 ? String(value) : undefined;
								return <MarkdownEditor defaultValue={defaultValue} {...field} />;
							case 'date':
								return (
									<>
										{clearable && (
											<Button
												label={t('actions.delete')}
												onClick={() => {
													field?.onChange('0000-00-00');
													setTimeout(() => {
														field?.onChange('');
													}, 500);
												}}
												icon="trash"
												iconOnly
												variant="danger-text"
												variantSize="sm"
											/>
										)}
										<InputText
											{...field}
											type="date"
											value={field.value || '0000-00-00'}
											onChange={(event) => {
												const date = new Date(event.currentTarget.value)
													.toISOString()
													.split('T')[0];
												field?.onChange(date);
											}}
											inline
											disabled={isLoading}
										/>
									</>
								);
							case 'boolean':
								// eslint-disable-next-line no-case-declarations
								const { invertValue, ...restInputCheckboxProps } = inputCheckboxProps || {};
								return (
									<InputSwitch
										checked={invertValue ? !field.value : field.value}
										onChange={(event) => {
											const newValue = invertValue ? !event.target.value : event.target.value;
											field.onChange(newValue);
											// setValue(fieldName, newValue); // Set the form value manually
										}}
										disabled={isLoading}
									/>
								);
							case 'number':
								return (
									<InputNumber
										{...field}
										onChange={({ value: newValue }) => {
											field?.onChange(newValue);
										}}
										inputId="minmax-buttons"
										mode="decimal"
										showButtons
										{...inputNumberProps}
										inputClassName="w-4rem py-0"
										style={{ width: 'auto' }}
										disabled={isLoading}
									/>
								);
							case 'select':
								// eslint-disable-next-line no-case-declarations
								const { options, noOptionsText } = inputDropdownProps || {
									options: [],
									noOptionsText: emptyText,
								};
								return (
									<>
										{!options?.length && <span className="text-muted">{noOptionsText}</span>}
										{options && options?.length > 0 && (
											<Dropdown
												{...field}
												options={options}
												withEmpty={withEmptyText ? Boolean(withEmptyText) : undefined}
												disabled={isLoading}
											/>
										)}
									</>
								);
							case 'image':
								return (
									<div className="flex flex-column gap-2 align-items-end">
										<div className="flex flex-row gap-1">
											<InputText
												ref={inputFileRef}
												type="file"
												accept={[
													'image/jpeg',
													'image/png',
													'image/gif',
													'image/bmp',
													'image/svg+xml',
												].join(',')}
												onChange={(event) => {
													const file = event.currentTarget.files?.[0];
													if (file) {
														const imageUrl = URL.createObjectURL(file);
														setPreviewImage(imageUrl);
														field?.onChange(file);
													}
												}}
											/>
											{previewImage && (
												<Button
													label={t('actions.delete')}
													onClick={() => {
														if (inputFileRef?.current) {
															clearInput(inputFileRef.current);
														}

														setPreviewImage(null);
													}}
													icon="trash"
													iconOnly
													variant="danger-text"
													variantSize="sm"
												/>
											)}
										</div>

										{previewImage && (
											<img
												src={previewImage}
												className="upload-preview-image logotype"
												alt="preview"
											/>
										)}
									</div>
								);
							default:
								return <div />;
						}
					}}
				/>
			);
		}

		if (editable) {
			if (typeof transformValue === 'function') {
				return transformValue({ value, editMode });
			}
		}

		return null;
	};

	const renderChildren = () => {
		if (children) {
			if (Array.isArray(children)) {
				return children.map((node, i) => {
					const key = `node-${i}`;
					return (
						<div key={key} className="text-right" style={{ flex: '22%' }}>
							{node}
						</div>
					);
				});
			}
			return children;
		}
		return null;
	};

	if (visibleOnlyInEditMode && !editMode) {
		return null;
	}

	const finalEditable = Boolean(controller) || editable;
	return (
		<div
			className="flex flex-row justify-space-between align-items-center flex-wrap gap-2 overflow-hidden"
			style={{ minHeight: '2rem' }}
		>
			{title && (
				<div
					className={editMode ? (!finalEditable ? 'text-muted' : '') : ''}
					style={{ flex: 3, minWidth: 150 }}
				>
					{title}
				</div>
			)}
			<div
				className={classNames(
					'flex flex-row gap-2 justify-content-end text-right',
					{ 'text-lg': large },
					className
				)}
				style={{ minWidth: 120 }}
			>
				{!editMode && (displayValue || renderChildren())}
				{editMode && <div className="flex flex-row align-items-center gap-2">{renderController()}</div>}
			</div>
		</div>
	);
};

type PanelProps<T extends FieldValues> = {
	title: string;
	editModeState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	onSave?: (values: T) => void;
	form?: UseFormReturn<T, object>;
	isLoading?: boolean;
	staticEdit?: To;
	children: ReactNode | ReactNode[];
};

export const Panel = <T extends FieldValues>({
	title,
	editModeState,
	onSave,
	form,
	isLoading = false,
	staticEdit,
	children,
}: PanelProps<T>) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { handleSubmit, reset } = form || {};

	const editMode = editModeState?.[0] || false;
	const setEditMode = editModeState?.[1] || (() => undefined);
	const handleEditClick = useCallback(() => {
		setEditMode(true);
	}, []);
	const handleCancelClick = useCallback(() => {
		setEditMode(false);
		reset?.();
	}, []);
	const handleSubmitForm = handleSubmit?.((values) => {
		onSave?.(values);
	});

	return (
		<form onSubmit={handleSubmitForm}>
			<div className="flex flex-row justify-content-between align-items-center">
				<h2>{title}</h2>
				{staticEdit && (
					<Button
						label={t('actions.edit')}
						onClick={() => {
							navigate(staticEdit);
						}}
						icon="pencil"
						iconOnly
						variant="primary-outlined"
					/>
				)}
				{editModeState && (
					<div className="flex flex-row gap-2">
						{!editMode && (
							<Button
								label={t('actions.edit')}
								onClick={handleEditClick}
								icon="pencil"
								iconOnly
								variant="primary-outlined"
							/>
						)}
						{editMode && (
							<>
								<Button
									label={t('actions.cancel')}
									onClick={handleCancelClick}
									variant="primary-text"
									variantSize="sm"
								/>
								<Button
									submit
									label={t('actions.save')}
									icon="floppy-disk"
									variant="primary"
									variantSize="sm"
									loading={isLoading}
								/>
							</>
						)}
					</div>
				)}
			</div>
			<EditModeCtxProvider value={{ editMode, isLoading }}>
				<Box variant="white-bordered">
					<BoxSectionList itemClassName="slim py-2">{children}</BoxSectionList>
				</Box>
			</EditModeCtxProvider>
		</form>
	);
};
