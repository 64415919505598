/* eslint-disable no-console */
import axios from 'axios';

export const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
	baseURL: apiUrl,
	timeout: 15000,
});

axiosInstance.interceptors.response.use((response) => response.data);

export const httpClient = {
	get: async <T>(url: string, params?: any, options?: any): Promise<T> =>
		axiosInstance.get(url, { params, ...options }),

	post: async <T = void>(url: string, payload?: any, options?: any): Promise<T> =>
		axiosInstance.post(url, payload, { ...options }),

	patch: async <T = void>(url: string, payload?: any): Promise<T> => axiosInstance.patch(url, payload),

	put: async <T = void>(url: string, payload?: any): Promise<T> => axiosInstance.put(url, payload),

	delete: async <T = void>(url: string, payload?: any): Promise<T> => axiosInstance.delete(url, payload),

	subscribe: async <T>(url: string, params?: any, options?: any): Promise<T> =>
		axiosInstance.get(url, {
			params,
			httpsAgent: { keepAlive: true, timeout: 25000 },
			timeout: 30000,
			validateStatus: (status) => status >= 200 && status <= 204,
			...options,
		}),
};

type ObjectToFormDataOptions = {
	namespace?: string;
	skipUndefined?: boolean;
};

export function objectToFormData(
	obj: Record<string, any>,
	formDataObj: FormData | null = null,
	_options?: ObjectToFormDataOptions
): FormData {
	const options: ObjectToFormDataOptions = {
		namespace: undefined,
		skipUndefined: false,
		...(_options || {}),
	};
	const { namespace, skipUndefined } = options;

	// eslint-disable-next-line no-param-reassign
	let formData = formDataObj || new FormData();
	// eslint-disable-next-line no-restricted-syntax, guard-for-in
	for (const property in obj) {
		const value = obj[property];
		if (skipUndefined && (typeof value === 'undefined' || value === 'undefined')) {
			// eslint-disable-next-line no-continue
			continue;
		}
		if (!Object.prototype.hasOwnProperty.call(obj, property)) {
			// eslint-disable-next-line no-continue
			continue;
		}

		const key = namespace ? `${namespace}[${property}]` : property;
		if (value instanceof Date) {
			formData.append(key, value.toISOString());
		} else if (typeof value === 'object' && !(value instanceof File)) {
			objectToFormData(value, formData, { namespace: key, skipUndefined });
		} else if (Array.isArray(value)) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < value.length; i++) {
				const arrayKey = `${key}[${i}]`;
				if (typeof value[i] === 'object' && !(value[i] instanceof File)) {
					formData = objectToFormData(value[i], formData, { namespace: arrayKey, skipUndefined });
				} else {
					formData.append(arrayKey, value[i]);
				}
			}
		} else {
			formData.append(key, value);
		}
	}
	return formData;
}
