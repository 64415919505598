import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { postsApi } from 'api';
import SubPageTitle from 'components/_common/panel/SubPageTitle';
import { useProgramPanel } from 'contexts/programPanelContext';
import { Button } from 'components/_common/Button';
import { Post } from 'types/Dashboard';
import { Spinner } from 'components/_new/Spinner';
import { Paginated } from 'types/Paginated';
import { DialogConfirm } from 'components/_new/Dialog';
import NotificationPost from '../wall/NotificationPost';

export const ProgramPostList = () => {
	const { t } = useTranslation();
	const { data: program } = useProgramPanel();
	const navigate = useNavigate();
	const postsPerLoad = 8;

	const queryClient = useQueryClient();

	const { data, fetchNextPage, isLoading, isFetchingNextPage, isSuccess, hasNextPage } = useInfiniteQuery(
		[
			'getProgramAdminPosts',
			{
				programId: Number(program?.id),
			},
		],
		({ pageParam }) =>
			postsApi.getPostsAdmin({
				programId: Number(program?.id),
				params: { page: pageParam || 1, pageSize: postsPerLoad },
			}),
		{
			onSuccess: ({ pages }) => pages,
			enabled: true,
			keepPreviousData: true,
			getNextPageParam: ({ pagination }) => {
				const nextPage = pagination.currentPage + 1;
				if (nextPage > pagination.itemsPerPage) {
					return false;
				}
				return nextPage;
			},
		}
	);

	const [deletedPosts, setDeletedPosts] = useState<number[]>([]);
	const posts = useMemo(
		() =>
			(data?.pages || [])
				.reduce<Post[]>((current, item) => {
					return [...current, ...item.data];
				}, [])
				.filter((post) => !deletedPosts.includes(post.id)),
		[data, deletedPosts]
	);

	const { mutate: deletePost } = useMutation(['deleteProgramAdminPost'], postsApi.deletePostAdmin, {
		onSuccess: (data, variables) => {
			setDeletedPosts((prev) => [...prev, variables.postId]);
			setPostToDelete(null);
			queryClient.setQueriesData<InfiniteData<Paginated<Post>>>(
				[
					'getProgramAdminPosts',
					{
						programId: Number(program?.id),
					},
				],
				(data) => {
					if (data) {
						return {
							...data,
							pages: data?.pages.map((page) => ({
								...page,
								data: page.data.filter((post) => post.id !== variables.postId),
							})),
						};
					}
					return data;
				}
			);
		},
	});

	const [postToDelete, setPostToDelete] = useState<any | null>(null);
	const [deletePostDialogOpen, setDeletePostDialogOpen] = useState(false);

	const handleConfirmDeletePost = () => {
		if (program && postToDelete) {
			deletePost({
				postId: postToDelete.id,
			});
		}
	};

	return (
		<>
			<Helmet title={t('organizationPanel.programCommunication')} />
			<SubPageTitle
				title={t('organizationPanel.programCommunication')}
				additional={program?.displayName}
				right={
					<Link
						to={`/program/${program?.name}/communication/add`}
						className="p-button p-button-text p-button-sm p-0"
					>
						{t('programPanel.post.addNew')}
					</Link>
				}
			/>

			{isLoading && <Spinner />}
			{!isLoading && isSuccess && posts && posts.length === 0 && <p> {t('programPanel.post.noPosts')}</p>}
			{isSuccess &&
				posts &&
				posts.length > 0 &&
				posts.map((post) => (
					<div key={post.id} className="flex flex-column">
						<NotificationPost
							key={post.id}
							postData={post}
							withComments
							adminMode
							getPostCommentsRequest={({ postId, pageParam }) => {
								return postsApi.getPostCommentsAdmin({
									postId,
									params: {
										page: pageParam[0] || 1,
										limit: 3,
									},
								});
							}}
							addPostCommentRequest={({ postId, payload }) => {
								return postsApi.addPostCommentAdmin({ postId, payload });
							}}
						/>
						<div className="flex flex-row justify-content-end gap-2">
							<Button
								size="sm"
								onClick={() => {
									navigate(`/program/${program?.name}/communication/edit/${post.id}`);
								}}
								label={t('actions.edit')}
							/>
							<Button
								size="sm"
								onClick={() => {
									setPostToDelete(post);
									setDeletePostDialogOpen(true);
								}}
								label={t('actions.remove')}
								className="p-button-danger"
							/>
						</div>
					</div>
				))}
			{hasNextPage && (
				<div className="flex justify-content-center">
					<Button label={t('misc.loadMore')} onClick={() => fetchNextPage()} loading={isFetchingNextPage} />
				</div>
			)}
			<DialogConfirm
				visible={deletePostDialogOpen}
				onHide={() => setDeletePostDialogOpen(false)}
				message={t('programPanel.post.confirmRemove')}
				onConfirm={handleConfirmDeletePost}
			/>
		</>
	);
};
