import React, { PropsWithChildren } from 'react';
import { ChatDialog } from './ChatDialog';
import { ChatContextProvider } from './ChatContextProvider';

// eslint-disable-next-line @typescript-eslint/ban-types
type ChatContainerProps = PropsWithChildren<{}>;

export const ChatContainer = ({ children }: ChatContainerProps) => {
	return (
		<ChatContextProvider>
			<ChatDialog />
			{children}
		</ChatContextProvider>
	);
};
