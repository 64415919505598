import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useOrganizationPanel } from 'contexts/organizationPanelContext';
import { Box, BoxSection } from 'components/_new/Box';
import { Button } from 'primereact/button';
import { Spinner } from 'components/_new/Spinner';

export const OrganizationList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		myOrganizations: { data, isSuccess, isLoading },
	} = useOrganizationPanel();

	if (isLoading) {
		return <Spinner center />;
	}
	if (isSuccess && !isLoading && Array.isArray(data) && data.length > 0) {
		return (
			<div>
				<ul className="p-list-unstylled flex flex-column gap-2">
					{isSuccess &&
						(data || []).map((organization) => (
							<li key={organization.id}>
								<Box variant="white-bordered">
									<BoxSection>
										<h2>{organization.displayName}</h2>
										<div className="flex gap-2 justify-content-end">
											<Link
												to={`/organization/${organization.name}`}
												className="p-button p-button-primary"
											>
												{t('organizationPanel.goToOrganization')}
											</Link>
											<Link
												to={`/organization/${organization.name}/memberships`}
												className="p-button p-button-text"
											>
												{t('organizationPanel.organizationMemberships')}
											</Link>
										</div>
									</BoxSection>
								</Box>
							</li>
						))}
				</ul>
			</div>
		);
	}

	return (
		<div className="flex flex-column align-items-center">
			<p>{t('organizationPanel.noAdminAccess')}</p>
			<Button className="mt-3" onClick={() => navigate('/')}>
				{t('navigation.returnToHomePage')}
			</Button>
		</div>
	);
};
