import React from 'react';
import { Box, BoxSection } from 'components/_new/Box';
import './StatsItem.scss';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

type StatColorVariant = 'lpurplish' | 'purplish' | 'rose';

type ProgressBarProps = { current: number; variant?: StatColorVariant };

const ProgressBar = ({ current, variant }: ProgressBarProps) => {
	const width = `${current}%`;
	return (
		<div
			className={classNames('stats-progress', {
				'stats-progress-rose': variant === 'rose',
				'stats-progress-purplish': variant === 'purplish',
				'stats-progress-lpurplish': variant === 'lpurplish',
			})}
		>
			<div className="stats-progress-bar" style={{ width }} />
		</div>
	);
};

type StatsProgressProps = StatsItemProps & { progressValue?: number; positivePercValue?: number };

export const StatsItemProgress = ({ name, value, progressValue, positivePercValue, variant }: StatsProgressProps) => {
	const { t } = useTranslation();
	return (
		<Box
			variant="white-bordered"
			className={classNames('stats-item', {
				'stats-item-rose': variant === 'rose',
				'stats-item-purplish': variant === 'purplish',
				'stats-item-lpurplish': variant === 'lpurplish',
			})}
			slim
		>
			<BoxSection className="">
				<ProgressBar current={progressValue || Number(value) || 0} variant={variant} />
			</BoxSection>
			<BoxSection contentClassName="gap-1">
				<div className="flex flex-row gap-3 justify-items-center align-items-center">
					<div className="num">{value}</div>
					<div>{name}</div>
				</div>
				{positivePercValue && (
					<div className="perc">
						{t('programPanel.statistics.avgMenteeGoalsAchievementPositivePerc', {
							count: positivePercValue,
						})}
					</div>
				)}
			</BoxSection>
		</Box>
	);
};

type StatsItemProps = {
	name: string;
	value?: string | number | null;
	variant?: StatColorVariant;
};

export const StatsItem = ({ name, value, variant }: StatsItemProps) => {
	return (
		<Box
			variant="white-bordered"
			className={classNames('stats-item', {
				'stats-item-rose': variant === 'rose',
				'stats-item-purplish': variant === 'purplish',
				'stats-item-lpurplish': variant === 'lpurplish',
			})}
			slim
		>
			<BoxSection contentClassName="num">{value}</BoxSection>
			<BoxSection className="">{name}</BoxSection>
		</Box>
	);
};
