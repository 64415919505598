import React, { ReactNode } from 'react';

import { classNames } from 'primereact/utils';

interface CustomLabelProps {
	name: string;
	label?: string | ReactNode;
	children?: ReactNode;
	required?: boolean;
	inline?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

const CustomLabel = ({ name, label, children, required, inline, className, style }: CustomLabelProps) => (
	<label
		htmlFor={name}
		className={classNames({ 'block px-3': !inline, className })}
		style={{
			...style,
		}}
	>
		{label || children}
		{required && <span className="text-red-500">*</span>}
	</label>
);

export default CustomLabel;
