import React, { useState, useEffect, useId, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { InputTextArea } from '../../_new/InputTextArea';
import { InputText } from '../../_new/InputText';

type MultipleChoiceEditWrapperProps = {
	initialItems: string[];
	initialLabel: string;
	initialText: string;
	required: boolean;
	hiddenFromUsers: boolean;
	minAnswers: number;
	maxAnswers: number;
	onSave: (
		items: string[],
		label: string,
		text: string,
		required: boolean,
		hiddenFromUsers: boolean,
		min: number,
		max: number
	) => void;
	onCancel: () => void;
};

interface EditFormObject {
	option: string;
	questionLabel: string;
	questionText: string;
	isRequired: boolean;
	hiddenFromUsers: boolean;
	minAnswers: number;
	maxAnswers: number;
}

export const MultipleChoiceEditWrapper: React.FC<MultipleChoiceEditWrapperProps> = ({
	initialItems,
	initialText,
	initialLabel,
	required,
	hiddenFromUsers,
	minAnswers,
	maxAnswers,
	onSave,
	onCancel,
}) => {
	const { t } = useTranslation();
	const [items, setItems] = useState<string[]>(initialItems);
	const [editing, setEditing] = useState<number | null>(null);
	const requiredCheckboxId = useId();
	const hiddenFromUsersCheckboxId = useId();
	const { control, handleSubmit, reset, setValue } = useForm<EditFormObject>({
		defaultValues: {
			questionLabel: initialLabel,
			questionText: initialText,
			isRequired: required,
			hiddenFromUsers,
			minAnswers,
			maxAnswers,
		},
	});

	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		setValue('questionLabel', initialLabel);
		setValue('questionText', initialText);
		setValue('isRequired', required);
		setValue('hiddenFromUsers', hiddenFromUsers);
		setValue('minAnswers', minAnswers);
		setValue('maxAnswers', maxAnswers);
	}, [initialLabel, initialText, required, hiddenFromUsers, minAnswers, maxAnswers, setValue]);

	const handleAddOption = (e: React.MouseEvent) => {
		e.preventDefault();
		if (editing !== null) return; // Prevent adding a new option if one is being edited
		setItems((prevItems) => [...prevItems, '']);
		setEditing(items.length);
		setValue('option', '');
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleEditOption = (index: number) => {
		const option = items[index];
		setEditing(index);
		setValue('option', option);
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleDeleteOption = (index: number) => {
		setItems((prevItems) => prevItems.filter((_, i) => i !== index));
		setEditing(null);
		setValue('option', '');
	};

	const handleFormSubmit = (data: EditFormObject) => {
		const trimmedOption = data.option.trim();
		setItems((prevItems) => {
			const updatedItems = [...prevItems];
			if (editing !== null) {
				updatedItems[editing] = trimmedOption;
			}
			return updatedItems;
		});
		setEditing(null);
		setValue('option', '');

		// Automatically add a new option and focus on it
		setItems((prevItems) => [...prevItems, '']);
		setEditing(items.length);
		setValue('option', '');
		setTimeout(() => inputRef.current?.focus(), 0);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleSubmit(handleFormSubmit)();
		}
	};

	const handleSave = (data: EditFormObject) => {
		onSave(
			items,
			data.questionLabel,
			data.questionText,
			data.isRequired,
			data.hiddenFromUsers,
			data.minAnswers,
			data.maxAnswers
		);
	};

	return (
		<div>
			<div className="field mt-2">
				<Controller
					name="questionText"
					control={control}
					rules={{ required: t('programPanel.editQuestion.questionTextIsRequired') }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="questionText">
								{t('programPanel.editQuestion.questionContent')}
							</label>
							<InputText
								{...field}
								placeholder={t('programPanel.editQuestion.enterQuestionTextPlaceholder')}
							/>
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			<div className="field mt-2">
				<Controller
					name="questionLabel"
					control={control}
					// rules={{ required: 'Question label is required' }}
					render={({ field, fieldState }) => (
						<>
							<label className="ml-2 field-label" htmlFor="questionLabel">
								{t('programPanel.editQuestion.additionalInformations')}
							</label>
							<InputTextArea
								{...field}
								placeholder={t('programPanel.editQuestion.enterQuestionLabelPlaceholder')}
							/>
							{fieldState.error && <small className="p-error">{fieldState.error.message}</small>}
						</>
					)}
				/>
			</div>
			<div>
				<div className="mt-2">
					<Controller
						name="isRequired"
						control={control}
						render={({ field }) => (
							<Checkbox
								id={requiredCheckboxId}
								{...field}
								inputId="required-checkbox"
								checked={field.value}
								onChange={(e) => setValue('isRequired', e.checked)}
							/>
						)}
					/>
					<label htmlFor={requiredCheckboxId} className="ml-2">
						{t('programPanel.editQuestion.isRequired')}
					</label>
				</div>
				<div className="mt-2 mb-2">
					<Controller
						name="hiddenFromUsers"
						control={control}
						render={({ field }) => (
							<Checkbox
								id={hiddenFromUsersCheckboxId}
								{...field}
								inputId="hiddenFromUsers-checkbox"
								checked={field.value}
								onChange={(e) => setValue('hiddenFromUsers', e.checked)}
							/>
						)}
					/>
					<label className="ml-2 field-label" htmlFor={hiddenFromUsersCheckboxId}>
						{t('programPanel.editQuestion.hiddenFromUsers')}
					</label>
				</div>
			</div>
			<div className="flex gap-2">
				<div className="field mb-3">
					<label htmlFor="min-answers" className="mr-2">
						{t('programPanel.editQuestion.minAnswers')}
					</label>
					<Controller
						name="minAnswers"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								inputId="min-answers"
								onChange={({ value: newValue }) => {
									field?.onChange(newValue);
								}}
								mode="decimal"
								showButtons
								min={0}
								max={maxAnswers}
								step={1}
								inputClassName="w-4rem py-0"
							/>
						)}
					/>
				</div>
				<div className="field mb-3">
					<label htmlFor="max-answers" className="mr-2">
						{t('programPanel.editQuestion.maxAnswers')}
					</label>
					<Controller
						name="maxAnswers"
						control={control}
						render={({ field }) => (
							<InputNumber
								{...field}
								inputId="max-answers"
								onChange={({ value: newValue }) => {
									field?.onChange(newValue);
								}}
								mode="decimal"
								showButtons
								min={minAnswers}
								max={100}
								step={1}
								inputClassName="w-4rem py-0"
							/>
						)}
					/>
				</div>
			</div>
			<ul className="list-none ml-2">
				{items.map((item, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<li key={`${initialLabel}-${item}-${index}`}>
						{editing === index ? (
							<div className="flex align-items-center gap-2">
								<Controller
									name="option"
									control={control}
									rules={{ required: t('programPanel.editQuestion.optionIsRequired') }}
									render={({ field, fieldState }) => (
										<>
											<InputText
												{...field}
												placeholder={t('programPanel.editQuestion.enterOptionPlaceholder')}
												onKeyDown={handleKeyDown}
												ref={inputRef}
											/>
											{fieldState.error && (
												<small className="p-error">{fieldState.error.message}</small>
											)}
											<Button
												type="button"
												icon="pi pi-check"
												className="p-button-primary p-button-sm"
												onClick={handleSubmit(handleFormSubmit)}
												disabled={!field.value.trim()}
											/>
											<Button
												type="button"
												icon="pi pi-times"
												className="p-button-secondary p-button-sm"
												onClick={() => handleDeleteOption(index)}
											/>
										</>
									)}
								/>
							</div>
						) : (
							<div className="inline-flex align-items-center ml-2">
								<span className="flex justify-center">{item}</span>
								<Button
									className="p-button-text p-button-sm"
									icon="pi pi-pencil text-purple-500"
									onClick={() => handleEditOption(index)}
								/>
								<Button
									className="p-button-text p-button-sm"
									icon="pi pi-trash text-red-500"
									onClick={() => handleDeleteOption(index)}
								/>
							</div>
						)}
					</li>
				))}
			</ul>

			<Button
				className="p-button-text p-button-sm"
				icon="pi pi-plus"
				onClick={handleAddOption}
				disabled={editing !== null}
				label={t('programPanel.editQuestion.addAnswer')}
			/>

			<div className="flex flex-row mt-2">
				<Button label={t('actions.save')} className="p-button p-button-sm" onClick={handleSubmit(handleSave)} />
				<Button
					label={t('actions.cancel')}
					className="p-button p-button-sm p-button-secondary ml-2"
					onClick={onCancel}
				/>
			</div>
		</div>
	);
};
