/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Box, BoxSection } from 'components/_new/Box';
import { Button } from 'components/_new/Button';
import { ButtonGroupYesNo } from 'components/_new/ButtonGroup';
import { InputTextArea } from 'components/_new/InputTextArea';
import { Line } from 'components/_new/Line';
import { Checkbox } from 'primereact/checkbox';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePair } from 'contexts/pairContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { userPanelApi } from 'api';
import { Link } from 'components/_new/Link';
import { sessionMentibotApi } from 'api/sessionMentibot';
import { usePanel } from 'contexts/panelContext';
import { Spinner } from 'components/_new/Spinner';
import ReactMarkdown from 'react-markdown';
import { MessageBox } from 'components/_new/MessageBox';
import { useSessionsData } from '../Sessions';
import mentibotAiImage from '../../../../assets/mentibot-img.png';

export const RecommendationAISubpage = () => {
	const { t } = useTranslation();

	const { sessionId } = useParams();
	const { currentProgramMembership, currentApplicationData } = usePanel();

	const { sessiosRefetch } = useSessionsData();

	const { pair, path: pairPath } = usePair();
	const { sessionsData } = useSessionsData();
	const currentSession = sessionsData.find((session) => session.id === Number(sessionId));
	const [whileGenerating, setWhileGenerating] = useState(false);

	const { data: goalsData } = useQuery(
		['goals', Number(pair.id)],
		() => userPanelApi.getGoals(Number(pair.id), Number(currentProgramMembership?.id)),
		{
			enabled: Boolean(Number(pair.id) && currentProgramMembership?.id),
		}
	);

	const goals = (goalsData?.goals || []).map(({ goal }: any, index: number) => {
		const num = index + 1;
		return { num, goal };
	});

	const { data, isSuccess, isFetched, refetch } = useQuery(
		[
			'session-mentibot',
			{
				pairId: pair.id,
				programmembershipId: currentProgramMembership?.id,
				applicationId: currentApplicationData.id,
				sessionId,
			},
		],
		() =>
			sessionMentibotApi.getLastReply(
				Number(pair.id),
				currentProgramMembership.id,
				currentApplicationData.id,
				Number(sessionId)
			),
		{
			refetchInterval: 5000,
			enabled: true,
			onError: () => {},
			onSuccess: (result) => {
				if (result.status === 'error') {
					setWhileGenerating(false);
				} else {
					setWhileGenerating(result.status === 'waiting');
				}
			},
		}
	);

	const { mutate, isLoading } = useMutation(
		(data: any) =>
			sessionMentibotApi.sendMentibotRecommendation(
				Number(pair.id),
				currentProgramMembership.id,
				currentApplicationData.id,
				Number(sessionId),
				data
			),
		{
			onSuccess: (result) => {
				if (result.status === 'error') {
					setWhileGenerating(false);
					setNewQuestionMode(true);
				} else {
					setWhileGenerating(result.status === 'waiting');
					refetch();
					setNewQuestionMode(false);
				}

				if (result.info.update) {
					sessiosRefetch();
				}
			},
		}
	);

	const { handleSubmit, control } = useForm({
		defaultValues: {
			subject: currentSession?.goal || '',
			withGoals: false,
			terms: false,
		},
	});

	const handleSubmitForm = handleSubmit((values) => mutate(values));

	const hasResult = isSuccess && isFetched && ['waiting', 'ready'].includes(data?.status || '');
	const hasResultReply = hasResult && data?.content !== null;
	const [newQuestionMode, setNewQuestionMode] = useState(false);

	return (
		<div className="flex flex-column gap-4">
			{hasResult && !newQuestionMode ? (
				<>
					<h2 className="mt-4 mb-2">{t('userPanel.sessionsNew.aiRecommendations.fullTitle')}</h2>
					<div className="flex flex-column gap-4">
						{whileGenerating || !hasResultReply ? (
							<div className="flex flex-column justify-items-center align-items-center gap-2">
								<Spinner size="md" />
								<span>{t('userPanel.mentibotAi.reply.pleaseWait')}</span>
							</div>
						) : (
							<>
								<div className="flex flex-column gap-2">
									<div>
										{t('userPanel.mentibotAi.reply.yourSubject')} <b>{data?.prompt}</b>
									</div>
									<div>{t('userPanel.mentibotAi.reply.replyInfo')}</div>
									<MessageBox variant="purple" message={t('userPanel.mentibotAi.reply.replyInfo2')} />
								</div>

								<div className="line-height-22 md-content">
									<ReactMarkdown>{data?.content || ''}</ReactMarkdown>
								</div>

								<div className="flex flex-row justify-content-end">
									<div className="flex flex-column align-items-end gap-2">
										<Link
											to="#"
											onClick={() => {
												setNewQuestionMode(true);
											}}
										>
											{t('userPanel.sessionsNew.aiRecommendations.newQuestion')}
										</Link>
									</div>
								</div>
							</>
						)}
					</div>
				</>
			) : (
				<form onSubmit={handleSubmitForm} className="flex flex-column gap-4">
					<div className="grid">
						<div className="col-12 lg:col-8 align-items-between">
							<h2 className="mt-4 mb-2">{t('userPanel.sessionsNew.aiRecommendations.fullTitle')}</h2>
							<p className="mt-4">{t('userPanel.sessionsNew.aiRecommendations.sessionSubjectHelper')}</p>
						</div>
						<div className="hidden lg:flex lg:col-4 align-items-center justify-content-end">
							<img
								src={mentibotAiImage}
								className="mt-2 h-auto max-w-full"
								style={{ maxHeight: 140 }}
								alt="Mentibot brain"
							/>
						</div>
					</div>

					<Box variant="white-bordered">
						<Controller
							name="subject"
							control={control}
							rules={{ required: t('userPanel.sessionsNew.aiRecommendations.subjectIsRequired') }}
							render={({ field: { value, ...restField }, fieldState: { error } }) => (
								<BoxSection
									header={t('userPanel.sessionsNew.aiRecommendations.sessionSubject')}
									headerClassName="pt-2 font-semibold heading-16-24 text-on-blue-grey"
									contentClassName="pb-2"
								>
									<InputTextArea rows={3} {...restField} value={value} defaultValue={value} />
									{error && <p className="text-error">{error.message}</p>}
								</BoxSection>
							)}
						/>
					</Box>

					<div>
						<Controller
							name="withGoals"
							control={control}
							render={({ field: { value, onChange } }) => (
								<div className="flex flex-row gap-2 align-items-center">
									<div className="">
										<h3 className="font-semibold heading-16-24 text-on-blue-grey">
											{t('userPanel.sessionsNew.aiRecommendations.includeGoalsIntoQuery')}
										</h3>
									</div>
									{/* {value === undefined && <Icon name="circle" className="text-xs text-rose" />} */}
									<Line className="flex-1" minWidth={20} />
									<ButtonGroupYesNo
										value={typeof value !== 'undefined' && value !== null ? Boolean(value) : null}
										onChange={({ value }) => onChange(value)}
										disabled={!goals.length}
									/>
								</div>
							)}
						/>

						<div className=" text-gray">
							{!goals.length ? (
								<div className="bold">
									<Trans
										t={t}
										i18nKey="userPanel.sessionsNew.aiRecommendations.noGoals"
										components={[<Link to={`${pairPath}/mentee-plan`} />]}
									/>
								</div>
							) : (
								<ul className="list-none flex flex-column gap-2 pl-0">
									{goals.map(({ num, goal }) => (
										<li key={num} className="pl-2 md:pl-4">
											{t('userPanel.finalReport.goalNo', { index: num, goal })}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>

					<div className="flex flex-row gap-2 my-2">
						<Controller
							control={control}
							rules={{ required: t('userPanel.sessionsNew.aiRecommendations.termsIsRequired') }}
							name="terms"
							render={({ field, fieldState: { error } }) => (
								<div className="flex flex-column ">
									<label className="flex flex-row gap-2" htmlFor="terms">
										<Checkbox {...field} checked={field.value} inputId="terms" />
										<div>
											<span>{t('userPanel.sessionsNew.aiRecommendations.termsInfo')}</span>
											{error && <p className="text-error">{error.message}</p>}
										</div>
									</label>
								</div>
							)}
						/>
					</div>

					<div className="flex flex-row gap-2 justify-content-end">
						{hasResult && (
							<Button
								submit
								label={t('actions.back')}
								variant="primary-outlined"
								loading={isLoading}
								onClick={() => setNewQuestionMode(false)}
							/>
						)}
						<Button
							submit
							label={t('userPanel.sessionsNew.aiRecommendations.sendQuestionAndGetReply')}
							variant="primary"
							loading={isLoading}
						/>
					</div>

					<div className="text-xxs">
						<Trans t={t} i18nKey="userPanel.mentibotAi.form.info" shouldUnescape />
					</div>
				</form>
			)}
		</div>
	);
};
