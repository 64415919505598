import { User } from '../types/User';

export const userDisplayName = (user: Pick<User, 'firstName' | 'lastName' | 'email'>, def: any = undefined) => {
	const fullName = [user?.firstName || '', user?.lastName || '']
		.filter((namePart) => namePart && String(namePart).length > 0)
		.join(' ')
		.trim();
	if (fullName.length > 0) {
		return fullName;
	}
	if (typeof def !== 'undefined') {
		return def;
	}
	return user.email;
};
