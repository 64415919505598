import React, { useCallback, useMemo } from 'react';
import { PostAttachment, PostAttachmentFileType } from 'types/Dashboard';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { postsApi } from 'api';
import slugify from 'slugify';
import { Dialog, DialogBaseProps } from '../Dialog';
import { Button, ButtonProps } from '../Button';
import { Spinner } from '../Spinner';
import { Link } from '../Link';

type AttachemntPreviewDialogProps = DialogBaseProps & {
	attachement: PostAttachment | null;
};

export const AttachemntPreviewDialog = ({ attachement, ...dialogProps }: AttachemntPreviewDialogProps) => {
	const { t } = useTranslation();
	const { visible } = dialogProps;

	const { data: attachmentData, isLoading: attachementLoading } = useQuery(
		['GET_POST_ATTACHMENT_URL', { id: Number(attachement?.id) }],
		() => postsApi.getPostAttachment(Number(attachement?.id)),
		{
			enabled: visible && Boolean(attachement?.id),
			refetchOnWindowFocus: true,
		}
	);

	const prepareFilename = (attachment: PostAttachment) => {
		const slugName = slugify(attachment.originalFileName);
		const extension = ['file', 'pdf'].includes(attachment.fileType) ? `.pdf` : '';
		return attachment.originalFileName || `${slugName}${extension}`;
	};

	const handleDownload = useCallback(() => {
		if (attachmentData && typeof attachmentData === 'object') {
			const { attachment, signedUrl } = attachmentData;
			if (signedUrl?.attachment) {
				const downloadLink = document.createElement('a');
				downloadLink.href = signedUrl.attachment;
				downloadLink.rel = 'noreferrer';
				downloadLink.download = prepareFilename(attachment);
				document.body.append(downloadLink);
				downloadLink.click();
				setTimeout(() => {
					downloadLink.remove();
				}, 100);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentData]);

	const renderContent = () => {
		if (attachmentData) {
			if (!attachmentData.signedUrl.inline || !attachmentData.signedUrl.attachment) {
				return <div className="text-center text-danger">{t('misc.fileUnexpectedError')}</div>;
			}
			switch (attachement?.fileType) {
				case PostAttachmentFileType.PDF:
					return (
						<div className="flex flex-column gap-2">
							<iframe
								className="iframe-view iframe-pdf"
								src={attachmentData.signedUrl.inline}
								title={attachmentData?.attachment.originalFileName}
								width="100%"
								height="500px"
								aria-label="pdf file preview"
								allowFullScreen
							/>
							<div className="text-center">
								<Trans
									t={t}
									i18nKey="userPanel.userFiles.openPdfAlternative"
									components={[
										<Link
											to={attachmentData.signedUrl.inline}
											target="_blank"
											variant="standard"
										/>,
									]}
								/>
							</div>
						</div>
					);
				case PostAttachmentFileType.IMAGE:
					return (
						<div className="flex flex-row justify-content-center align-items-center">
							<img
								src={attachmentData.signedUrl.inline}
								alt={attachement.originalFileName}
								style={{ maxHeight: '50vh' }}
							/>
						</div>
					);
				case PostAttachmentFileType.UNKNOWN:
				default:
					return null;
			}
		}
		return null;
	};

	const handleOpenInNewTabnClick = useCallback(() => {
		if (attachmentData?.signedUrl.inline) {
			const anchor = document.createElement('a');
			if (attachement) {
				anchor.target = '_blank';
				switch (attachement.fileType) {
					case PostAttachmentFileType.PDF:
					case PostAttachmentFileType.IMAGE:
						if (attachmentData) {
							window.open(attachmentData.signedUrl.inline, '_blank');
						}
						break;
					case PostAttachmentFileType.UNKNOWN:
					default:
						break;
				}
				anchor.click();
			}
		}
	}, [attachement, attachmentData]);

	const downloadButtonProps: Partial<ButtonProps> = useMemo(() => {
		if (attachement) {
			switch (attachement.fileType) {
				case PostAttachmentFileType.PDF:
				case PostAttachmentFileType.IMAGE:
					return {
						loading: attachementLoading,
						disabled: !attachmentData?.signedUrl.attachment || !attachmentData?.signedUrl.inline,
					};
				case PostAttachmentFileType.UNKNOWN:
				default:
					return {
						loading: false,
					};
			}
		}
		return {
			loading: false,
		};
	}, [attachement, attachmentData, attachementLoading]);

	const openInNewTabButtonProps: Partial<ButtonProps> = useMemo(() => {
		if (attachement) {
			switch (attachement.fileType) {
				case PostAttachmentFileType.PDF:
				case PostAttachmentFileType.IMAGE:
					return {
						loading: attachementLoading,
						disabled: !attachementLoading && !attachmentData,
					};
				case PostAttachmentFileType.UNKNOWN:
				default:
					return {
						loading: false,
					};
			}
		}
		return {
			loading: false,
		};
	}, [attachement, attachmentData, attachementLoading]);

	return (
		<Dialog
			title={t('programPanel.post.attachmentPreviewTitle')}
			size="lg"
			headerRight={() => (
				<>
					<Button
						label={t('misc.downloadFile')}
						icon="download"
						onClick={handleDownload}
						{...downloadButtonProps}
					/>
					<Button
						label={t('misc.openInNewTab')}
						icon="arrow-up-right-from-square"
						variant="primary-outlined"
						onClick={handleOpenInNewTabnClick}
						{...openInNewTabButtonProps}
					/>
				</>
			)}
			{...dialogProps}
		>
			{attachementLoading && <Spinner center />}
			{!attachementLoading && renderContent()}
		</Dialog>
	);
};
