import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useProgramPanel } from 'contexts/programPanelContext';
import { useTranslation } from 'react-i18next';
import { statisticsApi } from 'api/statistics';
import { Spinner } from 'components/_new/Spinner';
import { Box, BoxSection } from 'components/_new/Box';
import { StatisticsProgramFeedbackItem } from 'types/Statistics';
import { SurveyQuestionType } from 'types/Survey';
import { useAuth } from 'contexts/authContext';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from '../../../_new/Dropdown';

type FeedbackItemGroup = Array<{
	step: number;
	items: Array<Omit<StatisticsProgramFeedbackItem, 'step'>>;
}>;

type UserTypeFilter = 'all' | 'mentors' | 'mentees';

type FilterValues = {
	userType: UserTypeFilter;
};

export const Feedback = () => {
	const { t } = useTranslation();
	const { currentUser } = useAuth();

	const { data: program } = useProgramPanel();

	const { control, watch } = useForm<FilterValues>({
		defaultValues: {
			userType: 'all',
		},
	});

	const {
		data: feedbackData,
		isLoading: menteeIsLoading,
		refetch,
	} = useQuery(
		[
			'programStatistics',
			{
				type: 'feedback',
				programId: Number(program?.id),
				language: currentUser?.language,
				userType: watch('userType'),
			},
		],
		() => {
			const userType = watch('userType');
			return statisticsApi.getFeedback(
				Number(program?.id),
				userType === 'all' ? undefined : (userType as 'mentors' | 'mentees')
			);
		},
		{
			enabled: Boolean(program),
			cacheTime: 10000,
		}
	);

	if (menteeIsLoading || !feedbackData) {
		return <Spinner />;
	}

	const data = feedbackData.answerStats.reduce<FeedbackItemGroup>((current, { step, ...item }) => {
		const idx = current.findIndex((ctg) => ctg.step === step);
		if (idx === -1) {
			current.push({
				step,
				items: [item],
			});
		} else {
			current[idx].items.push(item);
		}
		return current;
	}, []);

	const presentValueByType = (type: SurveyQuestionType, value: number) => {
		switch (type) {
			case SurveyQuestionType.SCALE:
				return `${value}/10`;
			case SurveyQuestionType.BOOLEAN:
				return `${value}%`;
			default:
				return null;
		}
	};

	return (
		<div className="flex flex-column gap-2">
			<div className="mb-2">
				<h3>{t('programPanel.statistics.feedback')}</h3>
				<div className="flex flex-column md:flex-row justify-content-between">
					<p>{t('programPanel.statistics.feedbackDesc', { count: feedbackData.count })}</p>
					<Controller
						control={control}
						name="userType"
						render={({ field }) => (
							<Dropdown
								{...field}
								inline
								options={[
									{ label: t('programPanel.statistics.filter.all'), value: 'all' },
									{ label: t('programPanel.statistics.filter.mentorsOnly'), value: 'mentors' },
									{ label: t('programPanel.statistics.filter.menteesOnly'), value: 'mentees' },
								]}
							/>
						)}
					/>
				</div>
			</div>
			{data.map(({ step, items }) => (
				<Box key={step} variant="white-bordered" slim>
					{items.map(({ type, text, value, positivePerc, choices }) => (
						<>
							<BoxSection horizontal contentClassName="grid grid-nogutter">
								<div className="col md:col-8 p-0 font-bold flex align-items-center">
									<span>{text}</span>
								</div>
								<div className="col-auto md:col-3 p-0 font-bold flex flex-column align-items-end">
									{[SurveyQuestionType.MULTI_CHOICE, SurveyQuestionType.SINGLE_CHOICE].includes(
										type
									) ? (
										<div />
									) : (
										<>
											{(value === null || (Array.isArray(value) && !value.length)) && (
												<span className="hidden md:inline text-xxl text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<div className="hidden md:flex flex-column align-items-end flex-wrap gap-1">
													<span className="text-xxl text-light-purplish-blue">
														{presentValueByType(type, value)}
													</span>
													{type === SurveyQuestionType.SCALE && (
														<span className="text-sm text-rose">
															{t('programPanel.statistics.feedbackScalePositive', {
																count: Number(positivePerc),
															})}
														</span>
													)}
												</div>
											)}
											{value === null && (
												<span className="inline md:hidden text-lg text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<div className="flex md:hidden flex-column align-items-end flex-wrap gap-1">
													<span className="text-lg text-light-purplish-blue">
														{presentValueByType(type, value)}
													</span>
													{type === SurveyQuestionType.SCALE && (
														<span className="text-sm text-rose">
															{t('programPanel.statistics.feedbackScalePositive', {
																count: Number(positivePerc),
															})}
														</span>
													)}
												</div>
											)}
										</>
									)}
								</div>
							</BoxSection>

							{[SurveyQuestionType.TEXT, SurveyQuestionType.LONGTEXT].includes(type) &&
								Array.isArray(value) &&
								value.map((str, idx) => (
									// eslint-disable-next-line react/no-array-index-key
									<BoxSection key={idx} horizontal contentClassName="grid grid-nogutter">
										<div className="col md:col-8 p-0 pl-4 flex align-items-center">{str}</div>
									</BoxSection>
								))}

							{[SurveyQuestionType.MULTI_CHOICE, SurveyQuestionType.SINGLE_CHOICE].includes(type) &&
								(choices || []).map(({ id, text, value }) => (
									<BoxSection key={id} horizontal contentClassName="grid grid-nogutter">
										<div className="col md:col-8 p-0 pl-4 flex align-items-center">
											<span>{text}</span>
										</div>
										<div className="col-auto md:col-3 p-0 font-bold flex align-items-center">
											{value === null && (
												<span className="hidden md:inline text-xl text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<span className="hidden md:inline text-xl text-light-purplish-blue">
													{`${value}%`}
												</span>
											)}
											{value === null && (
												<span className="inline md:hidden text-lg text-gray">
													{t('misc.noData')}
												</span>
											)}
											{value !== null && (
												<span className="inline md:hidden text-lg text-light-purplish-blue">
													{`${value}%`}
												</span>
											)}
										</div>
									</BoxSection>
								))}
						</>
					))}
				</Box>
			))}
		</div>
	);
};
