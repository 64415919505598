import { httpClient } from 'api';
import { Post, PostAttachment, PostComment } from 'types/Dashboard';
import { Paginated } from 'types/Paginated';

export type GetPostCommentsResponse = {
	data: PostComment[];
	meta: { page: number; nextPage: number | null; left: number };
};

export const postsApi = {
	// for program admin
	addProgramPostAdmin: ({ programId, ...data }: any) =>
		httpClient.post<Post>(`posts/admin/program/${programId}`, data),

	addProgramPostAttachmentLocalAdmin: ({ postLocalUuid, data }: { postLocalUuid: string; data: FormData }) =>
		httpClient.post<PostAttachment>(`posts/admin/upload-attachment/uuid/${postLocalUuid}`, data),
	addProgramPostAttachmentAdmin: ({ postId, data }: { postId: number; data: FormData }) =>
		httpClient.post<PostAttachment>(`posts/admin/upload-attachment/post/${postId}`, data),
	removeProgramPostAttachmentAdmin: (id: number) => httpClient.delete<boolean>(`posts/admin/upload-attachment/${id}`),

	getPostsAdmin: ({ programId, params }: { programId: number; params?: Record<string, any> }) =>
		httpClient.get<Paginated<Post>>(`posts/admin/program/${programId}`, params),
	getPostCommentsAdmin: ({ postId, params }: { postId: number; params?: Record<string, any> }) =>
		httpClient.get<GetPostCommentsResponse>(`posts/admin/${postId}/comments`, params),
	deletePostAdmin: ({ postId }: { postId: number }) => httpClient.delete<boolean>(`posts/admin/${postId}`),
	getPostAdmin: ({ postId }: { postId: number }) => httpClient.get<Post>(`posts/admin/${postId}`),
	editPostAdmin: ({ postId, data }: { postId: number; data: any }) =>
		httpClient.put<Post>(`posts/admin/${postId}`, data),
	addPostCommentAdmin: ({ postId, payload }: { postId: number; payload: any }) =>
		httpClient.post<PostComment>(`posts/admin/${postId}/comment`, payload),
	// userpanel/dashboard
	getPosts: ({
		membershipId,
		applicationId,
		params,
	}: {
		membershipId: number;
		applicationId: number;
		params?: Record<string, any>;
	}) => httpClient.get<Paginated<Post>>(`posts/${membershipId}/${applicationId}`, params),
	getPostComments: ({ postId, params }: { postId: number; params?: Record<string, any> }) =>
		httpClient.get<GetPostCommentsResponse>(`posts/comment/${postId}`, params),
	addPostComment: ({ postId, payload }: { postId: number; payload: any }) =>
		httpClient.post<PostComment>(`posts/comment/${postId}`, payload),
	uploadImage: (data: FormData) => httpClient.put<string>(`posts/upload-image`, data),
	uploadImageByUrl: (url: string) => httpClient.put<string>(`posts/upload-image-url`, { url }),
	// getPostAttachmentBlob: (postAttachmentId: number) =>
	// 	httpClient.get<Blob>(`posts/upload-attachment/stream/${postAttachmentId}`, undefined, {
	// 		responseType: 'blob',
	// 	}),
	getPostAttachment: (postAttachmentId: number) =>
		httpClient.get<{ attachment: PostAttachment; signedUrl: { inline: string | null; attachment: string | null } }>(
			`posts/upload-attachment/${postAttachmentId}`
		),
};
