import React, { ReactNode, HTMLAttributes } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Badge } from '../Badge';
import { Icon, IconStyle } from '../Icon';
import { universalRenderer } from '../../../utils/universalRenderer';
import './NavTabs.scss';

export type NavTabsNavLink = Omit<NavLinkProps, 'children'> & {
	key: string;
	elementClassName?: string;
	activeClassName?: string;
	label: string;
	children?: ReactNode;
	badge?: ReactNode;
	iconLeft?: string;
	iconLeftStyle?: IconStyle;
	iconRight?: string;
	iconRightStyle?: IconStyle;
};

type NavTabsProps = HTMLAttributes<HTMLDivElement> & {
	tabs: NavTabsNavLink[];
	minusMargin?: boolean;
	large?: boolean;
	disabled?: boolean;
	right?: ReactNode | (() => ReactNode);
};

export const NavTabs = ({
	tabs,
	className,
	minusMargin = false,
	large = false,
	disabled = false,
	right,
}: NavTabsProps) => {
	const prepareItemLinkLabel = (
		label: string,
		children: ReactNode,
		badge?: ReactNode,
		iconLeft?: string,
		iconRight?: string
	): ReactNode => {
		return (
			<>
				{iconLeft && <Icon name={iconLeft} className="text-rose" />}
				<span className="nav-tabls-link-label">{children || label}</span>
				{badge ? <Badge>{badge}</Badge> : null}
				{iconRight && <Icon name={iconRight} className="text-rose" />}
			</>
		);
	};
	return (
		<nav
			className={classNames(
				'nav-tabs',
				{ 'nav-tabs-minusmargin': minusMargin, 'nav-tabs-large': large },
				className
			)}
		>
			<ul className="nav-tabs-list">
				{tabs.map(
					({
						key,
						label,
						badge,
						elementClassName,
						activeClassName,
						children,
						iconLeft,
						iconLeftStyle,
						iconRight,
						iconRightStyle,
						...rest
					}) => (
						<li key={key} className={classNames('nav-tabs-list-item', elementClassName)}>
							<NavLink
								className={({ isActive }) =>
									classNames(
										'nav-tabs-link',
										{
											'nav-tabs-link-active': isActive,
											'nav-tabs-link-disabled': disabled,
										},
										activeClassName ? { activeClassName: isActive } : {}
									)
								}
								aria-label={label}
								aria-disabled={disabled}
								{...rest}
							>
								{prepareItemLinkLabel(label, children, badge, iconLeft, iconRight)}
							</NavLink>
						</li>
					)
				)}
			</ul>
			{right && <div className="flex flex-row gap-2 justify-content-end">{universalRenderer(right)}</div>}
		</nav>
	);
};
