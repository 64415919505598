import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'primereact/tooltip';

import { CreateGoalPayload, UpdateGoalPayload } from 'types/payloads/GoalPayload';
import { ActionGoalId, Goal, GoalFields } from 'types/Goal';

import { InputTextArea } from 'components/_new/InputTextArea';
import getGoalRows from './components/getGoalRows';

interface Props {
	id: string;
	goalData: Goal;
	editedGoal: number;
	getGoals: () => void;
	updateGoal: (goalValue: UpdateGoalPayload) => void;
	saveNewGoal: (goalValue: CreateGoalPayload) => void;
	setEditedGoal: (id: null | number) => void;
}

type GoalFormObject = Record<GoalFields, string>;

type TooltipKeys = {
	goal: string;
	change: string;
	achivmentPath: string;
	priorities: string;
	notes: string;
};

const GoalForm = ({ id, goalData, editedGoal, updateGoal, saveNewGoal, setEditedGoal }: Props) => {
	const { goal, change, achivmentPath, priorities, notes } = goalData;
	const { t } = useTranslation();

	const { register, handleSubmit } = useForm<GoalFormObject>({
		defaultValues: {
			goal,
			change,
			achivmentPath,
			priorities,
			notes,
		},
	});

	const rows = getGoalRows(goalData, t);

	const handleSubmitForm = handleSubmit((values) => {
		if (editedGoal === ActionGoalId.new) {
			saveNewGoal(values);
		} else {
			updateGoal({ ...values, id: editedGoal });
		}
		setEditedGoal(null);
	});

	const getTooltipKey = (type: keyof TooltipKeys) => {
		const typeToKey: TooltipKeys = {
			goal: 'agreedGoalTooltip',
			change: 'expectedChangeTooltip',
			achivmentPath: 'achivmentPathTooltip',
			priorities: 'prioritiesTooltip',
			notes: 'notesTooltip',
		};
		return `userPanel.goal.${typeToKey[type]}`;
	};

	return (
		<form id={id} className="w-full" onSubmit={handleSubmitForm}>
			{rows?.map(({ label, required, type }) => (
				<div key={type} className="grid mt-2">
					<div className="col-12 lg:col-4 p-2 flex align-items-center">
						<span style={{ display: 'inline-block' }}>
							{label}
							<i
								className="fa-regular fa-circle-question text-rose cursor-help"
								data-pr-tooltip={t(getTooltipKey(type as keyof TooltipKeys))}
								data-pr-position="top"
								data-pr-at="center-0 top-10"
								style={{ display: 'inline', marginLeft: '0.5rem' }}
							/>
						</span>
						<Tooltip target="[data-pr-tooltip]" />
					</div>
					<div className="col-12 lg:col-8">
						<InputTextArea
							{...register(type)}
							name={type}
							required={required}
							className="w-full font-bold"
						/>
					</div>
				</div>
			))}
		</form>
	);
};

export default GoalForm;
