import React from 'react';
import { useTranslation } from 'react-i18next';

interface PairTimestampsProps {
	pair: {
		createdAt: string;
		entryMeeting: string | null;
		acceptedByMentee: string | null;
		acceptedByMentor: string | null;
		rejectedByMentee: any | null;
		rejectedByMentor: any | null;
	};
}

const PairActivityTimestamps: React.FC<PairTimestampsProps> = ({ pair }) => {
	const { t } = useTranslation();
	const timestamps = [
		{
			date: pair.createdAt,
			key: 'programPanel.pair.timestamps.createdAt',
		},
		{
			date: pair.entryMeeting,
			key: 'programPanel.pair.timestamps.entryMeeting',
		},
		{
			date: pair.acceptedByMentee,
			key: 'programPanel.pair.timestamps.acceptedByMentee',
		},
		{
			date: pair.acceptedByMentor,
			key: 'programPanel.pair.timestamps.acceptedByMentor',
		},
		{
			date: pair.rejectedByMentee,
			key: 'programPanel.pair.timestamps.rejectedByMentee',
		},
		{
			date: pair.rejectedByMentor,
			key: 'programPanel.pair.timestamps.rejectedByMentor',
		},
	];

	const formatDate = (date: string | null) => {
		if (!date) return null;
		return new Date(date).toLocaleString('pl-PL', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		});
	};

	return (
		<div className="text-gray-500 text-sm mt-2">
			{timestamps.map(
				({ date, key }, index) => date && <div className="mb-1">{`${t(key)}: ${formatDate(date)}`}</div>
			)}
		</div>
	);
};

export default PairActivityTimestamps;
